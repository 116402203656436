import { Dispatch, Fragment, SetStateAction, useEffect, useLayoutEffect, useRef, useState } from 'react';

import Button from '@containers/common/Button';
import { Box, Typography } from '@mui/material';
import Checkbox from '@containers/common/Checkbox';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IConversationForm } from '@features/conversation/types';
import { getConversations, sendMessage } from '@features/conversation/actions';
import { useParams } from 'react-router-dom';
import {
  getConversationSelector,
  getConversationInitLoaderSelector,
  getConversationMessageSelector,
  selectOrderSetsSelector,
  getConversationDisallowState,
} from '@features/conversation/selectors';
import { EXTRA_DATE_FORMAT } from '@utils/helpers';
import { addNewMessage, clearMessages, updateSetUnopenedStatus } from '@features/conversation/slice';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorMessage from '@containers/common/ErrorMessage';
import moment from 'moment';
import { OrderStatusesEnum } from '@features/orders/order/enums';

import Dialog from './Dialog';
import { ConversationSchema } from './helpers';
import {
  Section,
  LoaderWrapper,
  MessagesList,
  LeftMessage,
  RightMessage,
  StyledTextarea,
  SendEmailWrapper,
  MissingMessage,
  SetsList,
  Item,
  Unanswered,
  StyledNewLine,
 } from './styled';

interface ConversationFormProps {
  clickedSetId: string;
  askQuestionModal: boolean;
  setAskQuestionModal: Dispatch<SetStateAction<boolean>>;
  setIsConversationOpen: Dispatch<SetStateAction<boolean>>;
  setStopModalCancellation: Dispatch<SetStateAction<boolean>>;
}

const ConversationForm = ({
  clickedSetId,
  askQuestionModal,
  setAskQuestionModal,
  setIsConversationOpen,
  setStopModalCancellation,
 }: ConversationFormProps) => {
  const { orderId } = useParams();
  const dispatch = useAppDispatch();
  const orderSetsInfo = useAppSelector(selectOrderSetsSelector);
  const conversation = useAppSelector(getConversationSelector);
  const initLoader = useAppSelector(getConversationInitLoaderSelector);
  const message = useAppSelector(getConversationMessageSelector);
  const disallowState = useAppSelector(getConversationDisallowState);

  const prevSelectedSetId = useRef(clickedSetId || "");
  const [selectedSetId, setSelectedSetId] = useState<string>(clickedSetId || '');

  const methods = useForm<IConversationForm>({
    resolver: yupResolver(ConversationSchema),
    defaultValues: {
      message: '',
      sendEmail: false,
    },
  });

  let newLineRenderedRef = 0;
  const { reset, watch, handleSubmit, register, formState: { errors } } = methods;
  const prevLoadedHeightRef = useRef<number>(0);
  const bottomRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const messageFiled = watch('message');

  const [once, setOnce] = useState(false);
  const [moreLoading, setMoreLoading] = useState(false);
  const [filters, setFilters] = useState({ limit: 10, offset: 0 });

  const makeUnopenedChatTruth = (setId: string) => {
    const updatedSets = orderSetsInfo.map((item) => {
      if (item.setId === setId) {
        return { ...item, existsUnansweredMessagesForAdmin: false };
      }

      return item;
    });

    dispatch(updateSetUnopenedStatus(updatedSets));
    verticalScrollInSetTabs(setId);
  };

  const onSubmit = async (data: IConversationForm) => {
    if (!orderId || !selectedSetId) {
      return;
    }

    try {
      const response = await dispatch(sendMessage({ ...data, setId: selectedSetId })).unwrap();

      await dispatch(addNewMessage(response));
      makeUnopenedChatTruth(selectedSetId);

      if (bottomRef?.current) {
        bottomRef.current.scrollIntoView();
      }

      reset();
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleScroll = (e: any) => {
    if (!prevLoadedHeightRef.current) {
      prevLoadedHeightRef.current = e.target.scrollHeight;
    }

    if (e.target.scrollTop === 0 && !moreLoading) {
      setMoreLoading(true);

      setTimeout(() => {
        // Just for loader, without setTimeout it works well
        setFilters((prev) => ({ ...prev, offset: prev.offset + prev.limit }));
      }, 100);
    }
  };

  const verticalScrollInSetTabs = (setId: string) => {
    const SetTabItemInList = document.getElementById(setId);

    if (SetTabItemInList) {
      setTimeout(() => {
        SetTabItemInList.scrollIntoView();
      }, 0);
    }
  };

  useEffect(() => {
    setStopModalCancellation(!!messageFiled);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageFiled]);

  useEffect(() => {
    // it's working when was click on set tab
    // with static arguments

    if (selectedSetId !== prevSelectedSetId.current) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      newLineRenderedRef = 0;
      dispatch(clearMessages());
      dispatch(getConversations({ id: selectedSetId, ...filters }))
        .unwrap()
        .then((response) => {
          const openedSetId = response?.currentSetInfo?.setId;
          const { orderSetsInfo: newOrderSetsInfo } = response;

          if (!openedSetId || !newOrderSetsInfo?.length) {
            return;
          }

          prevSelectedSetId.current = selectedSetId;

          setMoreLoading(false);
        })
        .catch(() => {
          setMoreLoading(false);
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSetId]);

  useEffect(() => {
    // load more messages, need to check
    if (selectedSetId) {
      verticalScrollInSetTabs(selectedSetId);
      dispatch(getConversations({ id: selectedSetId, ...filters }))
        .unwrap()
        .then((response) => {
          const openedSetId = response?.currentSetInfo?.setId;
          const { orderSetsInfo: newOrderSetsInfo } = response;

          if (!openedSetId || !newOrderSetsInfo?.length) {
            return;
          }

          prevSelectedSetId.current = selectedSetId;
          setMoreLoading(false);
        })
        .catch(() => {
          setMoreLoading(false);
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useLayoutEffect(() => {
    // it works one time, when page loads
    if (bottomRef.current && !once) {
      setOnce(true);
      bottomRef.current.scrollIntoView();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation]);

  useLayoutEffect(() => {
    if (containerRef.current && once) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight - prevLoadedHeightRef.current;

       setTimeout(() => {
        prevLoadedHeightRef.current = 0;
      }, 0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation.length]);

  useLayoutEffect(() => {
    const element = containerRef.current;

    if (element && !!conversation.length) {
      element.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!conversation.length]);

  const newLineHtml = (messageAdminStatus: 'new' | 'seen', sender: string) => {
    if (messageAdminStatus === 'new' && !newLineRenderedRef && sender === 'customer') {
      newLineRenderedRef = 1;

      return (
        <div>
          <StyledNewLine>
            <span />
            <p>
              <span>New</span>
            </p>
          </StyledNewLine>
        </div>
      );
    }
  };

  const viewRender = () => {
    if (initLoader) {
      return (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      );
    }

    if (
      !conversation?.length && initLoader ||
      !conversation?.length && !initLoader && message
    ) {
      return (
        <MessagesList>
          <MissingMessage>
            There are no comments for this order yet.
          </MissingMessage>
        </MessagesList>
      );
    }

    return (
      <MessagesList ref={containerRef}>
        {
            moreLoading && (
              <Box
                sx={{
                  maxHeight: '60px',
                  height: '60px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress />
              </Box>
            )
          }
        {
            !moreLoading && message && (
              <MissingMessage
                sx={{ marginBottom: '16px' }}
              >
                {message}
              </MissingMessage>
            )
          }
        {
            conversation?.map((item) => {
              if (item.sender === 'admin') {
                return (
                  <Fragment key={item.id}>
                    {newLineHtml(item.messageAdminStatus, item.sender)}

                    <RightMessage>
                      <Typography>
                        {item.message}
                        <span>{ moment(item.created).format(`${EXTRA_DATE_FORMAT} HH:mm a`)}</span>
                      </Typography>
                    </RightMessage>
                  </Fragment>
                );
              }

              return (
                <Fragment key={item.id}>
                  {newLineHtml(item.messageAdminStatus, item.sender)}
                  <LeftMessage key={item.id}>
                    <Typography>
                      {item.message}
                      <span>{ moment(item.created).format(`${EXTRA_DATE_FORMAT} HH:mm a`)}</span>
                    </Typography>
                  </LeftMessage>
                </Fragment>
              );
            })
          }
        <div ref={bottomRef} />
      </MessagesList>
    );
  };

  if (askQuestionModal) {
    return (
      <Dialog
        setAskQuestionModal={setAskQuestionModal}
        setIsConversationOpen={setIsConversationOpen}
        setStopModalCancellation={setStopModalCancellation}
      />
    );
  }

  return (
    <Section>
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
        >
          <SetsList>
            {
              orderSetsInfo?.map((set) => (
                <Item
                  id={set.setId}
                  key={set.setId}
                  active={selectedSetId === set.setId ? 1 : 0}
                  onClick={() => {
                    setSelectedSetId(set.setId);
                  }}
                >
                  Set:
                  {' '}
                  {set.setNumber}
                  {set.existsUnansweredMessagesForAdmin ? (<Unanswered />) : null}
                </Item>
              ))
            }
          </SetsList>
          {viewRender()}
          {
            disallowState ? null
            : (
              <div>
                <StyledTextarea
                  onlyBorder
                  placeholder="Reply here..."
                  value={messageFiled}
                  {...register('message')}
                  errorMessage={errors?.message?.message}
                />
                <SendEmailWrapper>
                  <Checkbox name="sendEmail" />
                  <Typography sx={{ marginTop: '2px' }}>
                    Send an email update to customer after submitting your reply
                  </Typography>
                </SendEmailWrapper>
                {errors?.message?.message && (
                <Box sx={{ textAlign: 'center', marginBottom: '12px' }}>
                  <ErrorMessage message={errors?.message?.message as string} />
                </Box>
                )}
                <Button type="submit">Send</Button>
              </div>
            )
          }
        </form>
      </FormProvider>
    </Section>
  );
};

export default ConversationForm;
