import { createSlice } from '@reduxjs/toolkit';

import { IState } from './types';
import {
  getDeveloperTaskById,
  getOpenDevelopersTasks,
  getClosedDevelopersTasks,
} from './actions';

const initialState: IState = {
  isLoading: true,
  actionLoading: false,
  data: null,
  errorMessage: null,
  total: 0,
};

const developersTasksSlice = createSlice({
  name: 'developersTasks',
  initialState,
  reducers: {
    setDevelopersTasks: (state, { payload }) => {
      state.data = payload;
    },
    restoreDevelopersTasks: () => {},
  },
  extraReducers: (builder) => {
    builder.addCase(getOpenDevelopersTasks.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getOpenDevelopersTasks.fulfilled, (state, { payload }) => {
      state.data = payload.tasks;
      state.total = payload.total;
      state.isLoading = false;
    });
    builder.addCase(getOpenDevelopersTasks.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getClosedDevelopersTasks.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getClosedDevelopersTasks.fulfilled, (state, { payload }) => {
      state.data = payload.tasks;
      state.total = payload.total;
      state.isLoading = false;
    });
    builder.addCase(getClosedDevelopersTasks.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getDeveloperTaskById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getDeveloperTaskById.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getDeveloperTaskById.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { setDevelopersTasks, restoreDevelopersTasks } = developersTasksSlice.actions;

export default developersTasksSlice.reducer;
