import { useState } from "react";

import Modal from "@containers/Orders/Order/components/common/Modal";
import { useAppDispatch } from "@features/app/hooks";
import { clearMessages } from "@features/conversation/slice";

import { ConversationForm } from "./components";
import { Unanswered } from "./components/ConversationForm/styled";
import { StyledButton } from "./styled";

interface IConversation {
  setId?: string;
  disallowMessage: boolean;
  existsUnansweredMessagesForAdmin?: boolean;
}

const Conversation = ({ disallowMessage, setId, existsUnansweredMessagesForAdmin }: IConversation) => {
  const [isConversationOpen, setIsConversationOpen] = useState<boolean>(false);
  const [stopModalCancellation, setStopModalCancellation] = useState(false);
  const [askQuestionModal, setAskQuestionModal] = useState(false);
  const dispatch = useAppDispatch();

  return (
    <div>
      <StyledButton
        state={disallowMessage}
        isOutlined
        onClick={() => setIsConversationOpen(true)}
      >
        Order / Chat
        {existsUnansweredMessagesForAdmin ? <Unanswered /> : null}
      </StyledButton>
      <Modal
        hideTopSection={askQuestionModal}
        sx={{ padding: '24px' }}
        title="Artwork Chat"
        open={isConversationOpen}
        handleClose={() => {
          dispatch(clearMessages());
          if (!stopModalCancellation) {
            setAskQuestionModal(false);
            setIsConversationOpen(false);
          } else {
            setAskQuestionModal(true);
          }
        }}
      >
        <ConversationForm
          clickedSetId={setId || ''}
          askQuestionModal={askQuestionModal}
          setAskQuestionModal={setAskQuestionModal}
          setIsConversationOpen={setIsConversationOpen}
          setStopModalCancellation={setStopModalCancellation}
        />
      </Modal>
    </div>
  );
};

export default Conversation;
