import { memo } from 'react';

import { StyledTableRow } from '@containers/common/Table/styled';
import TCell from '@containers/common/Table/components/TCell';
import { OrdersData } from '@features/dashboard/types';
import { formatPrice } from '@containers/dashboard';

interface ITableRow extends OrdersData {
}

const TableRow = ({
  count,
  amount,
  subCategoryTitle,
}: ITableRow) => (
  <StyledTableRow>
    <TCell text={subCategoryTitle} />
    <TCell text={count} />
    <TCell text={formatPrice(Number(amount || 0))} />
  </StyledTableRow>
);

export default memo(TableRow);
