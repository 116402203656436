import * as yup from "yup";
import {
  createOptionalTextWidthValidation,
  createRequiredTextWidthValidation,
  createTextWidthValidation,
  integerOptionalSchema,
} from "@utils/schemas";

export const AddSubcategorySchema = yup.object().shape({
  title: createRequiredTextWidthValidation("Title", 255),
  description: createTextWidthValidation(1500),
  specifications: createTextWidthValidation(5000),
  slug: yup
    .string()
    .matches(
      /^[A-Za-z-]+$/,
      "Only letters and hyphens are allowed, without spaces",
    )
    .required("Slug is required"),
  photo: yup
    .array()
    .of(yup.string().required("Photo is required"))
    .min(1, "At least one photo is required")
    .max(8, "A maximum of 8 photos is allowed."),
  categoryId: createRequiredTextWidthValidation("Category", 255),
  printType: createRequiredTextWidthValidation("Print type", 255),
  isEDDM: yup.boolean().required(),
  frontArtworkLable: createOptionalTextWidthValidation(
    "Front Artwork Label",
    255,
  ),
  backArtworkLable: createOptionalTextWidthValidation(
    "Back Artwork Label",
    255,
  ),
  categoryDiscountOffMSRP: integerOptionalSchema
    .min(0, "Min value is 0")
    .max(100, "Max value is 100")
    .optional()
    .nullable(),
  productTypes: yup
    .array()
    .of(
      yup.object({
        name: createRequiredTextWidthValidation("Product type", 255),
        description: createTextWidthValidation(1500),
        specifications: createTextWidthValidation(5000),
        photos: yup
          .array()
          .of(yup.string().required("Photo is required"))
          .min(1, "At least one photo is required")
          .max(8, "A maximum of 8 photos is allowed."),
        customShippingFacility: yup
          .boolean()
          .required("Custom Shipping Facility is required."),
        shippingZips: yup.array(),
        slug: yup
          .string()
          .matches(
            /^[A-Za-z-]+$/,
            "Only letters and hyphens are allowed, without spaces",
          )
          .required("Slug is required"),
      }),
    )
    .test(
      "productTypes-validation",
      "Validation error",
      // eslint-disable-next-line func-names
      function (
        this: any,
        productTypes?: { photos?: string[]; name: string }[],
      ) {
        const makeCategoryAsProduct = this.parent?.makeCategoryAsProduct;

        if (makeCategoryAsProduct) {
          return true;
        }

        if (!productTypes || productTypes.length === 0) {
          return this.createError({
            path: "productTypes",
            message: "At least one product type is required",
          });
        }

        for (let i = 0; i < productTypes.length; i += 1) {
          for (let j = i + 1; j < productTypes.length; j += 1) {
            if (
              productTypes[i].name.toLowerCase()
              === productTypes[j].name.toLowerCase()
            ) {
              return this.createError({
                path: `productTypes[${j}].name`,
                message: "Name is duplicated",
              });
            }
          }
        }

        return true;
      },
    ),
});
