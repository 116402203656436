export const headCells = [
  {
    label: 'Sub-category',
    sortKey: 'subCategoryTitle',
    sortable: true,
  },
  {
    label: 'Total orders',
    sortKey: 'count',
    sortable: true,
  },
  {
    label: 'Total Order Value',
    sortKey: 'amount',
    sortable: true,
  },
];
