import { useEffect, useState } from "react";

import { useFormContext } from "react-hook-form";
import download from "@assets/images/download.svg";

import Modal from "../../../../../../../../../../../common/Modal";
import EachProof from "./components/EachProof";
import UploadBox, { CertificateEnumType } from "./components/UploadBox";
import { useFileUploadLogic } from "./components/UploadBox/useFileUploadLogic";
import { Title } from "../../styled";
import { Content, Underlined, Wrapper } from "./styled";
import { ProofStatusEnum } from "../../../../../../../utils/enums";

interface ProofProps {
  fileID: number;
  setId: string;
  jobIndex: number;
  setIndex: number;
  shipmentIndex: number;
  backProofStatus: ProofStatusEnum;
  frontProofStatus: ProofStatusEnum;
  backArtworkState: boolean;
}

const Proof = ({
  fileID,
  setId,
  jobIndex,
  setIndex,
  shipmentIndex,
  backProofStatus,
  frontProofStatus,
  backArtworkState,
}: ProofProps) => {
  const [isOpenUploadModal, setIsOpenUploadModal] = useState(false);
  const defaultKey = `jobs[${jobIndex}].shipments[${shipmentIndex}].sets[${setIndex}]`;
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const logic = useFileUploadLogic(defaultKey, setValue, setId, fileID);

  const frontProof = watch(`${defaultKey}.frontProof`) as string;
  const frontProofName = watch(`${defaultKey}.frontProofName`) as string;
  const frontProofSize = watch(`${defaultKey}.frontProofSize`) as string;
  const backProof = watch(`${defaultKey}.backProof`) as string;
  const backProofName = watch(`${defaultKey}.backProofName`) as string;
  const backProofSize = watch(`${defaultKey}.backProofSize`) as string;
  const frontProofDate = watch(`${defaultKey}.frontProofDate`) as string;
  const backProofDate = watch(`${defaultKey}.backProofDate`) as string;

  const messagesObjJobs = errors?.jobs as Record<string, any>;

  let messageShipmentSets = {} as Record<string, any>;

  if (messagesObjJobs && messagesObjJobs[jobIndex]) {
    messageShipmentSets =
      messagesObjJobs[jobIndex]?.shipments?.[shipmentIndex]?.sets?.[setIndex];
  }

  useEffect(() => {
    if (
      (frontProof && backProof && backArtworkState) ||
      (!backArtworkState && frontProof)
    ) {
      setTimeout(() => {
        if (isOpenUploadModal) {
          window.location.reload();
        }

        setIsOpenUploadModal(false);
      }, 1000);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frontProof, backProof, backArtworkState]);

  return (
    <>
      <Modal
        sx={{ padding: "24px" }}
        title="UPLOAD PROOFS"
        open={isOpenUploadModal}
        handleClose={() => {
          setIsOpenUploadModal(false);
        }}
      >
        <Wrapper>
          <UploadBox
            title="Front Side Artwork"
            desc=""
            mobileButton=""
            uploaded={frontProof}
            keyType={CertificateEnumType.frontProof}
            {...logic}
          />
          {backArtworkState && (
            <UploadBox
              title="Back Side Artwork"
              desc=""
              mobileButton=""
              uploaded={backProof}
              {...logic}
              keyType={CertificateEnumType.backProof}
            />
          )}
        </Wrapper>
      </Modal>
      <Title>
        PROOF
        <Underlined
          onClick={() => {
            setIsOpenUploadModal(true);
          }}
        >
          <img src={download} alt={download} />
          <p>Upload proofs</p>
        </Underlined>
      </Title>
      <Content>
        <EachProof
          text="Front:"
          proof={frontProof}
          proofName={frontProofName}
          proofSize={frontProofSize}
          proofDate={frontProofDate}
          proofStatus={frontProofStatus}
          defaultKey={defaultKey}
          deleteKey="frontProof"
        />
        {backArtworkState && (
          <EachProof
            text="Back:"
            proof={backProof}
            proofName={backProofName}
            proofDate={backProofDate}
            proofSize={backProofSize}
            proofStatus={backProofStatus}
            defaultKey={defaultKey}
            deleteKey="backProof"
          />
        )}
      </Content>
    </>
  );
};

export default Proof;
