import { memo } from "react";

import { useFormContext } from "react-hook-form";
import { StyledTableRow } from "@containers/common/Table/styled";
import { TableCell } from "@mui/material";
import { StyledTableCell } from "@containers/common/StyledAddEditTables/styled";
import Input from "@containers/common/Input";
import AddTextBtn from "@containers/common/Table/components/AddTextBtn";
import DndContainer from "@containers/common/Table/components/DndContainer";
import { DropResult } from "@hello-pangea/dnd";
import ReusableDragRow from "@containers/common/Table/components/DndContainer/ReusableDragRow";
import { dragSort } from "@containers/common/Table/components/DndContainer/helpers";
import { useAppDispatch } from "@features/app/hooks";
import { reorderProductTypes } from "@features/productTypes/actions";

import TypeItem from "./TypeItem";
import { StyledInputStack, StyledStack } from "./TypeItem/styles";
import { allItemsHaveId } from "./helpers";

interface IProductTypes {
  newNameError: string;
}

const ProductTypes = ({ newNameError }: IProductTypes) => {
  const { watch, register, setValue } = useFormContext();
  const dispatch = useAppDispatch();

  const { productTypes, newName, shippingZips } = watch();
  const isDraggable = allItemsHaveId(productTypes);

  const handleAdd = () => {
    if (newName) {
      setValue("newName", "");

      // eslint-disable-next-line max-len
      setValue("productTypes", [
        ...productTypes,
        {
          name: newName,
          photos: [],
          description: "",
          specifications: "",
          customShippingFacility: false,
          shippingZips,
          slug: "",
        },
      ]);
    }
  };

  const reordingData = (result: DropResult) => {
    const { sortedData, items } = dragSort(result, productTypes);

    dispatch(reorderProductTypes(sortedData))
      .unwrap()
      .then(() => {
        setValue("productTypes", items);
      })
      .catch(() => {});
  };

  return (
    <StyledTableRow>
      <StyledTableCell>Product Types: *</StyledTableCell>
      <TableCell sx={{ padding: "0!important" }}>
        <DndContainer reordingData={reordingData}>
          <StyledStack isAdd>
            <StyledInputStack isAdd>
              <Input
                width="100%"
                {...register("newName")}
                placeholder="Product Type"
                errorMessage={newNameError}
              />
              <AddTextBtn text="Add" handleAdd={handleAdd} />
            </StyledInputStack>
          </StyledStack>
          {productTypes?.map((item: any, idx: number) =>
            (isDraggable ? (
              <ReusableDragRow
                id={item.id}
                key={item.id}
                index={idx}
                gridTemplateColumns="auto 1fr"
              >
                {({ providedDraggable }) => (
                  <TypeItem idx={idx} providedDraggable={providedDraggable} />
                )}
              </ReusableDragRow>
            ) : (
              <TypeItem key={item.id} idx={idx} />
            )))}
        </DndContainer>
      </TableCell>
    </StyledTableRow>
  );
};

export default memo(ProductTypes);
