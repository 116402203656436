import React, { Dispatch, SetStateAction } from "react";

import {
  Line,
  MainLine,
  ButtonVia,
  ButtonsWrapper,
  SwitcherContainer,
} from './styled';
import { switcherCollection } from "../helpers";
import { IParamsState } from "..";

interface SwitcherProps {
  children: React.ReactNode;
  tasksParams: IParamsState;
  setTasksParams: Dispatch<SetStateAction<IParamsState>>;
}

const Switcher = ({
  children,
  tasksParams,
  setTasksParams,
}: SwitcherProps) => {
  return (
    <SwitcherContainer>
      <ButtonsWrapper>
        {
          switcherCollection.map(({ title, key }) => {
            return (
              <ButtonVia
                key={key}
                onClick={() => {
                  setTasksParams((prev) => ({
                    ...prev,
                    offset: 0,
                    status: key as IParamsState['status'],
                  }));
                }}
              >
                {title}
                <Line active={tasksParams.status === key ? 1 : 0} />
              </ButtonVia>
            );
          })
        }
      </ButtonsWrapper>
      <MainLine />
      {children}
    </SwitcherContainer>
  );
};

export default Switcher;
