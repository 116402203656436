import { createAsyncThunk } from "@reduxjs/toolkit";
import { http } from "@services/RequestService";
import { customErrorHandling } from "@utils/errorHandler";
import { AxiosData } from "@utils/globalTypes";
import { AxiosResponse } from "axios";
import { Filters, constructQueryStringExceptionWithOffer } from "@utils/helpers";
import { ITask } from "@features/customers/tasks/types";

import { IChartsAndData } from "./types";

// import { IVendor } from "./types";

// dashboard
// http://{{host}}/admin/orders/dashboard?sortField=subCategoryTitle&sortOrder=ASC&dateInterval=last30Days
// admin tasks
// http://{{host}}/admin/tasks/super-admin-tasks?status=open

export const getDashboardThunk = createAsyncThunk<
	IChartsAndData,
	Filters,
	{ rejectValue: AxiosData }
>("dashboard/all", async (searchingData, thunkAPI) => {
  try {
    const queryParams = constructQueryStringExceptionWithOffer(searchingData);

    const { data: { data } } = await http.get<AxiosResponse<IChartsAndData>>(`/orders/dashboard?${queryParams}`);

    return data;
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});

export const getDashboardSortingDataThunk = createAsyncThunk<
	IChartsAndData,
	Filters,
	{ rejectValue: AxiosData }
>("dashboard-data-sorting/all", async (searchingData, thunkAPI) => {
  try {
    const queryParams = constructQueryStringExceptionWithOffer(searchingData);

    const { data: { data } } = await http.get<AxiosResponse<IChartsAndData>>(`/orders/dashboard?${queryParams}`);

    return data;
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});

export const getDashboardSeeMoreDataThunk = createAsyncThunk<
	IChartsAndData,
	Filters,
	{ rejectValue: AxiosData }
>("dashboard-data-see-more/all", async (searchingData, thunkAPI) => {
  try {
    const queryParams = constructQueryStringExceptionWithOffer(searchingData);

    const { data: { data } } = await http.get<AxiosResponse<IChartsAndData>>(`/orders/dashboard?${queryParams}`);

    return data;
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});

// ### ### ### ### ### ### ### ### ### ### ### ### ### ### ### ### ### ### ### ###
export const getSuperAdminTasksInitialThunk = createAsyncThunk<
	ITask[],
	Filters,
	{ rejectValue: AxiosData }
>("super-admin-tasks/all", async (searchingData, thunkAPI) => {
  try {
    const queryParams = constructQueryStringExceptionWithOffer(searchingData);

    const { data: { data } } = await http.get<AxiosResponse<ITask[]>>(`/tasks/super-admin-tasks?${queryParams}`);

    return data;
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});

export const getSuperAdminTasksSeeMoreThunk = createAsyncThunk<
	ITask[],
	Filters,
	{ rejectValue: AxiosData }
>("super-admin-tasks-see-more/all", async (searchingData, thunkAPI) => {
  try {
    const queryParams = constructQueryStringExceptionWithOffer(searchingData);

    const { data: { data } } = await http.get<AxiosResponse<ITask[]>>(`/tasks/super-admin-tasks?${queryParams}`);

    return data;
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});
