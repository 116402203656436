import { RootState } from '@features/app/store';

export const selectSuperAdminTasks = (state: RootState) => state.dashboard.tasks;
export const selectSuperAdminTasksLoaderStatus = (state: RootState) => state.dashboard.tasksLoading;
export const selectTasksSeeMoreButton = (state: RootState) => state.dashboard.tasksSeeMore;
export const selectTasksSeeMoreLoading = (state: RootState) => state.dashboard.tasksSeeMoreLoading;

export const selectDashboardData = (state: RootState) => state.dashboard.chartsAndData;
export const selectChartsAndDataInitialLoading = (state: RootState) => state.dashboard.chartsAndDataInitialLoading;
export const selectChartsAndDataSeeMore = (state: RootState) => state.dashboard.chartsAndDataSeeMore;
export const selectChartsAndDataSeeMoreLoading = (state: RootState) => state.dashboard.chartsAndDataSeeMoreLoading;
