export const headCells = [
  {
    label: "DATE",
  },
  {
    label: "CUSTOMER",
  },
  {
    label: "FROM IP",
  },
  {
    label: "PAYMENT METHOD",
    width: '200px',
  },
  {
    label: "ORDER COMMENT",
  },
  {
    label: "AUTO EMAILS",
  },
];

export const billingCells = [
  {
    label: "NAME",
  },
  {
    label: "COMPANY",
  },
  {
    label: "ADDRESS",
  },
  {
    label: "CITY/STATE/ZIP",
  },
  {
    label: "ORDER DATE/TIME",
  },
];

export const jobInvoicesCells = [
  {
    label: "INVOICE ID",
  },
  {
    label: "DATE CREATED",
  },
  {
    label: "DATE BILLED",
  },
  {
    label: "STATUS",
  },
  {
    label: "TRANSACTION ID",
  },
  {
    label: "TOTAL",
  },
  {
    label: "ACTIONS",
  },
];
