import { memo, useState } from 'react';

import StyledTable from '@containers/common/Table';
import Loader from '@containers/common/Loader';
import PageTitle from '@containers/common/PageTitle';
import EmptyState from '@containers/common/EmptyState';
import queryString from 'query-string';
import DndBtn from '@containers/common/Table/components/TablesActions/DndCell';
import EditCell from '@containers/common/Table/components/EditCell';
import DndContainer from '@containers/common/Table/components/DndContainer';
import ReusableDragRow from '@containers/common/Table/components/DndContainer/ReusableDragRow';
import { useLocation } from 'react-router-dom';
import TCell from '@containers/common/Table/components/TCell';
import PAGE_ROUTES from '@routes/routingEnum';
import { formattedDate } from '@utils/helpers';
import { DeveloperTaskStatus } from '@features/customers/developerTasks/types';
import { usePrevScroll } from '@utils/prevState';

import { ColoredTxt } from './styled';
import { headCells } from './helpers';
import SearchSection from './components/SearchSection';
import Switcher from './Switcher';
import { useLogic } from './useLogic';

const DevelopersTasks = () => {
  usePrevScroll();

  const { search } = useLocation();
  const { tab } = queryString.parse(search);
  const [switcherStatus, setSwitcherStatus] = useState((tab && +tab) ? +tab : 0);
  const isItOpensTasks = switcherStatus === 0;
  const {
    total,
    developersTasks,
    isLoading,
    filtersResult,
    reOrderingData,
  } = useLogic({ switcherStatus });

    let columns = headCells;

    if (isItOpensTasks) {
      // open Developers Tasks
      columns = columns.filter((item) => item.label !== 'Date Completed');
    }

  return (
    <>
      <PageTitle
        title={`Development Tasks (${total})`}
        btnName="Add Task"
        path={PAGE_ROUTES.ADD_DEVELOPER_TASKS}
      />
      { (filtersResult || !!developersTasks?.length) && <SearchSection /> }
      <Switcher
        switcherStatus={switcherStatus}
        setSwitcherStatus={setSwitcherStatus}
      >
        {
          isLoading
            ? <Loader />
            : developersTasks?.length ? (
              <DndContainer reordingData={reOrderingData}>
                <StyledTable headCells={columns}>
                  {developersTasks.map(({
                    title: name,
                    id,
                    user,
                    created,
                    deliveryDate,
                    complatedDate,
                    priority,
                    status,
                  }, index) => (
                    <ReusableDragRow
                      key={id}
                      id={id}
                      index={index}
                      gridTemplateColumns="auto auto auto auto auto auto auto auto "
                    >
                      {({ providedDraggable }) => (
                        <>
                          <EditCell title={name} path="EDIT_DEVELOPER_TASKS" params={{ taskId: id }} />
                          <TCell>
                            <ColoredTxt status={status as DeveloperTaskStatus}>
                              {status}
                            </ColoredTxt>
                          </TCell>
                          <TCell text={priority} />
                          <TCell text={formattedDate(created)} />
                          <TCell text={deliveryDate && formattedDate(deliveryDate)} />
                          {isItOpensTasks ? null : (
                            <TCell text={complatedDate && formattedDate(complatedDate)} />
                          )}
                          <TCell text={`${user.firstName} ${user.lastName}`} />
                          <DndBtn
                            providedDraggable={providedDraggable}
                          />
                        </>
                      )}
                    </ReusableDragRow>
                  ))}
                </StyledTable>
              </DndContainer>
            ) : (
              <EmptyState
                text={filtersResult ? 'No search results found' : 'You don’t have any developers tasks'}
                isAdd={!filtersResult}
              />
            )
        }
      </Switcher>
    </>
  );
};

export default memo(DevelopersTasks);
