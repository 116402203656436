import { memo, useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';

import { OrderContainer } from './styled';
import { TopNavbar, Switcher } from './components';
import { BasicInfo, Tasks, Emails, Invoices } from './components/Switcher/components';

export enum switcherEnum {
  tasks = 'tasks',
  email = 'email',
  invoices = 'invoices',
  baseInfo = 'baseInfo',
}

export const switcherCollection = [
  {
    key: 'baseInfo',
    title: 'Base Info',
    component: BasicInfo,
  },
  {
    key: 'tasks',
    title: 'Tasks',
    component: Tasks,
  },
  {
    key: 'invoices',
    title: 'Invoices',
    component: Invoices,
  },
  {
    key: 'emails',
    title: 'Emails',
    component: Emails,
  },
];

const Order = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { tab } = queryString.parse(search);
  const [switcherStatus, setSwitcherStatus] = useState((tab && +tab) ? +tab : 0);

  useEffect(() => {
    navigate(`${pathname}?tab=${switcherStatus}`);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [switcherStatus]);

  useEffect(() => {
    const isExist = !!tab || tab === '0';

    if (isExist) {
      setSwitcherStatus(+tab);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  useEffect(() => {
    const handlePopState = () => {
    const isExist = !!tab || tab === '0';

      if (isExist && +tab === switcherStatus) {
        window.history.go(-2);
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [tab, switcherStatus]);

  return (
    <OrderContainer>
      <TopNavbar />
      <Switcher
        switcherStatus={switcherStatus}
        setSwitcherStatus={setSwitcherStatus}
      />
    </OrderContainer>
  );
};

export default memo(Order);
