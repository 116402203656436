import { memo, useCallback } from 'react';

import StyledTable from '@containers/common/Table';
import PAGE_ROUTES from '@routes/routingEnum';
import { StyledTableRow } from '@containers/common/Table/styled';
import DeleteBtn from '@containers/common/Table/components/TablesActions/DeleteCell';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { deleteUser, getAllUsers } from '@features/users/actions';
import { selectUsers } from '@features/users/selectors';
import Loader from '@containers/common/Loader';
import useMount from '@customHooks/useMount';
import PageTitle from '@containers/common/PageTitle';
import EmptyState from '@containers/common/EmptyState';
import EditCell from '@containers/common/Table/components/EditCell';
import { resetUsers } from '@features/users/slice';
import TCell from '@containers/common/Table/components/TCell';
import TableCell from '@mui/material/TableCell';
import { usePrevScroll } from '@utils/prevState';
import { selectProfileStatus } from '@features/customers/tasks/selectors';

import { formattedRole, headCells } from './helpers';
import { StyledStatusBtn } from './styles';

const Users = () => {
  usePrevScroll();

  const dispatch = useAppDispatch();

  const userStatus = useAppSelector(selectProfileStatus);
  const { data: users, isLoading } = useAppSelector(selectUsers);
  const deleteAction = useCallback((id: string) => {
    dispatch(deleteUser(id)).unwrap().finally(() => dispatch(getAllUsers()));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useMount(() => {
    dispatch(getAllUsers());

    return () => dispatch(resetUsers());
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <PageTitle title="Users" btnName="Add User" path={PAGE_ROUTES.ADD_USER} />

      {!userStatus && users?.length ? (
        <StyledTable headCells={headCells}>
          { users.map(({ id, email, firstName, lastName, username, isVerified, permissions }) => (
            <StyledTableRow key={id}>
              <EditCell width="193px" title={username} path="EDIT_USER" params={{ id }} />
              <TCell width="193px" text={`${firstName} ${lastName}`} />
              <TCell width="193px" text={email} />
              <TCell width="193px" text={formattedRole(permissions)} />
              <TableCell width="193px">
                <StyledStatusBtn isVerified={isVerified}>
                  {isVerified ? 'Active' : 'Pending'}
                </StyledStatusBtn>
              </TableCell>
              <DeleteBtn
                deleteAction={() => deleteAction(id)}
                questionText="Are you sure you want to delete this user ?"
              />
            </StyledTableRow>
          ))}
        </StyledTable>
      ) : (
        <EmptyState text="You don’t have any users" isAdd />
      )}
    </>
  );
};

export default memo(Users);
