import { Dispatch, SetStateAction, useEffect } from "react";

import { useAppDispatch, useAppSelector } from "@features/app/hooks";
import {
  selectChartsAndDataSeeMore,
  selectDashboardData,
} from "@features/dashboard/selectors";
import StyledTable from '@containers/common/Table';
import { StyledButton } from "@containers/dashboard/styled";
import { getDashboardSeeMoreDataThunk, getDashboardSortingDataThunk } from "@features/dashboard/actions";
import { LIMIT_ORDERS } from "@containers/dashboard/constants";

import TableRow from "./TableRow";
import { headCells } from "./tableData";
import { IDashboardParams } from "..";

interface IDataComponentProp {
  dashboardParams: IDashboardParams;
  setDashboardParams: Dispatch<SetStateAction<IDashboardParams>>;
}

const Data = ({
  dashboardParams,
  setDashboardParams,
}: IDataComponentProp) => {
  const dispatch = useAppDispatch();
  const chartsAndData = useAppSelector(selectDashboardData);
  const chartsAndDataSeeMore = useAppSelector(selectChartsAndDataSeeMore);

  const handleLoadMoreData = () => {
    dispatch(getDashboardSeeMoreDataThunk({ ...dashboardParams }))
      .unwrap()
      .then(() => {})
      .catch(() => {});
  };

  const handleEstimatesSorting = (sortKey: string) => {
    const { sortOrder, dateInterval } = dashboardParams;

      const otherOne = sortOrder === 'DESC' ? 'ASC' : 'DESC';

      dispatch(getDashboardSortingDataThunk({
        ...dashboardParams,
        dateInterval,
        sortField: sortKey,
        sortOrder: otherOne,
      }))
        .unwrap()
        .then(() => {
          setDashboardParams((prev) => ({
            ...prev,
            sortOrder: otherOne,
            sortField: sortKey,
          }));
        })
        .catch(() => {});
  };

  useEffect(() => {
    if (chartsAndData?.dashboardData && dashboardParams.limit > chartsAndData?.dashboardData?.ordersData?.length) {
      handleLoadMoreData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardParams]);

  if (!chartsAndData) {
    return null;
  }

  const { ordersData } = chartsAndData.dashboardData;

  if (ordersData?.length) {
    return (
      <div>
        <StyledTable headCells={headCells} handleSorting={handleEstimatesSorting}>
          {ordersData.map((item) => {
            return (
              <TableRow {...item} />
            );
          })}
        </StyledTable>
        {
          !chartsAndDataSeeMore &&
          ordersData.length ? (
            <StyledButton
              onClick={() => {
                setDashboardParams((prev) => ({ ...prev, limit: prev.limit + LIMIT_ORDERS }));
              }}
            >
              See More
            </StyledButton>
          ) : null
        }
      </div>
    );
  }

  return null;

  // return (
  //   <div>
  //     <EmptyState
  //       text={!ordersData.length ? 'You don’t have any data' : ""}
  //     />
  //   </div>
  // );
};

export default Data;
