/* eslint-disable max-len */
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { selectChartsAndDataInitialLoading, selectDashboardData } from '@features/dashboard/selectors';
import { useAppSelector } from '@features/app/hooks';
import { priceFormatting } from '@utils/helpers';
import { LoaderWrapper } from '@containers/dashboard/styled';
import { CircularProgress } from '@mui/material';
import { formatPrice } from '@containers/dashboard';

import { CanvasWrapper, Container } from './styled';
import HourlyChart from './daily';
import WeeklyCharts from './weekly';
import MonthlyCharts from './monthly';
// import { faker } from '@faker-js/faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const ChartsComponent = ({
  dateInterval,
}: {
  dateInterval: 'last30Days' | 'last7Days' | 'today';
}) => {
  const dashboardInfo = useAppSelector(selectDashboardData);

  if (!dashboardInfo) {
    return null;
  }

  const render = () => {
    if (dateInterval === 'today') {
      return <HourlyChart data={dashboardInfo?.dashboardData.transactionsRevenueData} />;
    }

    if (dateInterval === 'last7Days') {
      return <WeeklyCharts data={dashboardInfo?.dashboardData.transactionsRevenueData} />;
    }

    if (dateInterval === 'last30Days') {
      return <MonthlyCharts data={dashboardInfo?.dashboardData.transactionsRevenueData} />;
    }
  };

  return (
    <Container className="wrapper">
      <CanvasWrapper>
        <p>Total Revenue</p>
        <h2>{formatPrice(Number(dashboardInfo?.dashboardData?.totalRevenue || 0))}</h2>
        {render()}
      </CanvasWrapper>
      <div />
      {/* <CanvasWrapper /> */}
      {/* <CanvasWrapper>
        <p>Total Revenue</p>
        <h2>{priceFormatting(dashboardInfo?.dashboardData.totalRevenue)}</h2>
        {render()}
      </CanvasWrapper> */}
    </Container>
  );
};

export default ChartsComponent;
