import { memo } from 'react';

import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Typography from '@mui/material/Typography';
import PAGE_ROUTES from '@routes/routingEnum';
import { buildRoute } from '@routes/helpers';
import { PrevState } from '@utils/prevState';

import { StyledBackBox } from './styled';

interface IBackButton {
  path: keyof typeof PAGE_ROUTES;
  params?: Record<string, string> | null;
 }

const BackButton = ({ path, params }: IBackButton) => {
  const newPath = !params ? PAGE_ROUTES[path] : buildRoute(PAGE_ROUTES[path], { ...params });

  const navigate = useNavigate();

  return (
    <span
      onClick={() => {
        PrevState.set({ isDo: true });
        navigate(newPath);
      }}
    >
      <StyledBackBox>
        <ArrowBackIosIcon />
        <Typography variant="body3">
          Back
        </Typography>
      </StyledBackBox>
    </span>
  );
};

export default memo(BackButton);
