import { styled } from "@mui/material/styles";
import RowComponent from "@containers/common/Table/components/RowComponent";
import { Box } from "@mui/material";
import { COLORS } from "@customTypes/global/theme/colors";
import { Link } from "react-router-dom";

export const StyledRowComponent = styled(RowComponent)(({ theme }) => ({
  ".MuiTableCell-root": {
    padding: "9px 16px",
  },
  ".MuiTableCell-root.MuiTableCell-body": {
    width: "352px",
  },
  [theme.breakpoints.down("lg")]: {
    ".MuiTableCell-root.MuiTableCell-body": {
      width: "300px",
    },
  },
  [theme.breakpoints.down("md")]: {
    ".MuiTableCell-root.MuiTableCell-body": {
      width: "250px",
    },
  },
}));

export const OrderSection = styled(Box)(() => ({
  marginTop: "18px",
}));

export const OrderIdLink = styled(Link)(() => ({
  textDecoration: "underline",
  whiteSpace: "nowrap",
  color: COLORS.blue.main,

  td: {
    color: COLORS.blue.main,
    cursor: "pointer",
  },
}));
