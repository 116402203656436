import { useFormContext } from 'react-hook-form';
import { Job } from '@features/orders/order/types';
import { Box } from '@mui/material';

import {
FirstViewBodyException,
RedLabel,
StyledWrapperDynamically,
} from './styled';

interface EditableAttributesProps {
  job: Job;
  jobIndex: number;
}

const UneditableAttributes = ({ job, jobIndex }: EditableAttributesProps) => {
  const CUSTOM_ESTIMATE_KEY = 'custom estimate';
  const { watch } = useFormContext();
  const keyByIndex = `jobs[${jobIndex}]`;

  let attributes = { ...job?.item } as Record<string, any>;

  delete attributes.item;

  if (job?.customEstimateId) {
    attributes = {
      ...attributes,
      [CUSTOM_ESTIMATE_KEY]: job.customEstimateId,
    };
  }

  if (job?.widthGP && job?.heightGP && job?.quantityGP) {
    const { quantity, ...rest } = attributes;

    // attributes need check this variable un usable
    attributes = {
      ...rest,
      width: job.widthGP,
      height: job.heightGP,
      quantity: job.quantityGP,
    };
  }

  const { jobAttributes } = job;

  return (
    <FirstViewBodyException>
      {
        jobAttributes.map((attribute, index) => {
          const isRed = attribute.red;
          const LabelComponent = isRed ? RedLabel : 'label';

          return (
            <StyledWrapperDynamically key={attribute.id}>
              <LabelComponent>
                {attribute.attributeCategoryName}
                :
              </LabelComponent>
              <p style={{ color: isRed ? 'red' : 'inherit' }}>
                {watch(`${keyByIndex}.attributes[${index}].attributeName`)}
              </p>
              <Box
                sx={{ gap: 1 }}
              >
                <p>$</p>
                <p>{watch(`${keyByIndex}.attributes[${index}].attributePrice`)}</p>
              </Box>
            </StyledWrapperDynamically>
          );
        })
      }
    </FirstViewBodyException>
  );
};

export default UneditableAttributes;
