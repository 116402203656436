import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "@features/app/hooks";
import { selectOrderDetails } from "@features/orders/order/selectors";
import { Box } from "@mui/material";
import { getOrderDetails } from "@features/orders/order/actions";
import { useNavigate, useParams } from "react-router-dom";
import PAGE_ROUTES from "@routes/routingEnum";
import Table from "@containers/common/Table";
import TCell from "@containers/common/Table/components/TCell";
import { formattedDate } from "@utils/helpers";
import { StyledTableRow } from "@containers/common/Table/styled";
import moment from "moment";
import Loader from "@containers/common/Loader";
import EmptyState from "@containers/common/EmptyState";
import Button from "@containers/common/Button";

// import Modal from "../../../common/Modal";
import { headCells } from "../../../../columns";
import OrderDetails from "./OrderDetails";
import { StyleWrapper, StyledLink, StyledWrapper } from "./styled";
import EmailAuto from "./EmailAuto";
import Modals from "./Modals";
import PayedInfo from "./PayedInfo";

const BasicInfo = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectOrderDetails);
  const [lastUpdated, setLastUpdated] = useState("");
  const [isBillingAddressOpen, setIsBillingAddressOpen] = useState(false);
  const { order, isLoading } = state;
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);

  const handelLogin = () => {
    if (!order?.customer?.id) {
      console.log("Customer id is missing", order?.customer);

      return null;
    }

    navigate(`/customers/customers/edit/${order.customer.id}`);

    // // redirect to client with logged user
    // dispatch(loginAsCustomers(order?.customer?.id))
    //   .unwrap()
    //   .then(({ refreshToken }) => {
    //     const url = `${WEB_API_URL}/auth/login-as-admin/${refreshToken}`;
    //     window.open(url, "_blank", "noopener,noreferrer");
    //   })
    //   .catch(() => {});
  };

  useEffect(() => {
    const updatedDates = order?.jobs
      ?.map((job) => job.updated)
      .filter((date) => date);

    const result = updatedDates?.reduce((prev: string, current: string) => {
      if (prev) {
        return moment(prev).isAfter(current) ? prev : current;
      }

      return current;
    }, "");

    setLastUpdated(result || "");
  }, [order?.jobs]);

  useEffect(() => {
    try {
      if (!orderId) {
        navigate(PAGE_ROUTES.OPEN_ORDERS);

        return;
      }

      dispatch(getOrderDetails({ id: orderId })).unwrap()
      .catch((error) => {
        console.log(error);
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  if (isLoading) {
    return <Loader />;
  }

  if (!isLoading && !Object.keys(order || {})?.length) {
    return (
      <EmptyState
        text="There was an error with displaying this order."
      />
    );
  }

  return (
    <Box sx={{ maxWidth: "1156px" }}>
      <StyleWrapper>
        <Table title="" headCells={headCells}>
          <StyledTableRow>
            <TCell>
              {order?.created
              ? (
                <div>
                  <p>Order Date:</p>
                  <span>{formattedDate(order?.created)}</span>
                </div>
                )
               : ""}
              <br />
              {lastUpdated
              ? (
                <div>
                  <p>Last Updated:</p>
                  <span>{formattedDate(lastUpdated)}</span>
                </div>
              )
               : ""}
            </TCell>
            <TCell>
              <StyledLink onClick={handelLogin}>
                {order?.customer.firstName}
                {' '}
                {order?.customer.lastName}
              </StyledLink>

              <StyledLink
                onClick={() => {
                  setIsBillingAddressOpen(true);
                }}
              >
                Billing Address
              </StyledLink>
            </TCell>
            <TCell text={order.customer?.IPAddress} />
            <TCell>
              <PayedInfo order={order} />
            </TCell>
            <TCell width="198">
              <StyledWrapper>
                <p>{order.comment}</p>
              </StyledWrapper>
              {/* Dots starts after 81 length */}
              {order.comment.length > 82 && (
              <Button
                onClick={() => {
                  setIsCommentModalOpen(true);
                }}
              >
                View All
              </Button>
              )}
            </TCell>
            <TCell>
              <EmailAuto checked={order.autoEmails} orderId={order.id} />
            </TCell>
          </StyledTableRow>
        </Table>
      </StyleWrapper>
      <Modals
        order={order}
        isBillingAddressOpen={isBillingAddressOpen}
        isCommentModalOpen={isCommentModalOpen}
        setIsBillingAddressOpen={setIsBillingAddressOpen}
        setIsCommentModalOpen={setIsCommentModalOpen}
      />
      <OrderDetails />
    </Box>
  );
};

export default BasicInfo;
