import { memo } from "react";

import Typography from "@mui/material/Typography";
import RowComponent from "@containers/common/Table/components/RowComponent";
import Stack from "@mui/material/Stack";
import AddTextBtn from "@containers/common/Table/components/AddTextBtn";
import { useFormContext } from "react-hook-form";
import RowTitle from "@containers/common/Table/components/RowTitle";

const BillingAddress = () => {
  const { watch } = useFormContext();
  const { billingInfo = {}, id: customerId } = watch();
  const { id, address1, state, country, city, zipCode, company, address2, phone, firstName, lastName } = billingInfo;

  const handleMap = () => {
    const address = encodeURIComponent(`${address1} ${city} ${state}`);
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${address}`;

    window.open(googleMapsUrl, "_blank");
  };

  return (
    <RowComponent label="Billing Address">
      {id ? (
        <Stack direction="row" alignItems="center" gap="16px">
          <Stack>
            <Typography>{company}</Typography>
            <Typography>
              {firstName}
              {' '}
              {lastName}
            </Typography>
            {/* <Typography>{phone}</Typography> */}
            <Typography>{address1}</Typography>
            <Typography>{address2}</Typography>
            <Typography>
              {city}
              ,
              {' '}
              {state}
              ,
              {' '}
              {zipCode}
              ,
              {' '}
              {country}
            </Typography>
          </Stack>

          <RowTitle
            title="Edit"
            path="BILLING_ADDRESS"
            params={{ billingId: id, customerId }}
          />
          <AddTextBtn text="Map it" handleAdd={handleMap} />
        </Stack>
      ) : (
        <RowTitle
          title="Add"
          path="BILLING_ADDRESS"
          params={{ customerId, billingId: "" }}
        />
      )}
    </RowComponent>
  );
};

export default memo(BillingAddress);
