import { memo, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { getDashboardThunk } from '@features/dashboard/actions';
import PageTitle from '@containers/common/PageTitle';
import { Filters } from '@utils/helpers';
import { LIMIT_ORDERS } from '@containers/dashboard/constants';
import { CircularProgress } from '@mui/material';
import { selectChartsAndDataInitialLoading } from '@features/dashboard/selectors';
import { LoaderWrapper } from "@containers/dashboard/styled";

import Data from './Data';
import ChartsComponent from './Charts';
import { ChartsContainer, FiltersWrapper, Item } from './styled';

export interface IDashboardParams {
  sortField: string;
  sortOrder: 'ASC' | 'DESC';
  dateInterval: 'last30Days' | 'last7Days' | 'today';
  limit: number;
  offset: number;
}

const ChartsAndData = () => {
  const dispatch = useAppDispatch();
  const [dashboardParams, setDashboardParams] = useState<IDashboardParams>({
    sortField: 'amount',
    sortOrder: 'DESC',
    dateInterval: 'last30Days',
    limit: LIMIT_ORDERS,
    offset: 0,
  });

  const chartsAndDataInitialLoading = useAppSelector(selectChartsAndDataInitialLoading);

  const { dateInterval } = dashboardParams;

  const changeFilter = (type: IDashboardParams['dateInterval']) => {
    setDashboardParams((prev) => ({ ...prev, dateInterval: type }));
  };

  const fetchData = () => {
    dispatch(getDashboardThunk(dashboardParams as unknown as Filters));
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardParams.dateInterval]);

  return (
    <ChartsContainer>
      <FiltersWrapper>
        <PageTitle title="Dashboard" isShowBtn={false} />
        <Item
          active={dateInterval === 'today'}
          onClick={() => changeFilter('today')}
        >
          Today
        </Item>
        <Item
          active={dateInterval === 'last7Days'}
          onClick={() => changeFilter('last7Days')}
        >
          Last 7 days
        </Item>
        <Item
          active={dateInterval === 'last30Days'}
          onClick={() => changeFilter('last30Days')}
        >
          Last 30 days
        </Item>
      </FiltersWrapper>
      {
        chartsAndDataInitialLoading ? (
          <LoaderWrapper>
            <CircularProgress />
          </LoaderWrapper>
        ) : (
          <div>
            <ChartsComponent dateInterval={dateInterval} />
            <Data dashboardParams={dashboardParams} setDashboardParams={setDashboardParams} />
          </div>
        )
      }
    </ChartsContainer>
  );
};

export default memo(ChartsAndData);
