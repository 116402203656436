import { useEffect, useState } from "react";

import { useFormContext } from "react-hook-form";
import download from "@assets/images/download.svg";

import Modal from "../../../../../../../../../../../common/Modal";
import UploadBox, { CertificateEnumType } from "./components/UploadBox";
import { useFileUploadLogic } from "./components/UploadBox/useFileUploadLogic";
import { Title } from "../../styled";
import { Content, Underlined, Wrapper } from "./styled";
import EachPrint from "./components/EachPrint";

interface PrintProps {
  setId: string;
  jobIndex: number;
  setIndex: number;
  shipmentIndex: number;
}

const Print = ({ setId, jobIndex, setIndex, shipmentIndex }: PrintProps) => {
  const [isOpenUploadModal, setIsOpenUploadModal] = useState(false);
  const defaultKey = `jobs[${jobIndex}].shipments[${shipmentIndex}].sets[${setIndex}]`;
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const logic = useFileUploadLogic(defaultKey, setValue, setId);

  const frontPrint = watch(`${defaultKey}.frontPrint`) as string;
  const backPrint = watch(`${defaultKey}.backPrint`) as string;

  const messagesObjJobs = errors?.jobs as Record<string, any>;

  let messageShipmentSets = {} as Record<string, any>;

  if (messagesObjJobs && messagesObjJobs[jobIndex]) {
    messageShipmentSets =
      messagesObjJobs[jobIndex]?.shipments?.[shipmentIndex]?.sets?.[setIndex];
  }

  useEffect(() => {
    if (frontPrint && backPrint) {
      setTimeout(() => {
        setIsOpenUploadModal(false);
      }, 1000);
    }
  }, [frontPrint, backPrint]);

  return (
    <>
      <Modal
        sx={{ padding: "24px" }}
        title="UPLOAD PRINT FILES"
        open={isOpenUploadModal}
        handleClose={() => {
          setIsOpenUploadModal(false);
        }}
      >
        <Wrapper>
          <UploadBox
            title="Front Side Print"
            desc=""
            mobileButton=""
            uploaded={frontPrint}
            keyType={CertificateEnumType.frontPrint}
            {...logic}
          />

          <UploadBox
            title="Back Side Print"
            desc=""
            mobileButton=""
            uploaded={backPrint}
            {...logic}
            keyType={CertificateEnumType.backPrint}
          />
        </Wrapper>
      </Modal>
      <Title>
        PRINT FILES
        <Underlined
          onClick={() => {
            setIsOpenUploadModal(true);
          }}
        >
          <img src={download} alt={download} />
          <p>Upload print files</p>
        </Underlined>
      </Title>
      <Content>
        <EachPrint
          text="Front:"
          print={frontPrint}
          defaultKey={defaultKey}
          deleteKey="frontPrint"
        />

        <EachPrint
          text="Back:"
          print={backPrint}
          defaultKey={defaultKey}
          deleteKey="backPrint"
        />
      </Content>
    </>
  );
};

export default Print;
