export const headCells = [
  {
    label: 'ID',
  },
  {
    label: 'DATE',
  },
  {
    label: 'BIL TO',
  },
  {
    label: 'STATUS',
  },
  {
    label: 'VENDOR JOB',
  },
  {
    label: 'TRACKING',
  },
];
