import {
  SyntheticEvent,
  createElement,
  memo,
  useEffect,
  useState,
} from "react";

import {
  GrandFormatOptions,
  IProductsPayload,
} from "@features/products/basicInfo/types";
import TitlesWithBackButton from "@containers/common/TitlesWithBackButton";
import { StyledStack } from "@containers/common/StyledAddEditTables/styled";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";

import { StyledDivider, StyledTab, StyledTabs } from "./styles";
import { tabsOptions } from "./helpers";

interface IInputsTable {
  getProduct?: () => void | undefined;
  editData?: IProductsPayload;
  grandFormatOptions?: GrandFormatOptions | null;
}

const InputsTable = ({
  getProduct,
  editData,
  grandFormatOptions,
}: IInputsTable) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { tab } = queryString.parse(search);

  const [value, setValue] = useState(tab && +tab ? +tab : 0);

  const handleChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: number,
  ) => {
    setValue(newValue);
    navigate(`${pathname}?tab=${newValue}`);
  };

  return (
    <TitlesWithBackButton
      path="PRODUCTS_PRODUCTS"
      title={editData ? "Edit Product" : "Add Product"}
    >
      {!!editData && (
        <StyledStack>
          <StyledDivider>
            <StyledTabs value={value} onChange={handleChange}>
              {tabsOptions.map(({ label }) => (
                <StyledTab key={label} label={label} disableRipple />
              ))}
            </StyledTabs>
          </StyledDivider>
        </StyledStack>
      )}
      {createElement(tabsOptions[value].component, {
        editData,
        grandFormatOptions,
        getProduct,
        subTitle: editData?.productSKU,
      })}
    </TitlesWithBackButton>
  );
};

export default memo(InputsTable);
