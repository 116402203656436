import Select from '@containers/common/Select';
import Checkbox from '@containers/common/Checkbox';
import CloseIcon from '@mui/icons-material/Close';
import ErrorMessage from '@containers/common/ErrorMessage';
import TextareaEditor from '@containers/common/Editor';
import { useAppSelector } from '@features/app/hooks';
import { selectOrderDetails } from '@features/orders/order/selectors';

import { useEmailUpdate } from './useEmailUpdate';
import {
  Wrapper,
  Parts,
  StyledInput,
  SpecialParts,
  StyledTextarea,
  CheckboxWrapper,
  EmailUpdateContainer,
  StyledText,
} from './styled';

interface EmailUpdateProps {
  keyByIndex: string;
  jobIndex: number;
}

const EmailUpdate = ({ keyByIndex, jobIndex }: EmailUpdateProps) => {
  const {
    textErrorMessage,
    emailsErrorMessage,
    email,
    inputRef,
    emails,
    emailTemplateId,
    options,
    templateErrorMessage,
    setEmail,
    watch,
    setValue,
    handleKeyDown,
    handleDelete,
  } = useEmailUpdate(keyByIndex, jobIndex);

  return (
    <EmailUpdateContainer>
      <Parts>
        Email Update
      </Parts>
      <Parts>
        <TextareaEditor
          value={watch(`${keyByIndex}.textTemplate`)}
          name={`${keyByIndex}.textTemplate`}
          setValue={setValue}
          placeholder="Email text"
          errorMessage={textErrorMessage}
        />
      </Parts>
      <SpecialParts>
        <Select
          orderDesignStyle
          value={emailTemplateId || ''}
          placeholderText="Select email template"
          name={`${keyByIndex}.emailTemplateId`}
          options={options}
          errorMessage={templateErrorMessage}
        />
        <div>
          <Wrapper
            border={emailsErrorMessage ? 1 : 0}
            onClick={() => {
              // @ts-ignore
              inputRef?.current?.focus();
            }}
          >
            {
              emails?.map((e: string, index: number) => (
                <StyledText key={e}>
                  {e}
                  <CloseIcon onClick={() => handleDelete(index)} />
                </StyledText>
              ))
            }
            {/* Custom Input */}
            <StyledInput
              ref={inputRef}
              type="text"
              value={email}
              placeholder="Additional emails"
              onKeyDown={handleKeyDown}
              onChange={(e) => {
              setEmail(e.target.value);
             }}
            />
          </Wrapper>
          {emailsErrorMessage && <ErrorMessage message={emailsErrorMessage} />}
        </div>
        <CheckboxWrapper>
          <Checkbox
            name={`${keyByIndex}.sendEmail`}
          />
          <p>Send an email update to customer after submitting</p>
        </CheckboxWrapper>
      </SpecialParts>
    </EmailUpdateContainer>
  );
};

export default EmailUpdate;
