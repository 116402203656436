import { createSlice } from "@reduxjs/toolkit";

import { getConversationLastMessage, getConversations, getOnlyConversationSets } from "./actions";
import { IState } from "./types";

const initialState: IState = {
  message: '',
  conversation: [],
  orderSetsInfo: [],
  initLoader: true,
  disallowMessage: false,
  lastMessage: '',
};

const conversationSlice = createSlice({
  name: "conversationSlice",
  initialState,
  reducers: {
    addNewMessage: (state, { payload }) => {
      state.conversation = [...state.conversation, payload];
      state.message = '';
    },
    clearMessages: (state) => {
      state.conversation = [];
      state.initLoader = true;
    },
    updateSetUnopenedStatus: (state, { payload }) => {
      state.orderSetsInfo = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getConversationLastMessage.fulfilled, (state, { payload }) => {
      if (payload?.length) {
        state.lastMessage = payload[0].message;
      }
    });
    builder.addCase(getConversations.fulfilled, (state, { payload: response }) => {
      const { currentSetInfo, orderSetsInfo, disallowMessage } = response;

      const { messages } = currentSetInfo;

      if (!messages.length) {
        state.initLoader = false;
        state.message = 'There are no more comments.';
        state.orderSetsInfo = orderSetsInfo;
        state.disallowMessage = disallowMessage;

        return state;
      }

      const old = state.conversation;
      const newItems = [...messages].reverse();
      const newArray = [...newItems, ...old];

      if (!state.conversation?.length) {
        state.initLoader = false;
      }

      state.message = '';
      state.conversation = newArray;
      state.disallowMessage = disallowMessage;

      if (!state?.orderSetsInfo?.length) {
        state.orderSetsInfo = orderSetsInfo;
      }
    });
    builder.addCase(getConversations.rejected, (state) => {
      state.message = 'Something went wrong';
    });
    // new in page load during call
    builder.addCase(getOnlyConversationSets.fulfilled, (state, { payload: response }) => {
      const { orderSetsInfo } = response;

      state.orderSetsInfo = orderSetsInfo;
    });
  },
});

export const { clearMessages, addNewMessage, updateSetUnopenedStatus } = conversationSlice.actions;
export default conversationSlice.reducer;
