import { memo, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import useMount from '@customHooks/useMount';
import Loader from '@containers/common/Loader';
import PAGE_ROUTES from '@routes/routingEnum';
import { IAddTaskPayload } from '@features/customers/tasks/types';
import { getTaskById } from '@features/customers/tasks/actions';
import { selectTasks } from '@features/customers/tasks/selectors';
import { buildRoute } from '@routes/helpers';
import { resetTasks } from '@features/customers/tasks/slice';

import InputsTable from '../InputsTable';
import { formattedData } from './helpers';

const EditComponent = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { taskId = '', customerId = '' } = useParams();
  const [editData, setEditData] = useState<IAddTaskPayload | null>(null);
  const { isLoading } = useAppSelector(selectTasks);
  const backPath = customerId ? PAGE_ROUTES.EDIT_CUSTOMER : PAGE_ROUTES.TASKS;
  const backParams = customerId ? { taskId, customerId } : { taskId } as Record<string, string>;
  const backNavigation = buildRoute(backPath, backParams);
  const [orderNumber, setOrderNumber] = useState<number | null>(null);
  const [orderId, setOrderId] = useState<string | null>(null);

  useMount(() => {
    dispatch(getTaskById(taskId)).unwrap().then((data) => {
      setOrderNumber(data.orderNumber);
      setOrderId(data.orderId);

      const newData = formattedData(data);

      setEditData(newData);

      return () => dispatch(resetTasks());
    }).catch(() => navigate(backNavigation));
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {editData && <InputsTable orderId={orderId} orderNumber={orderNumber} editData={editData} />}
    </>
  );
};

export default memo(EditComponent);
