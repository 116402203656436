import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const Container = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '24px',
  justifyContent: 'space-between',
  marginBottom: '24px',

  canvas: {
    width: '100% !important',
    height: '300px !important',
  },
}));

export const CanvasWrapper = styled(Box)(() => ({
  border: '1px solid #CFD9E0',
  padding: '16px',

  p: {
    marginBottom: '10px',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '100%',
    letterSpacing: '0%',
  },
  h2: {
    marginBottom: '60px',
    fontWeight: '700',
    fontSize: '32px',
    lineHeight: '100%',
    letterSpacing: '0%',
  },
}));
