import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const Container = styled(Box)(() => ({
  width: 'min-content',
}));

export const Wrapper = styled(Box)(() => ({
  gap: '3px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  whiteSpace: 'pre',

  p: {
    fontSize: '12px',
    fontWeight: 400,

    '&.bold': {
      fontWeight: 700,
    },
  },
}));
