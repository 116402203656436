import { memo } from "react";

import LinkedString from "@containers/Orders/Order/components/common/LinkedString";
import { getCDNImagePath } from "@utils/helpers";
import { Box, Typography } from "@mui/material";
import { handleWithoutFormRequest } from "@containers/common/FileUploader";
import { useAppDispatch } from "@features/app/hooks";
import { useFormContext } from "react-hook-form";
import deleteSetNestedItemsCross from "@assets/images/delete-set-nested-items-cross.svg";

import { UploadedArtworkPrintWrapper, LinkedTextWrapper } from "./styled";

const EachPrint = ({
  text,
  print,
  defaultKey,
  deleteKey,
}: any) => {
  const { watch, setValue } = useFormContext();
  const dispatch = useAppDispatch();

  const handleDeleteRequest = () => {
    const madeKey = `${defaultKey}.${deleteKey}`;

    setValue(madeKey, null);
    handleWithoutFormRequest({
      watch,
      dispatch,
      fieldPath: madeKey,
      updateLogicType: "sets",
    });
  };

  return (
    <UploadedArtworkPrintWrapper>
      <LinkedTextWrapper>
        <div>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Typography>{text}</Typography>
            {print ? (
              <LinkedString
                width="210px"
                target="_blank"
                href={getCDNImagePath(print)}
                text={print.replace('images/', '')}
              />
            ) : (
              `${" "} - `
            )}
          </Box>
        </div>
        {print && (
          <img
            src={deleteSetNestedItemsCross}
            alt=""
            onClick={() => {
              handleDeleteRequest();
            }}
          />
        )}
      </LinkedTextWrapper>
    </UploadedArtworkPrintWrapper>
  );
};

export default memo(EachPrint);
