import { memo } from 'react';

import SuperAdminTasks from './Components/Tasks';
import ChartsAndData from './Components/ChartsAndData';

export function formatPrice(price = 0) {
  return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
  }).format(price);
}

const Dashboard = () => {
  return (
    <div>
      <SuperAdminTasks />
      <ChartsAndData />
    </div>
  );
};

export default memo(Dashboard);
