export enum ShipmentStatusesEnum {
  UPS = 'UPS',
  FEDEX = "FEDEX",
  isEditable = 'isEditable'
}

export enum OrderTypesEnum {
  open = 'open',
  completed = 'completed',
  cancelled = 'cancelled',
}

export enum OrderListUpdateRequestFieldKeys {
  status = 'status',
  vendorId = 'vendorId',
  vendorJob = 'vendorJob',
  vendorCost = 'vendorCost',
  mailingPO = 'mailingPO',
  mailPo = 'mailPo',
}

// updated by backend requirements
export enum OrderStatusesEnum {
  AwaitingArtwork = 'Awaiting Artwork',
  Received = 'Received',
  ProofRejected = 'Proof Rejected',
  IssueWithYourArt = 'Issue with your art',
  BleedRequiredForRigidSigns = 'Bleed Required for Rigid Signs',
  PrintingBleed = 'Printing Bleed',
  ArtReminder = 'Art Reminder',
  OnHold = 'On Hold',

  FileReview = 'File Review',
  PreFlightArtwork = 'Pre-Flight Artwork',
  AwaitingProofApproval = 'Awaiting Proof Approval',
  OneSideApprovedOnly = 'One Side Approved Only',
  DataNeeded = 'Data Needed',
  StillAwaitingProofApproval = 'Still Awaiting Proof Approval',
  AdjustedProofNotice = 'Adjusted Proof Notice',

  Printing = 'Printing',
  Approved = 'Approved',
  Processing = 'Processing',
  BatchImposition = 'Batch Imposition',
  Plated = 'Plated',
  Finishing = 'Finishing',
  Bindery = 'Bindery',

  QualityControlReprint = 'Quality Control Reprint',

  MailingShipping = 'Mailing/Shipping',
  Shipping = 'Shipping',
  MailingServicesAddressing = 'Mailing Services Addressing',
  Fulfillment = 'Fulfillment',

  Complete = 'Complete',
  Canceled = 'Canceled',

  EmailCopy = 'Email copy',

  UploadedArt = 'Artwork Uploaded',
}

export enum OrderListStatusesForArrowsAdmin {
  OPEN = 'open',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
}
