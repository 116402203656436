import { memo } from 'react';

import StyledTypography from '@containers/common/StyledTypography';
import { useNavigate } from 'react-router-dom';
import PAGE_ROUTES from '@routes/routingEnum';
import { buildRoute } from '@routes/helpers';
import { PrevState } from '@utils/prevState';

export interface IRowTitle {
  title: string;
  path: keyof typeof PAGE_ROUTES;
  params?: Record<string, string>;
  underline?: boolean;
  queryString?: string;
}

const RowTitle = ({ queryString, title, path, params, underline = true }: IRowTitle) => {
  const navigate = useNavigate();
  const handleEdit = () => {
    PrevState.set({ scroll: window?.scrollY });

    let url = !params ? PAGE_ROUTES[path] : buildRoute(PAGE_ROUTES[path], { ...params });

    if (queryString) {
      url += queryString;
    }

    navigate(url);
  };

  return (
    <StyledTypography
      color="blue"
      underLine={underline}
      onClick={handleEdit}
      variant="body3"
      cursor="pointer"
    >
      {title}
    </StyledTypography>
  );
};

export default memo(RowTitle);
