import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TextareaEditorSection = styled(Box)`
  padding: 12px 0px;

  .quill {
    background: #fff;
  }
  .ql-editor {
    min-height: 100px;
    h1 {
      color: #000000;
    }
    h3 {
      color: #000000;
    }
  }
`;
