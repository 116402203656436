import { memo } from "react";

import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";

import tableDown from "../../../assets/images/table-down.svg";
import tableUp from '../../../assets/images/table-up.svg';
import {
  StyledMuiTable,
  StyledTableRow,
  TableCellStyle,
  StyledTableContainer,
  IconsWrapper,
  TitleWithArrows,
} from "./styled";
import { IStyledTable } from "./types";

const StyledTable = ({
  headCells,
  children,
  seperatedCategoriesData,
  tableTitle,
  handleCheck,
  colSpan,
  btn = null,
  checkboxVia,
  handleSorting,
}: IStyledTable) => {
  return (
    <StyledTableContainer>
      <StyledMuiTable>
        <TableHead>
          <StyledTableRow>
            {tableTitle ? (
              <TableCell colSpan={colSpan}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body3" textTransform="uppercase">
                    {tableTitle}
                  </Typography>
                  {btn && btn}
                </Stack>
              </TableCell>
            ) : headCells ? (
              headCells.map((props) => {
                const { paramKey, sortable, sortKey, ...restProps } = props;

                const { label, id, checkbox } = restProps;

                return (
                  <TableCell key={label} {...restProps}>
                    <Typography textTransform="uppercase" variant="body3">
                      <TableCellStyle>
                        <TitleWithArrows
                          cursor={!!sortable}
                          onClick={() => sortKey && handleSorting?.(sortKey)}
                        >
                          {checkbox ? checkboxVia : label}
                          {sortable && (
                            <IconsWrapper>
                              <img src={tableUp} alt="table Up" />
                              <img src={tableDown} alt="table Down" />
                            </IconsWrapper>
                          )}
                        </TitleWithArrows>
                        {label.toUpperCase() !== "QUANTITY" && id && handleCheck && (
                          <Checkbox
                            disableRipple
                            checked={seperatedCategoriesData?.includes(id)}
                            onChange={() => handleCheck(id)}
                          />
                        )}
                      </TableCellStyle>
                    </Typography>
                  </TableCell>
                );
              })
            ) : null}
          </StyledTableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </StyledMuiTable>
    </StyledTableContainer>
  );
};

export default memo(StyledTable);
