import PAGE_ROUTES from '@routes/routingEnum';

export const headCells = [
  {
    label: 'ESTIMATE ID',
    sortKey: 'id',
    sortable: true,
  },
  {
    label: 'JOB NAME',
  },
  {
    label: 'CUSTOMER',
    sortKey: 'firstName',
    sortable: true,
  },
  {
    label: 'STATUS',
  },
  {
    label: 'DATE',
    sortKey: 'dateRequested',
    sortable: true,
  },
  {
    label: 'ACTIONS',
  },
];
export const getCurrentPage = (pathname: string) => {
  if (pathname.includes('open-estimates')) {
    return 'received';
  }

  if (pathname.includes('complete-estimates')) {
    return 'complete';
  }

  if (pathname.includes('inactive-estimates')) {
    return 'rejected';
  }

  return '';
};

export const getPath = (pageName: string, isMain?: boolean) => {
  const obj: Record<string, keyof typeof PAGE_ROUTES> = {
    received: isMain ? 'OPEN_ESTIMATES' : 'VIEW_OPEN_ESTIMATE',
    complete: isMain ? 'COMPLETE_ESTIMATES' : 'VIEW_COMPLETE_ESTIMATE',
    rejected: isMain ? 'INACTIVE_ESTIMATES' : 'VIEW_INACTIVE_ESTIMATE',
  };

  return obj[pageName];
};

export const getTitle = (pageName: string) => {
  const obj: Record<string, string> = {
    received: 'Open',
    complete: 'Active',
    rejected: 'Inactive',
  };

  return obj[pageName];
};
