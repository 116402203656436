import { createRequiredTextWidthValidation } from "@utils/schemas";
import { InputTypes, ValidFieldNames } from "@utils/globalTypes";
import * as yup from "yup";
import { isHexCodeRegex } from "@utils/regexp";

export interface IAddDataForm {
  title: string;
  slug: string;
  description: string;
  displayInHeader: boolean;
  visibleOnSite: boolean;
  bannerEnabled: boolean;
  banner: {
    title?: string | null;
    textColor?: string | null;
    desktopPhoto?: string | null;
    mobilePhoto?: string | null;
    backgroundColor?: string | null;
    iconPhoto?: string | null;
    iconText?: string | null;
    iconTextColor?: string | null;
  };
}

export const defaultValues: IAddDataForm = {
  title: "",
  description: "",
  slug: "",
  displayInHeader: false,
  visibleOnSite: false,
  bannerEnabled: false,
  banner: {
    title: "",
    textColor: "#000000",
    desktopPhoto: "",
    mobilePhoto: "",
    backgroundColor: "#000000",
    iconPhoto: "",
    iconText: "",
    iconTextColor: "#000000",
  },
};

export const BannerSchema = yup.object().shape({
  title: yup
    .string()
    .max(255, "Title must be at most 255 characters")
    .nullable(),
  textColor: yup
    .string()
    .matches(isHexCodeRegex, "HEX code is invalid")
    .nullable(),
  desktopPhoto: yup
    .string()
    .max(255, "Desktop background must be at most 255 characters")
    .nullable(),
  mobilePhoto: yup
    .string()
    .max(255, "Mobile background must be at most 255 characters")
    .nullable(),
  backgroundColor: yup
    .string()
    .matches(isHexCodeRegex, "HEX code is invalid")
    .nullable(),
  promoIconTextColor: yup
    .string()
    .max(255, "Icon must be at most 255 characters")
    .nullable(),
  promoImage1: yup
    .string()
    .max(255, "Icon must be at most 255 characters")
    .nullable(),
  promoImage1Text: yup
    .string()
    .max(255, "Icon must be at most 255 characters")
    .nullable(),
  promoImage2: yup
    .string()
    .max(255, "Icon must be at most 255 characters")
    .nullable(),
  promoImage2Text: yup
    .string()
    .max(255, "Icon must be at most 255 characters")
    .nullable(),
  promoImage3: yup
    .string()
    .max(255, "Icon must be at most 255 characters")
    .nullable(),
  promoImage3Text: yup
    .string()
    .max(255, "Icon must be at most 255 characters")
    .nullable(),
  promoImage4: yup
    .string()
    .max(255, "Icon must be at most 255 characters")
    .nullable(),
  promoImage4Text: yup
    .string()
    .max(255, "Icon must be at most 255 characters")
    .nullable(),
});

export const AddDataSchema = yup.object().shape({
  title: createRequiredTextWidthValidation("Title", 255),
  slug: yup.string().required(),
  description: createRequiredTextWidthValidation("Description", 500),
  displayInHeader: yup.boolean().default(false),
  visibleOnSite: yup.boolean().default(false),
  bannerEnabled: yup.boolean().default(false),
  banner: BannerSchema,
});

export const addDataInputsRows: ValidFieldNames[] = [
  {
    label: "Title",
    field: "title",
    type: InputTypes.text,
  },
  {
    label: "Description",
    field: "description",
    type: InputTypes.textarea,
    textareaprops: {
      vtResize: true,
      height: "auto",
    },
  },
  {
    label: "Slug",
    field: "slug",
    type: InputTypes.text,
  },
  {
    label: "Display in Header",
    field: "displayInHeader",
    type: InputTypes.checkbox,
  },
  {
    label: "Visible on Site",
    field: "visibleOnSite",
    type: InputTypes.checkbox,
  },
];

export const bannerInputRows: ValidFieldNames[] = [
  {
    label: "Enable Banner",
    field: "bannerEnabled",
    type: InputTypes.checkbox,
  },
  {
    label: "Title text",
    field: "banner.title",
    type: InputTypes.text,
  },
  {
    label: "Text Color(HEX):",
    field: "banner.textColor",
    type: InputTypes.colorPicker,
  },
  {
    label: "Background Image(Desktop) Recommended size 1200x240px",
    field: "banner.desktopPhoto",
    type: InputTypes.image,
  },
  {
    label: "Image(Mobile) Recommended size 980x400px",
    field: "banner.mobilePhoto",
    type: InputTypes.image,
  },
  {
    label: "Background Color(mobile)",
    field: "banner.backgroundColor",
    type: InputTypes.colorPicker,
  },
  // {
  //   label: "Promo Icon(PNG or SVG) Recommended size 128x128px",
  //   field: "banner.iconPhoto",
  //   type: InputTypes.image,
  // },
  // {
  //   label: "Promo icon Text",
  //   field: "banner.iconText",
  //   type: InputTypes.text,
  // },
  // {
  //   label: "Promo icon text color (HEX)",
  //   field: "banner.iconTextColor",
  //   type: InputTypes.colorPicker,
  // },
  {
    label: "Promo icon text color (HEX):",
    field: "banner.promoIconTextColor",
    type: InputTypes.colorPicker,
  },
  {
    label: "Promo 1 (PNG or SVG) Recommended size 128x128px",
    field: "banner.promoImage1,banner.promoImage1Text",
    type: InputTypes.iconImageWithTitle,
    isIcon: true,
  },
  {
    label: "Promo 2 (PNG or SVG) Recommended size 128x128px",
    field: "banner.promoImage2,banner.promoImage2Text",
    type: InputTypes.iconImageWithTitle,
    isIcon: true,
  },
  {
    label: "Promo 3 (PNG or SVG) Recommended size 128x128px",
    field: "banner.promoImage3,banner.promoImage3Text",
    type: InputTypes.iconImageWithTitle,
    isIcon: true,
  },
  {
    label: "Promo 4 (PNG or SVG) Recommended size 128x128px",
    field: "banner.promoImage4,banner.promoImage4Text",
    type: InputTypes.iconImageWithTitle,
    isIcon: true,
  },
];
