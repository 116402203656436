import { InputTypes, ValidFieldNames } from '@utils/globalTypes';

export const inputsRows1: ValidFieldNames[] = [
  {
    label: 'Menu Category',
    field: 'categoryId',
    type: InputTypes.select,
    isRequired: true,
  },
  {
    label: 'Print Type',
    field: 'printType',
    type: InputTypes.select,
    isRequired: true,
  },
  {
    label: 'Slug',
    field: 'slug',
    type: InputTypes.text,
    isRequired: true,
  },
  {
    label: 'Title',
    field: 'title',
    type: InputTypes.text,
    isRequired: true,
  },
  {
    label: 'Description',
    field: 'description',
    type: InputTypes.textarea,
    isRequired: false,
    textareaprops: {
      vtResize: true,
      height: 'auto',
    },
  },
  {
    label: 'Specifications',
    field: 'specifications',
    type: InputTypes.textarea,
    isRequired: false,
    textareaprops: {
      vtResize: true,
      height: 'auto',
    },
  },
  {
    label: 'Disable Types (Make category as product)',
    field: 'makeCategoryAsProduct',
    type: InputTypes.checkbox,
    isRequired: false,
  },
  {
    label: 'EDDM',
    field: 'isEDDM',
    type: InputTypes.checkbox,
  },
  {
    label: 'Use Grand Format SQ. Ft. Template',
    field: 'useGrandFormatSQFtTemplate',
    type: InputTypes.checkbox,
  },
  {
    label: 'Mark as New',
    field: 'isNew',
    type: InputTypes.checkbox,
  },
  // {
  //   label: 'Mark as Sale',
  //   field: 'isSale',
  //   type: InputTypes.checkbox,
  // },
  {
    label: 'Show in Spotlight',
    field: 'showInSpotlight',
    type: InputTypes.checkbox,
  },
  // {
  //   label: 'Product Category Discount off MSRP(%)',
  //   field: 'categoryDiscountOffMSRP',
  //   type: InputTypes.text,
  // },
  // {
  //   label: 'Default Ink in Estimator',
  //   field: 'defaultInkInEstimator',
  //   type: InputTypes.select,
  // },
];
export const inputsRows2: ValidFieldNames[] = [
  {
    label: 'Photo',
    field: 'photo',
    multiple: true,
    type: InputTypes.image,
    isRequired: true,
  },
  // {
  //   label: 'Description',
  //   field: 'description',
  //   type: InputTypes.textarea,
  //   isRequired: true,
  // },
  {
    label: 'Visible On Site',
    field: 'visibleOnSite',
    type: InputTypes.checkbox,
  },
  {
    label: 'Display as Card in Header',
    field: 'displayAsCardInHeader',
    type: InputTypes.checkbox,
  },
];
