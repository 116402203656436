import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { parseISO, format } from "date-fns";
import { TransactionsRevenueData } from "@features/dashboard/types";

import { generateAllHoursTest, insertDatesIntoStatic } from "./helper";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const HourlyChart = ({ data }: {data: TransactionsRevenueData[]}) => {
  const dataWithDateList = data
    .slice() // Create a copy of the array to avoid modifying the original
    .sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime())
    .map((item) => format(parseISO(item.created), "h:mm a"));

  const hours24List = generateAllHoursTest();
  const updatedDates = insertDatesIntoStatic(dataWithDateList, hours24List);
  const listOfTimes = data.map((i) => ({ time: format(parseISO(i.created), "h:mm a"), value: i.amount }));
  const formattedData = listOfTimes.map((item) => ({ x: item.time, y: item.value }));

  const payload = {
    labels: updatedDates,
    datasets: [
      {
        label: "",
        data: formattedData, // Your actual data points
        backgroundColor: "#1D73E3",
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom' as const,
        display: false,
      },
    },
  };

  return <Bar options={options} data={payload} />;
};

export default HourlyChart;
