import { Dispatch, SetStateAction, useContext, memo } from "react";

import { Box } from "@mui/material";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IShipment } from "@features/orders/order/types";
import { useFormContext } from "react-hook-form";

import { DropShipShipToContext } from "../../../utils/context";
import { DropShipShipToEnum } from "../../../utils/types";
import { Sets, ShipmentInfo } from "./components";
import {
  ShipmentContainer,
  StyledTitle,
  TitleWrapper,
  ShipmentAndSetsWrapper,
  TopContainer,
  StyledBox,
  Underlined,
  StyledTypography,
} from "./styled";

interface ShipmentProps {
  jobIndex: number;
  shipmentsList: IShipment[];
  toggledShipments: string[];
  setToggledShipments: Dispatch<SetStateAction<string[]>>;
  isEddm?: boolean;
  customMailing: boolean;
}

const Shipment = ({
  jobIndex,
  shipmentsList,
  toggledShipments,
  setToggledShipments,
  isEddm,
  customMailing,
}: ShipmentProps) => {
  const { handleOpen, setShippingLink } = useContext(DropShipShipToContext);
  const { watch } = useFormContext();
  const doesNotShip = watch(`jobs[${jobIndex}].itemDoesNotShip`);

  return (
    <>
      {shipmentsList.map((shipment, shipmentIndex) => {
        const isOpen = !toggledShipments.includes(shipment.id);
        const shippingLink = `${jobIndex}-${shipmentIndex}`;

        return (
          <Box key={shipment.id}>
            <ShipmentContainer>
              <TitleWrapper
                onClick={() => {
                  setToggledShipments((prev) => {
                    if (prev.includes(shipment.id)) {
                      return [...prev.filter((item) => item !== shipment.id)];
                    }

                    return [...prev, shipment.id];
                  });
                }}
              >
                <StyledTitle>
                  SHIPMENT
                  {shipmentIndex + 1}
                </StyledTitle>
                {isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRight />}
              </TitleWrapper>
              <ShipmentAndSetsWrapper toggle={isOpen ? 0 : 1}>
                <TopContainer>
                  <StyledBox>
                    <ShipmentInfo
                      jobIndex={jobIndex}
                      shipment={shipment}
                      shipmentIndex={shipmentIndex}
                    />
                  </StyledBox>
                  <StyledBox>
                    {shipment.shipTo && !doesNotShip && !customMailing && (
                      <div>
                        <Box
                          sx={{
                            display: "flex",
                          }}
                        >
                          <h3>SHIPPING ADDRESS</h3>
                          <Underlined
                            onClick={() => {
                              handleOpen(DropShipShipToEnum.SHIPPING);
                              setShippingLink(`${shippingLink}-shipTo`);
                            }}
                          >
                            Edit
                          </Underlined>
                        </Box>
                        <div>
                          <StyledTypography>
                            {shipment?.shipTo?.company}
                          </StyledTypography>
                          <StyledTypography>
                            {shipment?.shipTo?.fullName}
                          </StyledTypography>
                          <StyledTypography>
                            {shipment?.shipTo?.address1}
                          </StyledTypography>
                          <StyledTypography>
                            {shipment?.shipTo?.address2}
                          </StyledTypography>
                          <StyledTypography>
                            {shipment?.shipTo?.city}
                            {", "}
                            {shipment?.shipTo?.state}
                            {", "}
                            {shipment?.shipTo?.zipCode}
                            {", "}
                            {shipment?.shipTo?.country}
                          </StyledTypography>
                          <StyledTypography>
                            {shipment?.shipTo?.phone}
                          </StyledTypography>
                        </div>
                      </div>
                    )}
                  </StyledBox>
                  <StyledBox>
                    {shipment?.dropShip && (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            h3: {
                              color: "red",
                            },
                          }}
                        >
                          <h3>DROP SHIP</h3>
                          <Underlined
                            onClick={() => {
                              handleOpen(DropShipShipToEnum.DROP);
                              setShippingLink(`${shippingLink}-dropShip`);
                            }}
                          >
                            Edit
                          </Underlined>
                        </Box>
                        <Box>
                          <StyledTypography color="red">
                            {shipment?.dropShip?.company}
                          </StyledTypography>
                          <StyledTypography color="red">
                            {shipment?.dropShip?.fullName}
                          </StyledTypography>
                          <StyledTypography color="red">
                            {shipment?.dropShip?.phone}
                          </StyledTypography>
                          <StyledTypography color="red">
                            {shipment?.dropShip?.address1}
                          </StyledTypography>
                          <StyledTypography color="red">
                            {shipment?.dropShip?.address2}
                          </StyledTypography>
                          <StyledTypography color="red">
                            {shipment?.dropShip?.city}
                            {", "}
                            {shipment?.dropShip?.state}
                            {", "}
                            {shipment?.dropShip?.zipCode}
                            {", "}
                            {shipment?.dropShip?.country}
                          </StyledTypography>
                        </Box>
                      </>
                    )}
                  </StyledBox>
                </TopContainer>
                <Sets
                  jobIndex={jobIndex}
                  setsList={shipment.sets}
                  shipmentIndex={shipmentIndex}
                  isEddm={isEddm}
                />
              </ShipmentAndSetsWrapper>
            </ShipmentContainer>
          </Box>
        );
      })}
    </>
  );
};

export default memo(Shipment);
