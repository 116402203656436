import React, { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "@features/app/hooks";
import {
  getOpenDevelopersTasks,
  getClosedDevelopersTasks,
  reorderDevelopersTasks,
} from "@features/customers/developerTasks/actions";
import queryString from 'query-string';
import { useLocation } from "react-router-dom";
import { GlobalQueryString } from "@utils/globalTypes";
import { DropResult } from '@hello-pangea/dnd';
import { dragSort } from '@containers/common/Table/components/DndContainer/helpers';
import { setDevelopersTasks } from '@features/customers/developerTasks/slice';
import { selectDevelopersTasks } from "@features/customers/developerTasks/selectors";

import { IFiltersForm } from './components/SearchSection/helpers';

export const useLogic = ({ switcherStatus }: { switcherStatus: number }) => {
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const { data: developersTasks, isLoading, total } = useAppSelector(selectDevelopersTasks);

  const params = queryString.parse(search) as GlobalQueryString;
  const {
    title = '',
    status: statusUrl = '',
    priority: priorityUrl = '',
  } = params as IFiltersForm;

  const reOrderingData = (result: DropResult) => {
    const { sortedData, items } = dragSort(result, developersTasks);

    dispatch(reorderDevelopersTasks(sortedData))
      .unwrap()
      .then(() => {
        dispatch(setDevelopersTasks(items));
      })
      .catch(() => fetchData());
  };

  const fetchData = async () => {
    const query = { title } as IFiltersForm;

    if (statusUrl) {
      query.status = statusUrl;
    }

    if (priorityUrl) {
      query.priority = priorityUrl;
    }

    try {
      if (switcherStatus === 0) {
        await dispatch(getOpenDevelopersTasks(query)).unwrap();
      } else if (switcherStatus === 1) {
        await dispatch(getClosedDevelopersTasks(query)).unwrap();
      }
    } catch (error) {
      console.log(error);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [switcherStatus, title, statusUrl, priorityUrl]);

  const filtersResult = title || statusUrl || priorityUrl;

  return {
    total,
    developersTasks,
    isLoading,
    filtersResult,
    reOrderingData,
  };
};
