import { memo } from "react";

import { Input } from "@containers/Orders/Order/components/common";
import Select from '@containers/common/Select';
import { FormProvider } from "react-hook-form";
import { countryOptions } from "@containers/Customers/Customers/BillingInfo/InputsTable/optionsList";
import { Box, Stack, Typography } from "@mui/material";
import SubmitBtn from "@containers/common/Table/components/SubmitBtn";
import GlobalUpdatesMessage from "@containers/common/GlobalUpdatesMessage";
import Loader from "@containers/common/Loader";
import CloseModalIcon from '@assets/images/close-modal.svg';

import { DropShipContainer, InputsWrapper, StyledTypography } from "./styled";
import { ShipToDropShipModalProps } from "./types";
import { useShipToDropShipLogic } from "./useShipToDropShipLogic";

const ShipToDropShipModal = ({ type, shippingLink }: ShipToDropShipModalProps) => {
  const {
    watch,
    errors,
    register,
    stateOptions,
    initialLoading,
    actionLoading,
    isSuccessMsg,
    methods,
    onSubmit,
    handleSubmit,
  } = useShipToDropShipLogic({ shippingLink, type });

  if (initialLoading) {
    return (
      <Box>
        <Loader sx={{ minHeight: '360px' }} />
      </Box>
    );
  }

  return (
    <DropShipContainer>
      <FormProvider {...methods}>
        <Stack
          onSubmit={handleSubmit(onSubmit)}
          component="form"
          gap="10px"
        >
          <InputsWrapper>
            <Input
              newRedesignView
              placeholder="Enter"
              label="Company"
              {...register(`company`)}
              errorMessage={errors?.company?.message}
            />
            <Input
              newRedesignView
              isRequired
              placeholder="Enter"
              label="Full Name"
              {...register(`fullName`)}
              onlyBorder
              errorMessage={errors?.fullName?.message}

            />
            <Input
              newRedesignView
              isRequired
              placeholder="Enter"
              label="Address 1"
              {...register(`address1`)}
              onlyBorder
              errorMessage={errors?.address1?.message}
            />
            <Input
              newRedesignView
              placeholder="Enter"
              label="Address 2"
              {...register(`address2`)}
            />
            <label>
              Country
              <span>*</span>
            </label>
            <Select
              orderDesignStyle
              label=""
              options={countryOptions}
              name="country"
              value={watch(`country`)}
              onlyBorder
              errorMessage={errors?.country?.message}
            />
            <Input
              onlyBorder
              newRedesignView
              placeholder="Enter"
              label="City"
              {...register(`city`)}
              errorMessage={errors?.city?.message}
            />
            <label>
              State
              <span>*</span>
            </label>
            <Select
              orderDesignStyle
              label=""
              options={stateOptions}
              name="state"
              value={watch(`state`)}
              onlyBorder
              errorMessage={errors?.state?.message}
            />
            <Input
              newRedesignView
              isRequired
              placeholder="Enter"
              label="Zip Code"
              {...register(`zipCode`)}
              onlyBorder
              errorMessage={errors?.zipCode?.message}
            />
            <Input
              newRedesignView
              placeholder="Enter"
              label="Phone"
              {...register(`phone`)}
              onlyBorder
              errorMessage={errors?.phone?.message}
            />
          </InputsWrapper>
          <div>
            {Object.keys(errors).map((key) => {
                return (
                  <Typography
                    sx={{
                      color: '#FB3838',
                      fontSize: '12px',
                      fontWeight: '500',
                      textAlign: 'center',
                    }}
                  >
                    {/* @ts-ignore */}
                    {errors && errors[key]?.message}
                  </Typography>
                );
              })}
          </div>
          <SubmitBtn actionLoading={actionLoading} margin="0px" />
          <Box sx={{ textAlign: 'center' }}>
            <GlobalUpdatesMessage {...isSuccessMsg} />
          </Box>
        </Stack>
      </FormProvider>
    </DropShipContainer>
  );
};

export default memo(ShipToDropShipModal);
