import { memo, useState, SyntheticEvent } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '@features/app/hooks';
import { selectProfileStatus } from '@features/customers/tasks/selectors';
import PAGE_ROUTES from '@routes/routingEnum';

import navData from './helpers';
import { StyledAccordion, StyledAccordionSummary, StyledAccordionDetails } from './styled';
import MenuItem from './MenuItem';
import SubMenuItem from './SubMenu';

const SidebarMenu = () => {
  const { pathname } = useLocation();

  const selectedPath = navData.find(({ path }) => pathname.startsWith(path));
  const initialValue = selectedPath?.path ? [selectedPath?.path] : [];
  const [expanded, setExpanded] = useState<string[]>(initialValue);
  const isSimpleUser = useAppSelector(selectProfileStatus);

  const handleChange =
    (path: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded((prev) => {
        if (prev.includes(path)) {
          return prev.filter((item) => item !== path);
        }

        return [...prev, path];
      });
    };

    let navbarNewList = navData;

    let byUser = {
      id: "dashboard",
      path: PAGE_ROUTES.DASHBOARD,
      title: "Dashboard",
    };

    let lists = navData;

    if (isSimpleUser) {
      byUser = {
        id: "myTasks",
        path: PAGE_ROUTES.MY_TASKS,
        title: "My Tasks",
      };

      lists = navData.map((item) => {
        return {
          ...item,
          children: item?.children?.filter((child) => {
            return child.path !== PAGE_ROUTES.USERS;
          }),
        };
      });
    }

    navbarNewList = [
      byUser,
      ...lists,
    ];

  return (
    <>
      {
        navbarNewList.map(({ path, title, children }) => {
          const isActive = pathname.startsWith(path);

          return (
            <StyledAccordion key={path} expanded={expanded.includes(path)} onChange={handleChange(path)}>
              <StyledAccordionSummary
                isActive={isActive}
                expandIcon={children && <ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id={path}
              >
                <MenuItem
                  title={title}
                  path={path}
                  hasChild={!!children}
                  isActive={isActive}
                />
              </StyledAccordionSummary>
              {children &&
                  children.map((item) => (
                    <StyledAccordionDetails key={item.path}>
                      <SubMenuItem {...item} />
                    </StyledAccordionDetails>
                  ))}
            </StyledAccordion>
          );
        })
      }
    </>
  );
};

export default memo(SidebarMenu);
