import { memo } from "react";

import { priceFormatting } from "@utils/helpers";
import { useFormContext } from "react-hook-form";
import Input from "@containers/common/Input";

import { GrayBackground, Wrapper, Text, EditableSection } from "./styled";

const AttributePrices = ({
  jobIndex,
  isEditable,
}: {
  jobIndex: number;
  isEditable: boolean;
}) => {
  const {
    watch,
    register,
    formState: { errors },
  } = useFormContext();

  const job = watch(`jobs[${jobIndex}]`);
  const keyByIndex = `jobs[${jobIndex}]`;

  const discountAmount = Number(job?.discountAmount);

  return (
    <GrayBackground>
      <Wrapper>
        <Text>Subtotal:</Text>
        <p>{priceFormatting(isEditable ? job.editablePrice : job.price)}</p>
      </Wrapper>
      <Wrapper>
        <Text>Shipping price:</Text>
        {isEditable ? (
          <EditableSection>
            <span>$</span>
            <Input
              orderDesignStyle
              onlyBorder
              {...register(`${keyByIndex}.editableShipping`)}
              label=""
              placeholder="Enter"
              errorMessage={
                Array.isArray(errors?.jobs)
                  ? errors?.jobs?.[jobIndex]?.editableShipping?.message
                  : undefined
              }
            />
          </EditableSection>
        ) : (
          <p>{priceFormatting(watch(`${keyByIndex}.shipping`))}</p>
        )}
      </Wrapper>
      <Wrapper>
        <Text>Tax:</Text>
        <p>{priceFormatting(isEditable ? job.editableTax : job.tax)}</p>
      </Wrapper>

      <Wrapper>
        <Text>Discount:</Text>
        <p>
          {discountAmount > 0
          ? `- ${priceFormatting(job?.discountAmount)}`
          : `${priceFormatting(job?.discountAmount)}`}
        </p>
      </Wrapper>
      <Wrapper>
        <Text>Total:</Text>
        <p>
          {priceFormatting(
            isEditable ? discountAmount
            ? job.editableTotal - discountAmount
            : job.editableTotal
            : discountAmount
            ? job.total - discountAmount
            : job.total,
          )}
        </p>
      </Wrapper>
    </GrayBackground>
  );
};

export default memo(AttributePrices);
