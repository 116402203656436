import { IOrder } from "@features/orders/order/types";
import paidModal from '@assets/images/paid.svg';
import { Typography } from "@mui/material";
import { priceFormatting, SHORT_DATE_FORMAT } from "@utils/helpers";
import moment from "moment";

import { Container, Wrapper } from "./styled";

const PayedInfo = ({ order }: {order: IOrder}) => {
  if (order?.paymentMethod === "payment_terms") {
    return (
      <Container>
        <Wrapper>
          <Typography component="p">Payment Terms</Typography>
        </Wrapper>
      </Container>
    );
  }

  return (
    <Container>
      <img src={paidModal} alt="Paid" />
      {
        order.cardType && order.cardLast4Digits ? (
          <Wrapper>
            <Typography component="p">Credit Card:</Typography>
            <Typography component="p" className="bold">{order.cardType}</Typography>
            <Typography component="p" className="bold">{order.cardLast4Digits}</Typography>
          </Wrapper>
        ) : null
      }
      <Wrapper>
        <Typography component="p">Transaction ID:</Typography>
        <Typography component="p">{order.paymentTransactionId}</Typography>
      </Wrapper>
      <Wrapper>
        <Typography component="p">Billed on:</Typography>
        <Typography component="p">{moment(order.billedDate).format(SHORT_DATE_FORMAT)}</Typography>
        <Typography component="p" className="bold">{priceFormatting(order.transactionAmount)}</Typography>
      </Wrapper>
    </Container>
  );
};

export default PayedInfo;
