import { memo } from "react";

import { InputTypes, ValidFieldNames } from "@utils/globalTypes";
import { useFormContext } from "react-hook-form";
import DatePicker from "@containers/common/DatePicker";
import { Box } from "@mui/material";
import TextareaEditor from "@containers/common/Editor";

import Input from "../../../Input";
import Textarea from "../../../Textarea";
import Checkbox from "../../../Checkbox";
import FileUpload from "../../../FileUploader";
import ColorPickerInput from "../../../ColorPickerInput";
import Select from "../../../Select";
import { ISelectList } from "./types";

interface IReusableFields extends ValidFieldNames {
  selectList?: ISelectList[];
  errorMessage?: string;
  filledData?: Record<string, string>;
  grandDisable?: boolean;
}

function ReusableFields({
  field,
  type,
  label,
  selectList,
  multiple,
  placeholder,
  extensions,
  textareaprops,
  selectprops,
  inputprops,
  errorMessage,
  filledData,
  grandDisable,
}: IReusableFields) {
  const {
    formState: { errors },
    watch,
    setValue,
    register,
  } = useFormContext();

  if (
    type === InputTypes.filled &&
    filledData &&
    Object.keys(filledData || {}).length
  ) {
    return filledData[field];
  }

  if (type === InputTypes.text) {
    return (
      <Input
        type={field === "password" ? "password" : "text"}
        placeholder={placeholder || label}
        {...register(field)}
        errorMessage={(errors?.[field]?.message as string) || errorMessage}
        inputProps={{ autoComplete: "new-password" }}
        {...inputprops}
      />
    );
  }

  if (type === InputTypes.textarea) {
    return (
      <Textarea
        errorMessage={(errors?.[field]?.message as string) || errorMessage}
        placeholder={placeholder || label}
        {...register(field)}
        {...textareaprops}
      />
    );
  }

  if (type === InputTypes.editor) {
    return (
      <TextareaEditor
        value={watch(field)}
        name={field}
        setValue={setValue}
        placeholder={placeholder || label}
        errorMessage={(errors?.[field]?.message as string) || errorMessage}
      />
    );
  }

  if (type === InputTypes.checkbox) {
    return (
      <Checkbox
        name={field as string}
        disabled={grandDisable}
        errorMessage={(errors?.[field]?.message as string) || errorMessage}
      />
    );
  }

  if (type === InputTypes.image) {
    return (
      <FileUpload
        isMultiple={multiple}
        type={InputTypes.image}
        name={field}
        errorMessage={(errors?.[field]?.message as string) || errorMessage}
      />
    );
  }

  if (type === InputTypes.file) {
    return (
      <FileUpload
        type={InputTypes.file}
        name={field}
        errorMessage={(errors?.[field]?.message as string) || errorMessage}
        fileExtensions={extensions}
      />
    );
  }

  if (type === InputTypes.colorPicker) {
    return (
      <ColorPickerInput
        placeholder={label}
        name={field}
        errorMessage={errors?.[field]?.message as string}
      />
    );
  }

  if (type === InputTypes.calendar) {
    return (
      <DatePicker
        width="100px"
        name={field}
        inputProps={{ placeholder: placeholder ?? "Date" }}
        errorMessage={(errors?.[field]?.message as string) || errorMessage}
      />
    );
  }

  if (type === InputTypes.select) {
    const selector = selectList?.find((item) => item.field === field);

    if (selector) {
      const { options } = selector;

      return (
        <Select
          name={field}
          options={options}
          isMultiple={multiple}
          errorMessage={(errors?.[field]?.message as string) || errorMessage}
          grandDisable={grandDisable}
          {...selectprops}
        />
      );
    }
  }

  if (type === InputTypes.iconImageWithTitle) {
    const [first, second] = field.split(',');

    if (!first || !second) {
      return null;
    }

    const isCategoryPage = second.includes('banner.');
    const secondErrorKye = isCategoryPage ? second.split('.')[1] : second;

    return (
      <Box>
        <FileUpload
          isMultiple={false}
          type={InputTypes.image}
          name={first}
          errorMessage={errors?.[first]?.message as string}
        />
        <br />

        {
          isCategoryPage ? (
            <Input
              type="text"
              placeholder="Enter"
              {...register(second)}
              // @ts-ignore
              errorMessage={errors?.banner?.[secondErrorKye]?.message as string}
            />
          ) : (
            <Input
              type="text"
              placeholder="Enter"
              {...register(second)}
              errorMessage={errors?.[secondErrorKye]?.message as string}
            />
          )
        }
      </Box>
    );
  }

  return null;
}

export default memo(ReusableFields);
