import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { TransactionsRevenueData } from "@features/dashboard/types";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

// const daysInMonth = Array.from({ length: 30 }, (_, i) => `Day ${i + 1}`); // Labels from Day 1 to Day 30

const generateDaysOfMonth = (date: string) => {
  const days = [];
  const start = moment(date).startOf('month'); // First day of month
  const end = moment(date).endOf('month'); // Last day of month

  while (start.isSameOrBefore(end)) {
    days.push(start.format('MM/DD/YY'));
    start.add(1, 'day'); // Move to next day
  }

  return days;
};

const currentMonth = moment().format('MMMM'); // e.g., "April"
const daysInMonth = generateDaysOfMonth(currentMonth);

const MonthlyCharts = ({ data }: {data: TransactionsRevenueData[]}) => {
  // const formattedData = data.map((item) => item.amount);
  const formattedData = data.map((item) => ({
    // x: moment(item.created).format('[Day] D'),
    x: moment(item.created).format('MM/DD/YY'),
    y: item.amount,
  }));

  const payload = {
    labels: daysInMonth,
    datasets: [
      {
        label: "",
        data: formattedData,
        backgroundColor: "#1D73E3",
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom' as const,
        display: false, // Hide the legend
      },
    },
  };

  return <Bar options={options} data={payload} />;
};

export default MonthlyCharts;
