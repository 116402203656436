import { useContext } from "react";

import moment from "moment";
import Input from "@containers/common/Input";
import {
  Checkbox,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { ISet, StatusOptions } from "@features/orders/sets/types";
import { selectRow } from "@features/orders/sets/slice";
import { useAppDispatch, useAppSelector } from "@features/app/hooks";
import { selectSets } from "@features/orders/sets/selectors";
import { UpdateShipmentPayload } from "@features/orders/order/types";
import { SHORT_DATE_FORMAT } from "@utils/helpers";
import {
  OrderListUpdateRequestFieldKeys,
  OrderStatusesEnum,
  OrderListStatusesForArrowsAdmin,
} from "@features/orders/order/enums";
import ErrorMessage from "@containers/common/ErrorMessage";
import { PrevState } from "@utils/prevState";
import { requestToArtwork } from "@utils/imageService";
import { updateSetsStatuses } from "@features/orders/sets/actions";
import { getEstimatesCount } from "@features/sidebarCounts/actions";
import PAGE_ROUTES from "@routes/routingEnum";
import ApprovedGreen from "@assets/images/approved-green.svg";
import RejectRed from "@assets/images/reject-red.svg";
import { COLORS } from "@customTypes/global/theme/colors";

import { IVendorOptions, ValidMessageState } from "../useSetsListLogic";
import OrderShipments from "../OrderShipments";
import {
  StyledSelect,
  OrderIdLink,
  StyledOrderTableRow,
  StyledTCell,
  StatusText,
} from "../styled";
import { findIsEditable, getPriority } from "./helper";

// eslint-disable-next-line import/order
import { OrderKindsContext } from "@containers/Orders/Sets";

interface OrderItemInListProps extends ISet {
  statusOptions: StatusOptions[];
  vendorsList: IVendorOptions[];
  validMessageState: ValidMessageState;
  beginningDebounceForEachChange: (
    key: OrderListUpdateRequestFieldKeys,
    value: string
  ) => void;
  beginningDebounceForShipmentChanges: (args: UpdateShipmentPayload) => void;
}

const SetItemInList = ({
  orderId,
  // jobId,
  jobName,
  shipmentId,
  isMailing,
  setId,
  setNumber,
  frontArtWork,
  backArtWork,
  status,
  statusColor,
  date,
  billTo,
  vendorJob,
  vendorId,
  vendorCost,
  // vendorName,
  mailPo,
  hasOpenTasks,
  isEDDMJob,
  orderWithComment,
  isRush,
  isEstimate,
  trackingNumber,
  trackingCarrier,
  statusOptions,
  vendorsList,
  validMessageState,
  quickbooksInvoiceId,
  beginningDebounceForEachChange,
  beginningDebounceForShipmentChanges,
}: OrderItemInListProps) => {
  /**
   *  Vendor Job, Vendor, Mail PO, Tracking number. These fields
   *  Can has the following values (user written value, NULL or isEditable)
   *
   *  If value is NULL field(input, select, etc) hidden.
   *  If value is equal 'isEditable' or user written value field must be visible.
   */

  const { openJobsSelectedRows } = useAppSelector(selectSets);
  const dispatch = useAppDispatch();
  const jobsKind = useContext(OrderKindsContext);

  const artwork = requestToArtwork(frontArtWork || backArtWork);

  const updateSingleStatus = async (e: SelectChangeEvent<unknown>) => {
    try {
      await dispatch(
        updateSetsStatuses({
          status: e.target.value as OrderStatusesEnum,
          ids: [setId],
        }),
      );

      if (
        [OrderStatusesEnum.Canceled, OrderStatusesEnum.Complete].includes(
          e.target.value as OrderStatusesEnum,
        )
      ) {
        await dispatch(getEstimatesCount());
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderJobName = (name: string) => {
    if (status !== OrderStatusesEnum.Complete) {
      return name;
    }

    const IconMarker = quickbooksInvoiceId ? ApprovedGreen : RejectRed;

    return (
      <>
        {name}
        {" "}
        <img
          style={{
            marginTop: "3px",
            marginBottom: "-3px",
            marginLeft: "5px",
            height: "15px",
            width: "15px",
          }}
          src={IconMarker}
          alt=""
        />
      </>
    );
  };

  return (
    <StyledOrderTableRow
      className={getPriority({
        hasOpenTasks,
        isEDDMJob,
        orderWithComment,
        isRush,
        isEstimate,
      })}
    >
      <StyledTCell text="" width="20px">
        <Checkbox
          onClick={() => {
            dispatch(selectRow(setId));
          }}
          checked={openJobsSelectedRows.includes(setId)}
        />
      </StyledTCell>
      <StyledTCell>
        <OrderIdLink
          onClick={() => {
                  PrevState.set({ scroll: window?.scrollY });

                  let st: OrderListStatusesForArrowsAdmin =
                      OrderListStatusesForArrowsAdmin.OPEN;

                  if (status === OrderStatusesEnum.Complete) {
                      st = OrderListStatusesForArrowsAdmin.COMPLETED;
                  }

                  if (status === OrderStatusesEnum.Canceled) {
                      st = OrderListStatusesForArrowsAdmin.CANCELLED;
                  }

                  if (
                      status !== OrderStatusesEnum.Complete &&
                      status !== OrderStatusesEnum.Canceled
                  ) {
                      st = OrderListStatusesForArrowsAdmin.OPEN;
                  }

                  localStorage.setItem("arrowKind", st);
              }}
          to={`/orders/${orderId}`}
        >
          {setNumber}
          {' '}
          {orderWithComment ? (
            <span
              style={{
                  position: "relative",
                  top: "-10px",
                  right: "-5px",
                  display: "inline-block",
                  width: "6px",
                  height: "6px",
                  backgroundColor: COLORS.red.dark,
                  borderRadius: "50%",
                  marginRight: "15px",
              }}
            />
) : ''}

        </OrderIdLink>
      </StyledTCell>
      {!window?.location?.pathname?.includes(PAGE_ROUTES.OPEN_ORDERS) ? (
        <StyledTCell text={renderJobName(jobName)} />
        ) : null}
      <StyledTCell
        text={
                date
                    ? moment(date).format(SHORT_DATE_FORMAT)
                    : moment().format(SHORT_DATE_FORMAT)
            }
      />
      <StyledTCell text={`${billTo}`} />
      <StyledTCell>
        <StyledSelect
          id=""
          label=""
          labelId=""
          defaultValue={status}
          onChange={updateSingleStatus}
        >
          {statusOptions.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              <StatusText
                color={
                  item.optionName === status && statusColor
                    ? statusColor
                    : "unset"
                }
              >
                {item.optionName}
              </StatusText>
            </MenuItem>
          ))}
        </StyledSelect>
      </StyledTCell>
      <StyledTCell>
        {/* {vendorJob !== null && ( */}
        <>
          <Input
            defaultValue={findIsEditable(vendorJob)}
            name="vendorJob"
            placeholder="Enter..."
            onChange={(e) => {
              beginningDebounceForEachChange(
                OrderListUpdateRequestFieldKeys.vendorJob,
                e.target.value,
              );
            }}
          />
          {validMessageState?.where
            === OrderListUpdateRequestFieldKeys.vendorJob &&
            validMessageState?.rowId === setId && (
              <ErrorMessage message={validMessageState.message} />
            )}
        </>
        {/* )} */}
      </StyledTCell>
      <StyledTCell>
        {/* {vendorId !== null && ( */}
        <StyledSelect
          value={findIsEditable(vendorId, "Select")}
          defaultValue="Select"
          sx={{ width: "150px", height: "45px" }}
          onChange={(el) => {
            beginningDebounceForEachChange(
              OrderListUpdateRequestFieldKeys.vendorId,
              el.target.value as string,
            );
          }}
        >
          <MenuItem value="Select">
            <Typography variant="body3">Select</Typography>
          </MenuItem>
          {vendorsList
            .sort((a, b) => a.optionName.localeCompare(b.optionName))
            .map(({ optionName, value }) => (
              <MenuItem key={value} value={value} selected={value === vendorId}>
                <Typography variant="body3">{optionName}</Typography>
              </MenuItem>
            ))}
        </StyledSelect>
        {/* )}np */}
      </StyledTCell>
      <StyledTCell width="100px">
        {/* {vendorCost !== null && ( */}
        <>
          <Input
            defaultValue={findIsEditable(vendorCost)}
            name="vendorCost"
            placeholder="Enter..."
            onChange={(e) => {
              beginningDebounceForEachChange(
                OrderListUpdateRequestFieldKeys.vendorCost,
                e.target.value,
              );
            }}
          />
          {validMessageState?.where
            === OrderListUpdateRequestFieldKeys.vendorCost &&
            validMessageState?.rowId === setId && (
              <ErrorMessage message={validMessageState.message} />
            )}
        </>
        {/* )} */}
      </StyledTCell>
      <StyledTCell>
        {shipmentId && trackingNumber !== null && (
          <OrderShipments
            shipment={{
              id: shipmentId,
              trackingNumber: findIsEditable(trackingNumber),
              trackingCarrier,
            }}
            onChange={(shipmentsUpdatePayload: UpdateShipmentPayload) => {
              beginningDebounceForShipmentChanges(shipmentsUpdatePayload);
            }}
          />
        )}
      </StyledTCell>
      <StyledTCell>
        {isMailing && (
          <Input
            name="test"
            defaultValue={findIsEditable(mailPo)}
            placeholder="Enter..."
            onChange={(e) => {
              beginningDebounceForEachChange(
                OrderListUpdateRequestFieldKeys.mailingPO,
                e.target.value,
              );
            }}
            sx={{
              minWidth: "80px",
            }}
          />
        )}
      </StyledTCell>
    </StyledOrderTableRow>
  );
};

export default SetItemInList;
