/* eslint-disable no-plusplus */
import { format, startOfDay, addHours } from "date-fns";

export const generateAllHoursTest = () => {
  const start = startOfDay(new Date()); // Start at 00:00 today

  return Array.from({ length: 24 }, (_, i) => format(addHours(start, i + 1), "h:mm a"));
};

export function insertDatesIntoStatic(dates: string[], staticDates: string[]) {
  function convertToDate(timeStr: string) {
    return new Date(`2000-01-01 ${timeStr}`);
  }

  dates.forEach((date) => {
    const dateObj = convertToDate(date);
    let inserted = false; // Flag to track if the date is inserted

    for (let i = 0; i < staticDates.length - 1; i++) {
      const startTime = convertToDate(staticDates[i]);
      const endTime = convertToDate(staticDates[i + 1]);

      if (dateObj > startTime && dateObj < endTime) {
        staticDates.splice(i + 1, 0, date);
        inserted = true;
        break; // Stop the loop after inserting once
      }
    }

    // If the time is before all times or after all times, insert accordingly
    if (!inserted && dateObj < convertToDate(staticDates[0])) {
      staticDates.unshift(date);
    } else if (!inserted && dateObj > convertToDate(staticDates[staticDates.length - 1])) {
      staticDates.push(date);
    }
  });

  return staticDates;
}
