import { memo } from "react";

import { useFormContext } from "react-hook-form";
import MultipleFileUploader from "@containers/common/FileUploader";
import Input from "@containers/common/Input";
import AddTextBtn from "@containers/common/Table/components/AddTextBtn";
import DndBtn from "@containers/common/Table/components/TablesActions/DndBtn";
import Stack from "@mui/material/Stack";
import { IProductTypes } from "@features/subcategories/types";
import Textarea from "@containers/common/Textarea";
import { Box } from "@mui/material";

import { StyledInputStack, StyledStack, StyledImagesWrapper } from "./styles";
import { CustomShippingFacility } from "./components";

interface ITypeItem {
  idx: number;
  providedDraggable?: any;
}

const TypeItem = ({ idx, providedDraggable }: ITypeItem) => {
  const {
    watch,
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  const productTypes = watch("productTypes");

  const handleDelete = () => {
    const newProductTypes = productTypes.filter(
      (item: IProductTypes, i: number) => i !== idx,
    );

    setValue("productTypes", newProductTypes);
  };

  return (
    <StyledStack isAdd={!providedDraggable}>
      {providedDraggable && (
        <DndBtn providedDraggable={providedDraggable} isText={false} />
      )}
      <Stack>
        <StyledInputStack>
          <Input
            width="100%"
            {...register(`productTypes[${idx}].name`)}
            placeholder="Product Type"
            errorMessage={(errors as any)?.productTypes?.[idx]?.name?.message}
          />
          <AddTextBtn text="Delete Type" handleAdd={handleDelete} />
        </StyledInputStack>
        <StyledInputStack>
          <Input
            width="100%"
            {...register(`productTypes[${idx}].slug`)}
            placeholder="Slug"
            errorMessage={(errors as any)?.productTypes?.[idx]?.slug?.message}
          />
        </StyledInputStack>
        <StyledImagesWrapper>
          <MultipleFileUploader
            name={`productTypes[${idx}].photos`}
            errorMessage={(errors as any)?.productTypes?.[idx]?.photos?.message}
            isMultiple
          />
        </StyledImagesWrapper>
        <Box sx={{ marginTop: "8px" }}>
          <Textarea
            placeholder="Description"
            {...register(`productTypes[${idx}].description`)}
            errorMessage={
              // @ts-ignore
              errors?.productTypes?.[idx]?.description?.message as string
            }
            vtResize
            height="auto"
          />
        </Box>
        <Box sx={{ marginTop: "8px" }}>
          <Textarea
            placeholder="Specifications"
            {...register(`productTypes[${idx}].specifications`)}
            errorMessage={
              // @ts-ignore
              errors?.productTypes?.[idx]?.specifications?.message as string
            }
            vtResize
            height="auto"
          />
        </Box>
        <CustomShippingFacility idx={idx} />
      </Stack>
    </StyledStack>
  );
};

export default memo(TypeItem);
