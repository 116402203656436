import { memo, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import {
  selectSuperAdminTasks,
  selectSuperAdminTasksLoaderStatus,
  selectTasksSeeMoreButton,
  selectTasksSeeMoreLoading,
} from '@features/dashboard/selectors';
import StyledTable from '@containers/common/Table';
import EmptyState from '@containers/common/EmptyState';
import PageTitle from '@containers/common/PageTitle';
import { getSuperAdminTasksInitialThunk, getSuperAdminTasksSeeMoreThunk } from '@features/dashboard/actions';
import { Filters } from "@utils/helpers";
import { LoaderWrapper, StyledButton, TasksContainer } from '@containers/dashboard/styled';
import { LIMIT_TASKS } from '@containers/dashboard/constants';
import { CircularProgress } from '@mui/material';

import Switcher from './Switcher';
import { headCells } from './tableData';
import TableRow from './TableRow';

export interface IParamsState { status: 'open' | 'closed'; offset: number; limit: number }

const SuperAdminTasks = () => {
  const dispatch = useAppDispatch();
  const superAdminTasks = useAppSelector(selectSuperAdminTasks);
  const superAdminTasksInitialLoader = useAppSelector(selectSuperAdminTasksLoaderStatus);
  const superAdminTasksSeeMoreLoader = useAppSelector(selectTasksSeeMoreLoading);
  const isSelectTasksSeeMoreButton = useAppSelector(selectTasksSeeMoreButton);

  const [tasksParams, setTasksParams] = useState<IParamsState>({ status: 'open', offset: 0, limit: LIMIT_TASKS });

  const fetchData = () => {
    dispatch(getSuperAdminTasksInitialThunk(tasksParams as unknown as Filters));
  };

  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasksParams.status]);

  const seeMore = () => {
    if (superAdminTasks?.length) {
      dispatch(getSuperAdminTasksSeeMoreThunk(tasksParams as unknown as Filters));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    seeMore();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasksParams.offset, tasksParams.limit]);

  const columns = [...headCells];

  if (tasksParams.status === 'open') {
    // it is 'Completed Date' column for opens task
    columns.pop();
  }

  const loadMoreButton = () => {
    if (superAdminTasksSeeMoreLoader) {
      return (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      );
    }

    if (
      !isSelectTasksSeeMoreButton &&
      !superAdminTasksInitialLoader &&
      superAdminTasks.length &&
      tasksParams?.limit > 0
    ) {
      return (
        <StyledButton
          onClick={() => {
            setTasksParams((prev) => ({ ...prev, offset: prev.offset + LIMIT_TASKS }));
          }}
        >
          See more
        </StyledButton>
      );
    }
  };

  const renderContent = () => {
    if (superAdminTasksInitialLoader) {
      return (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      );
    }

    if (superAdminTasks?.length) {
      return (
        <StyledTable headCells={columns}>
          {superAdminTasks.map((item) => {
              return (
                <TableRow key={item.id} switcherStatus={tasksParams.status as string} {...item} />
              );
          })}
        </StyledTable>
      );
    }

    return (
      <EmptyState
        className="empty"
        text={!superAdminTasks?.length ? `You don’t have ${tasksParams.status} tasks` : ""}
      />
    );
  };

  return (
    <TasksContainer>
      <PageTitle title="My Tasks" isShowBtn={false} />
      <div>
        <Switcher
          tasksParams={tasksParams}
          setTasksParams={setTasksParams}
        >

          {renderContent()}
        </Switcher>
        {loadMoreButton()}
      </div>
    </TasksContainer>
  );
};

export default memo(SuperAdminTasks);
