import { memo } from 'react';

import { useAppSelector } from '@features/app/hooks';
import { selectProfileStatus } from '@features/customers/tasks/selectors';
import { Navigate } from 'react-router-dom';
import PAGE_ROUTES from '@routes/routingEnum';

import InputsTable from '../InputsTable';

const AddComponent = () => {
  const userStatus = useAppSelector(selectProfileStatus);

  if (userStatus) {
    return <Navigate to={PAGE_ROUTES.MY_TASKS} />;
  }

  return (
    <InputsTable />
  );
};

export default memo(AddComponent);
