import { memo } from 'react';

import StyledSearchSection from '@containers/common/StyledSearchContainer';
import Input from '@containers/common/Input';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { StyledDatePicker, StyledSearchRows } from '@containers/common/StyledSearchContainer/styled';
import Stack from '@mui/material/Stack';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import PAGE_ROUTES from '@routes/routingEnum';
import { constructQueryString } from '@utils/helpers';
import SearchBtn from '@containers/common/SearchSection/SearchBtn';
import { paginationLimitForCustomers } from '@utils/constants';
import DatePicker from '@containers/common/DatePicker';
import { StyledStackWrapper } from '@containers/common/SearchStyledWrapper/styles';

import { FiltersSchema, IFiltersForm } from './helpers';

const SearchSection = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = queryString.parse(search);

  const methods = useForm<IFiltersForm>({
    resolver: yupResolver(FiltersSchema as any),
    defaultValues: params,
  });

  const { handleSubmit, register, formState: { errors } } = methods;

  const onSubmit = (data: IFiltersForm) => {
    const queryParams = constructQueryString({
      ...data,
      offset: 0,
      limit: paginationLimitForCustomers,
      startDate: data.startDate ? new Date(data.startDate).toISOString() : '',
      endDate: data.endDate ? new Date(data.endDate).toISOString() : '',
    });

    navigate(`${PAGE_ROUTES.CUSTOMERS_CUSTOMERS}?${queryParams}`);
  };

  return (
    <StyledSearchSection>
      <FormProvider {...methods}>
        <StyledStackWrapper
          onSubmit={handleSubmit(onSubmit)}
          component="form"
          gap="24px"
        >
          <StyledSearchRows>
            <Input
              {...register('searchTerm')}
              label=""
              placeholder="Name, Email, Phone Number"
              errorMessage={errors?.searchTerm?.message}
            />
            <StyledDatePicker>
              <DatePicker
                name="startDate"
                dateLabel=""
                inputProps={{ placeholder: 'Date From' }}
                errorMessage={errors?.startDate?.message as string}
              />
            </StyledDatePicker>
            <StyledDatePicker>
              <DatePicker
                name="endDate"
                dateLabel=""
                inputProps={{ placeholder: 'Date To' }}
                errorMessage={errors?.endDate?.message as string}
              />
            </StyledDatePicker>
            <SearchBtn path={PAGE_ROUTES.CUSTOMERS_CUSTOMERS} />
          </StyledSearchRows>
        </StyledStackWrapper>
      </FormProvider>
    </StyledSearchSection>
  );
};

export default memo(SearchSection);
