import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

export const Info = styled(Typography)<{component: string}>(() => ({
  color: "#06152B",
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '130%', /* 18.2px */

  span: {
    fontWeight: 500,
    marginRight: '8px',
  },
}));

export const BillingInfoWrapper = styled(Box)(() => ({
  button: {
    marginTop: '20px',
  },
}));
