import { memo } from 'react';

import StyledSearchSection from '@containers/common/StyledSearchContainer';
import Input from '@containers/common/Input';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { StyledDatePicker, StyledSearchRows } from '@containers/common/StyledSearchContainer/styled';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import PAGE_ROUTES from '@routes/routingEnum';
import { constructQueryString, getOptionsArray } from '@utils/helpers';
import SearchBtn from '@containers/common/SearchSection/SearchBtn';
import DatePicker from '@containers/common/DatePicker';
import Select from '@containers/common/Select';
import { useAppSelector } from '@features/app/hooks';
import { selectUsers } from '@features/users/selectors';
import { StyledStackWrapper } from '@containers/common/SearchStyledWrapper/styles';

import { FiltersSchema, IFiltersForm, statusOptions } from './helpers';

const SearchSection = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = queryString.parse(search);
  const { data: users } = useAppSelector(selectUsers);

  const usersList = getOptionsArray(users, 'firstName', 'lastName');

  const methods = useForm<IFiltersForm>({
    resolver: yupResolver(FiltersSchema as any),
    defaultValues: params,
  });

  const { reset, handleSubmit, register, formState: { errors } } = methods;

  const onSubmit = (data: IFiltersForm) => {
    const queryParams = constructQueryString({
      ...data,
      dateCreated: data?.dateCreated ? new Date(data?.dateCreated).toISOString() : '',
      dateCompleted: data?.dateCompleted ? new Date(data?.dateCompleted).toISOString() : '',
    });

    navigate(`${PAGE_ROUTES.TASKS}?${queryParams}`);
  };

  return (
    <StyledSearchSection>
      <FormProvider {...methods}>
        <StyledStackWrapper
          onSubmit={handleSubmit(onSubmit)}
          component="form"
          gap="24px"
        >
          <StyledSearchRows>
            <Input
              {...register('searchTerm')}
              label=""
              placeholder="Search"
              errorMessage={errors?.searchTerm?.message}
            />
            <Select
              label=""
              name="userId"
              placeholderText="Search by user"
              options={usersList}
            />
            <StyledDatePicker>
              <DatePicker
                name="dateCreated"
                dateLabel=""
                inputProps={{ placeholder: 'Date From:', width: '100px' }}
                errorMessage={errors?.dateCreated?.message as string}
              />
            </StyledDatePicker>
            <StyledDatePicker>
              <DatePicker
                name="dateCompleted"
                dateLabel=""
                inputProps={{ placeholder: 'Date To', width: '153px' }}
                errorMessage={errors?.dateCompleted?.message as string}
              />
            </StyledDatePicker>
            {/* <Select
              label=""
              name="status"
              placeholderText="Search by status"
              options={statusOptions}
            /> */}
            <SearchBtn
              path={PAGE_ROUTES.TASKS}
              onReset={() => {
                reset({});
              }}
            />
          </StyledSearchRows>
        </StyledStackWrapper>
      </FormProvider>
    </StyledSearchSection>
  );
};

export default memo(SearchSection);
