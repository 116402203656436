import { InputTypes } from "@utils/globalTypes";

export const topBannerVia = [
  {
    label: "Visible only to registered users",
    field: "visibleOnlyRegisteredUsers",
    type: InputTypes.checkbox,
  },
  {
    label: "Promo icon text color (HEX):",
    field: "promoIconTextColor",
    type: InputTypes.colorPicker,
  },
  {
    label: "Promo 1 (PNG or SVG) Recommended size 128x128px",
    field: "promoImage1,promoImage1Text",
    type: InputTypes.iconImageWithTitle,
    isIcon: true,
  },
  {
    label: "Promo 2 (PNG or SVG) Recommended size 128x128px",
    field: "promoImage2,promoImage2Text",
    type: InputTypes.iconImageWithTitle,
    isIcon: true,
  },
  {
    label: "Promo 3 (PNG or SVG) Recommended size 128x128px",
    field: "promoImage3,promoImage3Text",
    type: InputTypes.iconImageWithTitle,
    isIcon: true,
  },
  {
    label: "Promo 4 (PNG or SVG) Recommended size 128x128px",
    field: "promoImage4,promoImage4Text",
    type: InputTypes.iconImageWithTitle,
    isIcon: true,
  },
];
