import { SyntheticEvent, createElement, memo, useState } from "react";

import TitlesWithBackButton from "@containers/common/TitlesWithBackButton";
import { StyledStack } from "@containers/common/StyledAddEditTables/styled";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import queryString from "query-string";
import { ISubcategoriesInfo } from "@features/subcategories/types";
import {
  StyledDivider,
  StyledTab,
} from "@containers/Products/Products/components/Tabs/styles";
import { StyledTabs } from "@containers/Customers/Customers/EditCustomers/styles";
import { getAllAttributeCategoriesProducts } from "@features/attributeCategories/actions";
import { getAllAttributesBySubcategoryId } from "@features/subcategories/actions";
import useMount from "@customHooks/useMount";
import { useAppDispatch } from "@features/app/hooks";

import { tabsOptions } from "./helpers";

interface IInputsTable {
  editData?: ISubcategoriesInfo;
  onSuccess?: () => void;
}

const InputsTable = ({ editData, onSuccess }: IInputsTable) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { tab } = queryString.parse(search);
  const { id = "" } = useParams();

  const [value, setValue] = useState(tab && +tab ? +tab : 0);

  const handleChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: number,
  ) => {
    setValue(newValue);
    navigate(`${pathname}?tab=${newValue}`);
  };

  const tabs = tabsOptions(onSuccess);

  useMount(() => {
    dispatch(getAllAttributeCategoriesProducts())
      .unwrap()
      .then((data) => {
        if (data.length) {
          dispatch(getAllAttributesBySubcategoryId(id));
        }
      });
  });

  return (
    <TitlesWithBackButton
      title={editData ? "Edit Product Category" : "Add Product Category"}
      path="PRODUCT_CATEGORIES"
    >
      {!!editData && (
        <StyledStack>
          <StyledDivider>
            <StyledTabs value={value} onChange={handleChange}>
              {tabs.map(({ label }) => (
                <StyledTab key={label} label={label} disableRipple />
              ))}
            </StyledTabs>
          </StyledDivider>
        </StyledStack>
      )}
      {createElement(tabs[value].component, {
        editData,
        onSuccess,
        subTitle: editData?.title,
      })}
    </TitlesWithBackButton>
  );
};

export default memo(InputsTable);
