import { memo } from 'react';

import LinkedString from '@containers/Orders/Order/components/common/LinkedString';
import { DATE_FORMAT, getCDNImagePath } from '@utils/helpers';
import { Box, Typography } from '@mui/material';
import { handleWithoutFormRequest } from '@containers/common/FileUploader';
import { useAppDispatch } from '@features/app/hooks';
import { useFormContext } from 'react-hook-form';
import deleteSetNestedItemsCross from '@assets/images/delete-set-nested-items-cross.svg';
import moment from 'moment';

import {
  UploadedArtworkProofWrapper,
  LinkedTextWrapper,
  TitleText,
} from './styled';
import Statuses from '../Statuses';

const EachProof = ({
  text,
  proof,
  proofName,
  proofSize,
  proofDate,
  proofStatus,
  defaultKey,
  deleteKey,
}: any) => {
  const { watch, setValue } = useFormContext();
  const dispatch = useAppDispatch();

  const frontProof = watch(`${defaultKey}.frontProof`);
  const backProof = watch(`${defaultKey}.backProof`);

  const handleDeleteRequest = () => {
    const madeKey = `${defaultKey}.${deleteKey}`;

    setValue(madeKey, null);
    handleWithoutFormRequest({
      watch,
      dispatch,
      fieldPath: madeKey,
      updateLogicType: 'sets',
    });

    if (
      deleteKey === 'frontProof' && !backProof ||
      deleteKey === 'backProof' && !frontProof
    ) {
      setTimeout(() => {
        window?.location?.reload();
      }, 500);
    }
  };

  return (
    <UploadedArtworkProofWrapper>
      <LinkedTextWrapper>
        <div>
          <Box
            sx={{
                display: 'flex',
              }}
          >
            <Typography>{text}</Typography>
            {
                proof ? (
                  <LinkedString
                    width="210px"
                    target="_blank"
                    href={getCDNImagePath(proof)}
                    text={proofName}
                  />
                )
                : `${' '} - `
              }
          </Box>
          <Box
            sx={{
            gap: '10px',
             display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            {proof && (<Statuses status={proofStatus} />)}
            <TitleText>
              {proof && proofDate &&
                moment(proofDate).format(
                `${DATE_FORMAT}, hh:mm A`,
              )}
            </TitleText>
          </Box>
        </div>
        {
            proof && (
            <img
              src={deleteSetNestedItemsCross}
              alt=""
              onClick={() => {
                  handleDeleteRequest();
                }}
            />
            )
          }
      </LinkedTextWrapper>
    </UploadedArtworkProofWrapper>
  );
};

export default memo(EachProof);
