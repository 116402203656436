import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import Button from '@containers/common/Button';

export const TasksContainer = styled(Box)(() => ({
  marginBottom: '24px',

  ".empty": {
    height: '150px',
  },
}));

export const LoaderWrapper = styled(Box)(() => ({
  height: '150px',
  overflow: 'hidden',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
}));

export const StyledButton = styled(Button)(() => ({
  width: 'max-content',
  marginTop: '24px',
}));
