import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import Textarea from '@containers/common/Textarea';

export const Section = styled(Box)(() => ({
  maxHeight: 'calc(95vh - 100px)',
  overflow: 'auto',
}));

export const MessagesList = styled(Box)(() => ({
  marginTop: '10px',
  maxHeight: '440px',
  height: '100vh',
  width: '100%',
  'overflow-y': 'auto',
  scrollbarColor: '#777 #D9D9D9',
  scrollbarWidth: 'thin',
  paddingRight: '8px',
}));

export const LeftMessage = styled(Box)(() => ({
  width: '100%',
  marginBottom: '16px',
  display: 'flex',
  justifyContent: 'start',
  flexDirection: 'column',
  wordBreak: 'break-word',

  p: {
    padding: '10px',
    background: '#FAFAFA',
    maxWidth: '90%',
    width: 'max-content',

    span: {
      display: 'block',
    },
  },
}));

export const RightMessage = styled(Box)(() => ({
  width: '100%',
  marginBottom: '16px',
  display: 'flex',
  justifyContent: 'end',
  wordBreak: 'break-word',

  p: {
    textAlign: 'right',
    padding: '10px',
    background: '#E8F2FF',
    maxWidth: '90%',
    width: 'max-content',

    span: {
      display: 'block',
      textAlign: 'right',
    },
  },
}));

export const LoaderWrapper = styled(Typography)(() => ({
  marginTop: '10px',
  maxHeight: '440px',
  height: '440px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const StyledTextarea = styled(Textarea)(() => ({
  marginTop: '16px',
  height: '65px !important',
  maxHeight: '65px !important',
  minHeight: '48px !important',
}));

export const SendEmailWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  gap: '5px',
  marginTop: '12px',
  marginBottom: '24px',
}));

export const MissingMessage = styled(Box)(() => ({
  background: '#FAFAFA',
  textAlign: 'center',
  color: '#788B9A',
  fontSize: '12px',
  padding: '10px',
}));

export const SetsList = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  borderBottom: '1px solid #D9D9D9',
  overflowX: 'auto',
  scrollbarWidth: 'thin',

}));

export const Item = styled(Box)<{active: number}>(({ active }) => ({
  cursor: 'pointer',
  padding: '8px 16px',
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',

  color: active ? '#1D73E3' : '#2C2C2C',
  border: active ? `1px solid #1D73E3` : 'none',
  background: active ? '#E8F2FF' : 'none',
  minWidth: 'max-content',
  display: 'flex',
  gap: '5px',
  alignItems: 'center',
  justifyContent: 'center',

  '.unanswered': {
    width: '5px',
    height: '5px',
    background: '#ff0000',
    borderRadius: '20px',
  },
}));

export const Unanswered = styled(Box)(() => ({
  width: '5px',
  height: '5px',
  background: '#ff0000',
  marginLeft: '5px',
  borderRadius: '20px',
}));

export const StyledNewLine = styled('div')`
  position: relative;
  width: 100%;
  height: 20px;

  > span {
    position: absolute;
    top: 10px;
    width: 100%;
    display: block;
    height: 1px;
    background: #1d73e3;
  }

  > p {
    position: absolute;
    text-align: center;
    width: 100%;
    color: #1d73e3;

    > span {
      background: white;
      padding: 0px 10px;
    }
  }
`;
