import { Dispatch, SetStateAction } from "react";

import Modal from "@containers/Orders/Order/components/common/Modal";
import { IOrder } from "@features/orders/order/types";
import Button from "@containers/common/Button";

import { Info, BillingInfoWrapper } from "./styled";

interface IModalsProps {
  order: IOrder;
  isCommentModalOpen: boolean;
  isBillingAddressOpen: boolean;
  setIsBillingAddressOpen: Dispatch<SetStateAction<boolean>>;
  setIsCommentModalOpen: Dispatch<SetStateAction<boolean>>;
}

const Modals = ({
  order,
  isCommentModalOpen,
  isBillingAddressOpen,
  setIsBillingAddressOpen,
  setIsCommentModalOpen,
}: IModalsProps) => {
  return (
    <div>
      <Modal
        sx={{ padding: "24px" }}
        title="Billing Address"
        open={isBillingAddressOpen}
        handleClose={() => {
          setIsBillingAddressOpen(false);
        }}
      >
        <BillingInfoWrapper>
          <Info component="p">
            <span>Company:</span>
            {order.billingInfo.company}
          </Info>
          <Info component="p">
            <span>Name:</span>
            {order.billingInfo.firstName}
            {' '}
            {order.billingInfo.lastName}
          </Info>
          <Info component="p">
            <span>Phone number:</span>
            {order.billingInfo.phone}
          </Info>
          <Info component="p">
            <span>Address:</span>
            {order.billingInfo.address1}
          </Info>
          <Info component="p">
            <span>City/State/Zip:</span>
            {order.billingInfo.city}
            {' '}
            {order.billingInfo.state}
            {' '}
            {order.billingInfo.zipCode}
          </Info>

          <Button
            onClick={() => {
              setIsBillingAddressOpen(false);
            }}
          >
            Close
          </Button>
        </BillingInfoWrapper>
      </Modal>
      <Modal
        sx={{ padding: "24px" }}
        title="ORDER COMMENT"
        open={isCommentModalOpen}
        handleClose={() => {
          setIsCommentModalOpen(false);
        }}
      >
        <div>
          <p>{order.comment}</p>
        </div>
      </Modal>
    </div>
  );
};

export default Modals;
