import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

export const UploadedArtworkProofWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: '24px',
  flexDirection: 'column',

  button: {
    width: '116px',
    marginBottom: '26px',
  },
}));

export const LinkedTextWrapper = styled(Box)(() => ({
  padding: '5px 10px',
  display: 'grid',
  gap: '5px 10px',
  gridTemplateColumns: '1fr 15px',
  height: '100%',
  alignItems: 'center',
  border: '1px solid #F1F4FA',
}));

export const TitleText = styled(Typography)(() => ({
  color: '#788B9A',
  fontSize: '12px',
  fontWeight: 400,
}));
