import { ReactNode, memo } from 'react';

import postcardsLogo from '@assets/images/postcards-logo.png';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@features/app/hooks';
import { selectAuth } from '@features/auth/selectors';
import PAGE_ROUTES from '@routes/routingEnum';
import useMount from '@customHooks/useMount';
import { selectProfileStatus } from '@features/customers/tasks/selectors';

import { StyledBox, StyledLogoBox, StyledContainer, StyledSection } from './styled';

interface AuthComponentProps {
  children: ReactNode;
}

const AuthComponent = ({ children }: AuthComponentProps) => {
  const navigate = useNavigate();
  const { isAuth } = useAppSelector(selectAuth);
  const userStatus = useAppSelector(selectProfileStatus);

  useMount(() => {
    if (isAuth) {
      navigate(userStatus ? PAGE_ROUTES.MY_TASKS : PAGE_ROUTES.PRODUCTS_PRODUCTS);
    }
  });

  if (isAuth) {
    return;
  }

  return (
    <StyledSection>
      <StyledContainer>
        <StyledLogoBox>
          <img src={postcardsLogo} alt="postcards Logo" />
        </StyledLogoBox>
        <StyledBox>
          {children}
        </StyledBox>
      </StyledContainer>
    </StyledSection>
  );
};

export default memo(AuthComponent);
