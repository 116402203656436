import { memo, useEffect, useState } from "react";

import StyledTable from "@containers/common/Table";
import { Box, TableRow, Typography } from "@mui/material";
import { useAppDispatch } from "@features/app/hooks";
import { getSetsForCustomerThunk } from "@features/orders/sets/actions";
import { ISetForCustomer } from "@features/orders/sets/types";
import TCell from "@containers/common/Table/components/TCell";
import { StyledTableRow } from "@containers/common/Table/styled";
import Loader from "@containers/common/Loader";
import moment from "moment";
import { SHORT_DATE_FORMAT } from "@utils/helpers";

import { OrderIdLink, OrderSection } from "./styled";
import { headCells } from "./tableData";

interface IOrderTableProps {
  customerId: string;
}

const OrderTable = ({ customerId }: IOrderTableProps) => {
  const dispatch = useAppDispatch();
  const [sets, setSets] = useState<ISetForCustomer[]>([]);
  const [loading, setLoading] = useState(true);

  const handleSetsList = async () => {
    dispatch(getSetsForCustomerThunk({ customerId }))
      .unwrap()
      .then((response) => {
        setSets(response.sets);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleSetsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OrderSection>
      {loading ? (
        <Loader />
      ) : (
        <Box>
          <StyledTable headCells={headCells}>
            {sets?.map(
              ({
                orderId,
                setNumber,
                date,
                billTo,
                status,
                vendorJob,
                trackingNumber,
              }) => (
                <StyledTableRow key={setNumber}>
                  <TCell>
                    <OrderIdLink to={`/orders/${orderId}`}>
                      {setNumber}
                    </OrderIdLink>
                  </TCell>
                  <TCell>
                    {date
                      ? moment(date).format(SHORT_DATE_FORMAT)
                      : moment().format(SHORT_DATE_FORMAT)}
                  </TCell>
                  <TCell text={billTo} />
                  <TCell text={status} />
                  <TCell text={vendorJob} />
                  <TCell text={trackingNumber} />
                </StyledTableRow>
              ),
            )}
          </StyledTable>
        </Box>
      )}
    </OrderSection>
  );
};

export default memo(OrderTable);
