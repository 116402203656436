import * as yup from 'yup';
import { createRequiredTextWidthValidation, numberValidation, positiveNumberSchema } from '@utils/schemas';

export const InvoiceCreateSchema = yup.object().shape({
  emailInvoiceToCustomer: yup.boolean().required(),
  tax: numberValidation
    .max(999999999, 'The maximum number of digits is 9'),
  itemPrice: positiveNumberSchema
    .max(999999999, 'The maximum number of digits is 9')
    .required("Price is required"),
  itemDescription: createRequiredTextWidthValidation('Item Description', 255),
});

export const InvoiceUpdateSchema = yup.object().shape({
  emailInvoiceToCustomer: yup.boolean().required(),
  tax: numberValidation
    .max(999999999, 'The maximum number of digits is 9')
    .required("Tax is required"),
  itemPrice: positiveNumberSchema
    .max(999999999, 'The maximum number of digits is 9')
    .required("Price is required"),
  invoiceStatus: createRequiredTextWidthValidation('Invoice Status', 255),
  itemDescription: createRequiredTextWidthValidation('Item Description', 255),
});
