import { Job } from '@features/orders/order/types';

import {
  JobTitleWrapper,
  JobTitle,
  ReorderTitle,
} from './styled';

interface TopSectionProps {
  job: Job;
}

const TopSection = ({ job }: TopSectionProps) => {
  return (
    <JobTitleWrapper>
      <JobTitle>
        {job?.jobNumber}
      </JobTitle>
      {job.reorderSetNumber && (
        <ReorderTitle>
          Reorder of set #
          {job.reorderSetNumber}
        </ReorderTitle>
      )}
    </JobTitleWrapper>
  );
};

export default TopSection;
