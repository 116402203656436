import { memo } from 'react';

import { StyledTableRow } from '@containers/common/Table/styled';
import TableCell from '@mui/material/TableCell';
import EditCell from '@containers/common/Table/components/EditCell';
import { formattedDate } from '@utils/helpers';
import { ITask } from '@features/customers/tasks/types';
import TCell from '@containers/common/Table/components/TCell';

import TaskStatus from './TaskStatus';

interface ITableRow extends ITask {
  switcherStatus: number;
}

const TableRow = ({
  id: taskId,
  name,
  status,
  followUp,
  completedDate,
  created,
  customer,
  creator,
  orderId,
  orderNumber,
  switcherStatus,
}: ITableRow) => (
  <StyledTableRow>
    <EditCell title={name} path="EDIT_MY_TASKS" params={{ taskId }} />
    <TCell text={formattedDate(followUp)} />
    <EditCell
      underline={false}
      queryString="?tab=0"
      path="ORDER"
      params={{ orderId }}
      title={orderNumber ? `${orderNumber}` : ''}
    />
    <TCell text="" />
    <TCell text={customer && `${customer?.firstName} ${customer?.lastName}`} />
    <TableCell>
      <TaskStatus status={status} />
    </TableCell>
    <TCell text={`${creator?.firstName} ${creator?.lastName}`} />
    <TCell text={created && formattedDate(created)} />
    {
      switcherStatus ? (
        <TCell text={completedDate && formattedDate(completedDate)} />
      ) : null
    }
  </StyledTableRow>
);

export default memo(TableRow);
