import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/material';

export const StyledInputStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isAdd',
})<{isAdd?:boolean}>(({ theme, isAdd = false }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  justifyContent: 'space-between',
  gap: '15px',
  width: '100%',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.grey[50]}`,
  marginBottom: isAdd ? 0 : '20px',
}));

export const StyledStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isAdd',
})<{isAdd?:boolean}>(({ theme, isAdd = false }) => ({
  display: 'grid',
  gridTemplateColumns: isAdd ? '1fr auto' : '15px 1fr',
  borderBottom: `1px solid ${theme.palette.grey[500]}`,
  paddingBottom: '16px',
  padding: '20px 16px',
  gap: '12px',
}));

export const StyledImagesWrapper = styled(Box)(() => ({
  marginTop: '8px',

  '.MuiBox-root>.MuiStack-root': {
    justifyContent: 'space-between',
  },

  label: {
    width: '185px !important',

    div: {
      width: '100% !important',
    },

    '&.MuiBox-root:last-child': {
      flex: '1',
      width: '100% !important',

      '> div': {
        width: '100% !important',
      },
    },
  },
}));
