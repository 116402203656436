import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { TransactionsRevenueData } from "@features/dashboard/types";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

// const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
const generateDaysOfCurrentWeek = () => {
  const days = [];
  const start = moment().startOf('isoWeek'); // Monday
  const end = moment().endOf('isoWeek'); // Sunday

  while (start.isSameOrBefore(end)) {
    days.push(start.format('MM/DD/YY'));
    start.add(1, 'day'); // Move to next day
  }

  return days;
};

const daysOfWeek = generateDaysOfCurrentWeek();

const WeeklyCharts = ({ data }: {data: TransactionsRevenueData[]}) => {
  const formattedData = data.map((item) => item.amount);

  const payload = {
    labels: daysOfWeek,
    datasets: [
      {
        label: "",
        data: formattedData, // Your actual data points
        backgroundColor: "#1D73E3",
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom' as const,
        display: false, // Hide the legend
      },
    },
  };

  return <Bar options={options} data={payload} />;
};

export default WeeklyCharts;
