import { BannerCategoryEnum } from "@features/banners/types";
import { isHexCodeRegex } from "@utils/regexp";
import {
  createRequiredTextWidthValidation,
  validateOptionalURL,
} from "@utils/schemas";
import { InputTypes, ValidFieldNames } from "@utils/globalTypes";
import * as yup from "yup";

export interface IAddBannerForm {
  id?: string;
  title: string;
  description: string;
  desktopPhoto: string;
  mobilePhoto: string;
  backgroundColor: string;
  firstButtonName?: string;
  firstButtonLink: string;
  secondButtonName?: string;
  secondButtonLink: string;
  category?: BannerCategoryEnum | string;
  displayOnSite?: boolean;
}

export const defaultValues = {
  title: "",
  description: "",
  desktopPhoto: "",
  mobilePhoto: "",
  backgroundColor: "",
  firstButtonName: "",
  firstButtonLink: "",
  secondButtonName: "",
  secondButtonLink: "",
  category: "",
  displayOnSite: false,
};

export const AddBannerSchema = yup.object().shape({
  desktopPhoto: createRequiredTextWidthValidation("Desktop photo", 255),
  mobilePhoto: createRequiredTextWidthValidation("Moblie photo", 255),
  title: createRequiredTextWidthValidation("Title", 255),
  description: createRequiredTextWidthValidation("Description", 500),
  backgroundColor: yup
    .string()
    .required("Background color is required")
    .matches(isHexCodeRegex, "HEX code is invalid"),
  firstButtonLink: validateOptionalURL,
  secondButtonLink: validateOptionalURL,
  category: createRequiredTextWidthValidation("Category", 255),
  promoIconTextColor: yup
    .string()
    .max(255, "Icon must be at most 255 characters")
    .nullable(),
  promoImage1: yup
    .string()
    .max(255, "Icon must be at most 255 characters")
    .nullable(),
  promoImage1Text: yup
    .string()
    .max(255, "Icon must be at most 255 characters")
    .nullable(),
  promoImage2: yup
    .string()
    .max(255, "Icon must be at most 255 characters")
    .nullable(),
  promoImage2Text: yup
    .string()
    .max(255, "Icon must be at most 255 characters")
    .nullable(),
  promoImage3: yup
    .string()
    .max(255, "Icon must be at most 255 characters")
    .nullable(),
  promoImage3Text: yup
    .string()
    .max(255, "Icon must be at most 255 characters")
    .nullable(),
  promoImage4: yup
    .string()
    .max(255, "Icon must be at most 255 characters")
    .nullable(),
  promoImage4Text: yup
    .string()
    .max(255, "Icon must be at most 255 characters")
    .nullable(),
});

export const inputsRows: ValidFieldNames[] = [
  {
    label: "Title",
    field: "title",
    type: InputTypes.text,
    isRequired: true,
  },
  {
    label: "Description",
    field: "description",
    type: InputTypes.textarea,
    isRequired: true,
    textareaprops: {
      vtResize: true,
      height: 'auto',
    },
  },
  {
    label: "Background Image (Desktop) Recommended size 2200x440px",
    field: "desktopPhoto",
    type: InputTypes.image,
    isRequired: true,
  },
  {
    label: "Mobile Image Recommended size 1200x740px",
    field: "mobilePhoto",
    type: InputTypes.image,
    isRequired: true,
  },
  {
    label: "Category",
    field: "category",
    type: InputTypes.select,
    isRequired: true,
  },

  {
    label: "Text color (HEX)",
    field: "textColor",
    type: InputTypes.colorPicker,
    isRequired: true,
  },
  {
    label: "Background color (Mobile)",
    field: "backgroundColor",
    type: InputTypes.colorPicker,
    isRequired: true,
  },

  {
    label: "Button Name",
    field: "firstButtonName",
    type: InputTypes.text,
  },
  {
    label: "Button Link",
    field: "firstButtonLink",
    type: InputTypes.text,
  },
  {
    label: "Button Name 2",
    field: "secondButtonName",
    type: InputTypes.text,
  },
  {
    label: "Button Link 2",
    field: "secondButtonLink",
    type: InputTypes.text,
  },
  {
    label: "Visible on Site",
    field: "displayOnSite",
    type: InputTypes.checkbox,
  },
];

export const bannersTypeList = [
  {
    optionName: "Top Banner",
    value: BannerCategoryEnum.banner,
  },
  {
    optionName: "Bottom Banner",
    value: BannerCategoryEnum.slider,
  },
];
