import { createSlice } from '@reduxjs/toolkit';

import { IState } from './types';
import {
  getDashboardSeeMoreDataThunk,
  getDashboardSortingDataThunk,
  getDashboardThunk,
  getSuperAdminTasksInitialThunk,
  getSuperAdminTasksSeeMoreThunk,
} from './actions';

const initialState: IState = {
  tasksLoading: true,
  tasksSeeMoreLoading: false,
  tasksSeeMore: false,
  tasks: [],
// ### ### ### ### ### ### ### ### ### ### ### ### ### ### ### ### ### ### ### ###
  chartsAndData: null,
  chartsAndDataInitialLoading: true,
  chartsAndDataSeeMoreLoading: false,
  chartsAndDataSeeMore: false,
};

const vendorsSlice = createSlice({
  name: 'vendors',
  initialState,
  reducers: {
    resetVendors: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getSuperAdminTasksInitialThunk.pending, (state) => {
      state.tasksLoading = true;
    });
    builder.addCase(getSuperAdminTasksInitialThunk.fulfilled, (state, { payload }) => {
      if (payload.length < 8) {
        state.tasksSeeMore = true;
      } else {
        state.tasksSeeMore = false;
      }

      state.tasksLoading = false;
      state.tasks = payload;
    });
    builder.addCase(getSuperAdminTasksInitialThunk.rejected, (state) => {
      state.tasksLoading = false;
    });
    builder.addCase(getSuperAdminTasksSeeMoreThunk.pending, (state) => {
      state.tasksSeeMoreLoading = true;
    });
    builder.addCase(getSuperAdminTasksSeeMoreThunk.fulfilled, (state, { payload }) => {
      if (payload.length < 8) {
        state.tasksSeeMore = true;
      } else {
        state.tasksSeeMore = false;
      }

      state.tasksSeeMoreLoading = false;
      state.tasks = [...state.tasks, ...payload];
    });
    builder.addCase(getSuperAdminTasksSeeMoreThunk.rejected, (state) => {
      state.tasksSeeMoreLoading = false;
    });

    // ### ### ### ### ### ### ### ### ### ### ### ### ### ### ### ### ### ### ###
    // Initial Getting Dashboard Data
    builder.addCase(getDashboardThunk.pending, (state) => {
      state.chartsAndDataInitialLoading = true;
    });
    builder.addCase(getDashboardThunk.fulfilled, (state, { payload }) => {
      if (payload.dashboardData.ordersData.length < 8) {
        state.chartsAndDataSeeMore = true;
      } else {
        state.chartsAndDataSeeMore = false;
      }

      state.chartsAndDataInitialLoading = false;
      state.chartsAndData = payload;
    });
    builder.addCase(getDashboardThunk.rejected, (state) => {
      state.chartsAndDataInitialLoading = false;
    });
    // ### ### ### ###   Sorting Dashboard Data
    builder.addCase(getDashboardSortingDataThunk.fulfilled, (state, { payload }) => {
      if (state.chartsAndData) {
        state.chartsAndData.dashboardData.ordersData = payload.dashboardData.ordersData;
      }
    });
    // ### ### ### ###  Get Dashboard See More Data Thunk
    builder.addCase(getDashboardSeeMoreDataThunk.pending, (state) => {
      state.chartsAndDataSeeMoreLoading = true;
    });
    builder.addCase(getDashboardSeeMoreDataThunk.fulfilled, (state, { payload }) => {
      if (
        state.chartsAndData &&
        (payload.dashboardData.ordersData.length - state.chartsAndData.dashboardData.ordersData.length) < 8
      ) {
        state.chartsAndDataSeeMore = true;
      } else {
        state.chartsAndDataSeeMore = false;
      }

      state.chartsAndDataSeeMoreLoading = false;
      if (state.chartsAndData) {
        state.chartsAndData.dashboardData.ordersData = payload.dashboardData.ordersData;
        // state.chartsAndData.dashboardData.ordersData = [
        //   ...state.chartsAndData.dashboardData.ordersData,
        //   ...payload.dashboardData.ordersData,
        // ];
      }
    });
    builder.addCase(getDashboardSeeMoreDataThunk.rejected, (state) => {
      state.chartsAndDataSeeMoreLoading = false;
    });
  },
});

export const { resetVendors } = vendorsSlice.actions;
export default vendorsSlice.reducer;
