import { createAsyncThunk } from "@reduxjs/toolkit";
import { http } from "@services/RequestService";
import { customErrorHandling } from "@utils/errorHandler";
import { AxiosData } from "@utils/globalTypes";
import { AxiosResponse } from "axios";
import {
  Filters,
  constructQueryStringExceptionWithOffer,
} from "@utils/helpers";

import { ISet, ISetForCustomer } from "./types";
import {
  IShipTo,
  UpdateOrderPayload,
  UpdateShipmentPayload,
} from "../order/types";

const prefix = "/orders";

export const getSetsThunk = createAsyncThunk<
  { sets: ISet[]; total: number },
  Filters,
  { rejectValue: AxiosData }
>("orders/all", async (searchingData, thunkAPI) => {
  try {
    const queryParams = constructQueryStringExceptionWithOffer(searchingData);

    const {
      data: { data },
    } = await http.get<AxiosResponse<{ sets: ISet[]; total: number }>>(
      `${prefix}?${queryParams}`,
    );

    return data;
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});

export const getSetsForCustomerThunk = createAsyncThunk<
  { sets: ISetForCustomer[] },
  { customerId: string },
  { rejectValue: AxiosData }
>("orders/profile/all", async ({ customerId }, thunkAPI) => {
  try {
    const {
      data: { data },
    } = await http.get<AxiosResponse<{ sets: ISetForCustomer[] }>>(
      `${prefix}/profile/${customerId}`,
    );

    return data;
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});

export const updateOrderInfo = createAsyncThunk<
  void,
  UpdateOrderPayload,
  { rejectValue: AxiosData }
>("orders/update", async ({ id, payload }, thunkAPI) => {
  try {
    await http.put(`${prefix}/jobs/${id}`, payload);
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});

export const updateShipmentInfo = createAsyncThunk<
  void,
  UpdateShipmentPayload,
  { rejectValue: AxiosData }
>("orders/update-shipment", async ({ id, ...payload }, thunkAPI) => {
  try {
    await http.put(`${prefix}/shipments/${id}/tracking`, payload);
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});

export const updateSetsStatuses = createAsyncThunk<
  void,
  { status: string; ids: string[] },
  { rejectValue: AxiosData }
>("orders/update-sets-statuses", async (payload, thunkAPI) => {
  try {
    await http.put(`${prefix}/sets/status`, payload);
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});

export const updateOrderShipToInfo = createAsyncThunk<
  void,
  IShipTo,
  { rejectValue: AxiosData }
>("orders/update-shipTo", async ({ id, ...payload }, thunkAPI) => {
  try {
    await http.put(`${prefix}/shipments/${id}/shipping-address`, payload);
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});

export const updateOrderDropToInfo = createAsyncThunk<
  void,
  IShipTo,
  { rejectValue: AxiosData }
>("orders/update-dropTo", async ({ id, ...payload }, thunkAPI) => {
  try {
    await http.put(`${prefix}/shipments/${id}/dropship-address`, payload);
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});

export const getArrowIdsThunk = createAsyncThunk<
  { data: string[] },
  Filters,
  { rejectValue: AxiosData }
>("orders/all-arrows", async ({ status }, thunkAPI) => {
  try {
    // eslint-disable-next-line max-len
    const { data } = await http.get<{ data: string[] }>(
      `${prefix}/arrow?status=${status}`,
    );

    return data;
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});
