import { memo, useMemo } from "react";

import { Job } from "@features/orders/order/types";
import { useFormContext } from "react-hook-form";
import Typography from "@mui/material/Typography";
import Input from "@containers/common/Input";
import { Box } from "@mui/material";
import { priceFormatting } from "@utils/helpers";

import EditableAttributes from "../components/EditableAttributes";
import UneditableAttributes from "../components/UneditableAttributes";
import { StyledWrapperDynamically } from "../components/EditableAttributes/styled";
import { StyledLinkNew } from "../../../../styled";
import { Left, StaticStructure } from "./styled";
import AttributePrices from "../components/AttributePrices";

interface JobLeftSectionProps {
  job: Job;
  jobIndex: number;
  keyByIndex: string;
  isEditable: boolean;
}

function formatDateUS(dateStr: string) {
  const [year, month, day] = dateStr.split("-").map(Number);
  const fullYear = year < 100 ? year + 2000 : year;

  return `${month}/${day}/${fullYear}`;
}

const JobLeftSection = ({
  keyByIndex,
  job,
  jobIndex,
  isEditable,
}: JobLeftSectionProps) => {
  const {
    watch,
    register,
    formState: { errors },
  } = useFormContext();

  const setsCount = useMemo(() => {
    const setsCounts = job?.shipments?.map(({ sets }) => sets.length);
    const sum = setsCounts.reduce((acc, num) => acc + num, 0);

    return sum;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sets = job?.shipments?.flatMap((sh) => sh.sets) || [];
  const setsQuantities = job?.grandFormatOptionsWeb?.setsQuantities || [];

  const quantityGP = sets.reduce((acc, cur) => {
    const quantity = cur?.quantityGP || 0;

    return acc + quantity;
  }, 0);

  const totalGPQuantity = useMemo(() => {
    const sum = setsQuantities.reduce(
      (acc, num) => acc + num,
      0,
    );

    return sum || 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        borderLeft: "1px solid #ddd",
        overflow: "hidden",
      }}
    >
      <Left>
        <StyledWrapperDynamically>
          <Typography variant="body3">Job Name/PO:</Typography>
          <Input
            type="text"
            onlyBorder
            orderDesignStyle
            {...register(`${keyByIndex}.name`)}
            label=""
            placeholder="Job Name"
            // @ts-ignore
            errorMessage={errors?.jobs?.[jobIndex]?.name?.message}
          />
        </StyledWrapperDynamically>
        <StaticStructure>
          <Typography variant="body3">Item:</Typography>
          <Typography variant="body3">{job?.item?.item}</Typography>
        </StaticStructure>
        {job.isEDDM && job.eddmDeliveryDate ? (
          <StaticStructure>
            <Typography variant="body3">Target in Mailbox:</Typography>
            <Typography variant="body3">
              {formatDateUS(job.eddmDeliveryDate)}
            </Typography>
          </StaticStructure>
        ) : null}
        {job?.widthGP && job?.heightGP && (
          <>
            <StaticStructure>
              <Typography variant="body3">Size:</Typography>
              <Typography variant="body3">
                {job?.widthGP}
                {' '}
                X
                {job?.heightGP}
              </Typography>
            </StaticStructure>
          </>
        )}
        <StyledWrapperDynamically>
          <Typography variant="body3">
            {job.isEDDM
              ? "Mailing Quantity"
              : job.isMailing
                ? "Printing - Set"
                : "Quantity - Set"}
            :
          </Typography>
          <Typography variant="body3">
            {job.isEDDM
              ? job.mailingQuantity
              : job.grandFormatOptionsWeb
                ? totalGPQuantity
                : job?.item?.quantity}
            {" "}
            {job.grandFormatOptionsWeb && `(${setsCount})`}
            {setsCount > 1 &&
              !job.grandFormatOptionsWeb &&
              `(${setsCount}) - $${job.quantityPrice && (job.quantityPrice / setsCount).toFixed(2)}`}
          </Typography>
          <Box sx={{ gap: 1 }}>
            <p>$</p>
            {isEditable ? (
              <Input
                type="text"
                onlyBorder
                orderDesignStyle
                {...register(`${keyByIndex}.quantityPrice`)}
                label=""
                placeholder="Enter"
                errorMessage={
                  // @ts-ignore
                  errors?.jobs?.[jobIndex]?.quantityPrice?.message
                }
              />
            ) : (
              <p>{watch(`${keyByIndex}.quantityPrice`)}</p>
            )}
          </Box>
        </StyledWrapperDynamically>
        {job.grandFormatOptionsWeb &&
          job.shipments
            .flatMap((item) => item.sets)
            .map((set, index) => {
              return (
                <StyledWrapperDynamically>
                  <Typography variant="body3">
                    Quantity - Set
                    {' '}
                    {index + 1}
                    {' '}
                    :
                  </Typography>
                  <Typography variant="body3">
                    {setsQuantities[index] ?? 0}
                    {' '}
                    -
                    {" "}
                    {totalGPQuantity &&
                      (setsQuantities[index] ?? 0) > 0 &&
                      priceFormatting(
                        (job.quantityPrice / totalGPQuantity)
                          * (setsQuantities[index] ?? 0),
                      )}
                  </Typography>
                </StyledWrapperDynamically>
              );
            })}
        {job?.isMailing && !job.isEDDM && (
          <>
            <StyledWrapperDynamically>
              <Typography variant="body3">Mailing Service:</Typography>
              <Typography variant="body3">{job?.mailingQuantity}</Typography>
              <Box sx={{ gap: 1 }}>
                <p>$</p>
                {isEditable ? (
                  <Input
                    type="text"
                    onlyBorder
                    orderDesignStyle
                    {...register(`${keyByIndex}.mailingFee`)}
                    label=""
                    placeholder="Enter"
                    errorMessage={
                      // @ts-ignore
                      errors?.jobs?.[jobIndex]?.mailingFee?.message
                    }
                  />
                ) : (
                  <p>{watch(`${keyByIndex}.mailingFee`)}</p>
                )}
              </Box>
            </StyledWrapperDynamically>
            {!job?.isEDDM ? (
              <StyledWrapperDynamically>
                <Typography variant="body3">Postage Fee:</Typography>
                <Typography variant="body3">&nbsp;</Typography>
                <Box sx={{ gap: 1 }}>
                  <p>$</p>
                  <Input
                    type="text"
                    onlyBorder
                    orderDesignStyle
                    {...register(`${keyByIndex}.postageFee`)}
                    label=""
                    placeholder="Enter"
                    errorMessage={
                      // @ts-ignore
                      errors?.jobs?.[jobIndex]?.postageFee?.message
                    }
                  />
                </Box>
              </StyledWrapperDynamically>
            ) : null}
          </>
        )}

        {job?.isEDDM && (
          <>
            <StyledWrapperDynamically>
              <Typography variant="body3">Postage Fee:</Typography>
              <Typography variant="body3">&nbsp;</Typography>
              <Box sx={{ gap: 1 }}>
                <p>$</p>
                <p>{watch(`${keyByIndex}.postageFee`)}</p>
              </Box>
            </StyledWrapperDynamically>
            <StyledWrapperDynamically>
              <Typography variant="body3">Postage Processing Fee:</Typography>
              <Typography variant="body3">&nbsp;</Typography>
              <Box sx={{ gap: 1 }}>
                <p>$</p>
                <p>{watch(`${keyByIndex}.postageProcessingFee`)}</p>
              </Box>
            </StyledWrapperDynamically>
          </>
        )}

        {job.customEstimateId && (
          <StyledWrapperDynamically>
            <Typography variant="body3">Custom Estimate:</Typography>
            <StyledLinkNew
              to={`/orders/open-estimates/edit/${job.customEstimateId}`}
            >
              View custom estimate
            </StyledLinkNew>
          </StyledWrapperDynamically>
        )}
        {isEditable ? (
          <EditableAttributes job={job} jobIndex={jobIndex} />
        ) : (
          <UneditableAttributes job={job} jobIndex={jobIndex} />
        )}
      </Left>
      <AttributePrices jobIndex={jobIndex} isEditable={isEditable} />
    </Box>
  );
};

export default memo(JobLeftSection);
