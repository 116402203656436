import { memo, useEffect, useState } from "react";

import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { StyledStack } from "@containers/common/StyledAddEditTables/styled";
import { useAppDispatch, useAppSelector } from "@features/app/hooks";
import { addProductsPrices } from "@features/products/setPrice/actions";
import { selectProductsSetPrice } from "@features/products/setPrice/selectors";
import { useParams } from "react-router-dom";
import StyledTable from "@containers/common/Table";
import GlobalUpdatesMessage, {
  GlobalUpdatesMessageProps,
} from "@containers/common/GlobalUpdatesMessage";
import { GLOBAL_MESSAGES } from "@utils/messages";

import { AddDataSchema, IAddDataForm } from "./helpers";
import TableRow from "./TableRow";
import { StyledContent, StyledSubmitBtn, SubTitleSection } from "./styles";
import { StyledSubTitle } from "../../NewAddAttributes/components/CheckboxList/styles";

interface IInputsTable {
  subTitle?: string;
}

const InputsTable = ({ subTitle }: IInputsTable) => {
  const dispatch = useAppDispatch();
  const { id: productId } = useParams();
  const [isSuccessMsg, setIsSuccessMsg] = useState<GlobalUpdatesMessageProps>({
    msg: "",
    status: null,
  });

  const {
    data: { quantities, seperatedCategories },
    actionLoading,
  } = useAppSelector(selectProductsSetPrice);

  const methods = useForm<IAddDataForm>({
    resolver: yupResolver(AddDataSchema as any), // TODO: add typing
    defaultValues: { quantities, seperatedCategories },
  });

  const { handleSubmit, watch, reset, setValue } = methods;

  useEffect(() => {
    reset({ quantities, seperatedCategories });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantities, seperatedCategories]);

  const onSubmit = (data: IAddDataForm) => {
    dispatch(addProductsPrices({ body: data, id: productId as string }))
      .unwrap()
      .then(() => {
        setIsSuccessMsg({ msg: GLOBAL_MESSAGES.update, status: 200 });
      })
      .catch(() => {
        setIsSuccessMsg({ msg: GLOBAL_MESSAGES.wrong, status: 400 });
      });
  };

  const productsPricesData = watch("quantities");
  const seperatedCategoriesData = watch("seperatedCategories");

  const handleCheck = (id?: string) => {
    if (id) {
      const newChecked = seperatedCategoriesData;

      if (newChecked.indexOf(id) > -1) {
        const index = newChecked.indexOf(id);

        newChecked.splice(index, 1);
      } else {
        newChecked.push(id);
      }

      setValue("seperatedCategories", newChecked);
    }
  };

  const attributeCategoriesNames =
    productsPricesData[0].attributeCategories.map((item) => ({
      label: item.name,
      id: item.id,
    }));

  const headCells = [
    {
      label: "QUANTITY",
      id: "",
    },
    ...attributeCategoriesNames,
  ];

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledContent>
          <SubTitleSection>
            {subTitle && (
              <StyledSubTitle variant="body2">{`${subTitle}:`}</StyledSubTitle>
            )}
          </SubTitleSection>
          <StyledTable
            headCells={headCells}
            handleCheck={handleCheck}
            seperatedCategoriesData={seperatedCategoriesData}
          >
            {productsPricesData.map((_, rowIdx: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableRow key={rowIdx} rowIdx={rowIdx} />
            ))}
          </StyledTable>
          <StyledSubmitBtn actionLoading={actionLoading} />
          <GlobalUpdatesMessage {...isSuccessMsg} />
        </StyledContent>
      </form>
    </FormProvider>
  );
};

export default memo(InputsTable);
