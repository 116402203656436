/* eslint-disable no-debugger */
import { ChangeEvent, DragEvent, useState } from 'react';

import { useAppDispatch } from '@features/app/hooks';
import { isValidFileExtension, uploadFile } from '@utils/imageService';
import { getUploadUrl } from '@containers/common/FileUploader/requests';
import { updateJobSetInfo } from '@features/orders/order/actions';

import { CertificateEnumType, ProcessState } from '.';

export const useFileUploadLogic = (defaultKey: string, setValue: any, setId: string) => {
  const dispatch = useAppDispatch();
  const [process, updateProcess] = useState<ProcessState>({} as ProcessState);

  const uploadToS3 = async (files: FileList, type: CertificateEnumType) => {
    try {
      if (files && files[0]) {
        const file = files[0];

        const { fileList } = await getUploadUrl(files, type);
        const item = fileList[0];

        await uploadFile(file, item);

        if (setValue) {
          setValue(`${defaultKey}.${type}`, item.path);
          setValue(`${defaultKey}.${type}Name`, file.name);
          setValue(`${defaultKey}.${type}Size`, file.size);
        }

        updateProcess({ type, message: '', isLoading: false });

        const sizeKey = `${type}Size`;
        const nameKey = `${type}Name`;

        const payload = {
          id: setId,
          payload: {
            [type]: item.path || null,
            [sizeKey]: file.size,
            [nameKey]: file.name,
          },
        };

        dispatch(updateJobSetInfo(payload));
      } else {
        updateProcess({
          type,
          isLoading: false,
          message: '',
        });
      }
    } catch {
      updateProcess({
        type,
        isLoading: false,
        message: 'Something went wrong',
      });
    }
  };

  const handleFiles = async (files: FileList, type: CertificateEnumType) => {
    if (Array.from(files).every((file) => isValidFileExtension(file))) {
      await uploadToS3(files, type);
    } else {
      updateProcess({
        type,
        isLoading: false,
        message: 'You can upload only PDF type file.',
      });
    }
  };

  const onChange = async (
    event: ChangeEvent<HTMLInputElement>,
    type: CertificateEnumType,
  ) => {
    updateProcess({ type, message: '', isLoading: true });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { files } = event.target as any;

    if (files) {
      await handleFiles(files, type);
    } else {
      updateProcess({ type, message: '', isLoading: false });
    }
  };

  const onDrop = async (
    event: DragEvent<HTMLDivElement>,
    type: CertificateEnumType,
  ) => {
    event.preventDefault();

    updateProcess({ type, message: '', isLoading: true });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { files } = event.dataTransfer as any;

    if (files) {
      await handleFiles(files, type);
    } else {
      updateProcess({ type, message: '', isLoading: false });
    }
  };

  return {
    process,
    onDrop,
    onChange,
    updateProcess,
  };
};
