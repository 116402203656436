import { Job } from "@features/orders/order/types";

import { IOrderForm } from "./formPayloadType";

export const preparingShipments = (job: Job) => {
  return job.shipments.map((shipment) => {
    const { sets } = shipment;

    const setsList = sets.map(
      ({
        id,
        backProof,
        frontProof,
        backArtWork,
        frontArtWork,
        status,
        frontArtworkSize,
        frontArtworkName,
        backArtworkSize,
        backArtworkName,
        backProofSize,
        backProofName,
        frontProofSize,
        frontProofName,
        frontPrint,
        backPrint,
        backProofDate,
        frontProofDate,
      }) => ({
        id,
        frontArtWork,
        frontArtworkSize,
        frontArtworkName,

        backArtWork,
        backArtworkSize,
        backArtworkName,

        backProof,
        backProofSize,
        backProofName,

        frontProof,
        frontProofSize,
        frontProofName,

        frontPrint,
        backPrint,

        status,

        backProofDate,
        frontProofDate,
      }),
    );

    const newShipment = {
      id: shipment?.id,
      sets: setsList,
      trackingNumber: shipment.trackingNumber || "",
      trackingCarrier: shipment.trackingCarrier || "",
    };

    return newShipment;
  });
};

export const preparingFormData = (jobs: Job[]): IOrderForm[] => {
  const collection = jobs.map((job) => {
    const jobAttr = job?.jobAttributes.map((item) => {
      return {
        ...item,
        attributePrice: Number(item.attributePrice).toFixed(2),
      };
    });

    return {
      id: job?.id,
      itemDoesNotShip: job.itemDoesNotShip,
      name: job?.name,
      notes: job?.notes || "",
      status: job?.status,
      mailingPO: job?.mailingPO || "",
      mailingFile: job?.mailingFile,
      discountAmount: job?.discountAmount,
      postageToVendor: job.postageToVendor,
      customerPostage: job.customerPostage,
      postageFromVendor: job.postageFromVendor,
      shipments: preparingShipments(job),
      shipping: Number(job?.shipping),
      attributes: jobAttr,
      editablePrice: Number(job?.editablePrice),
      editableShipping: Number(job?.editableShipping),
      editableTax: Number(job?.editableTax),
      editableTotal: Number(job?.editableTotal),
      quantityPrice: Number(job?.quantityPrice).toFixed(2),
      price: job?.price,
      tax: job.tax,
      total: job.total,
      sendEmail: false, // don't get from response
      emails: job?.emails || [],
      textTemplate: job?.textTemplate,
      emailTemplateId: job?.emailTemplateId,

      vendor: job?.vendor?.name || "",
      vendorId: job?.vendorId || null,
      vendorJob: job?.vendorJob || "",
      vendorCost: job?.vendorCost || "",

      vendor2: job?.vendor2?.name || "",
      vendor2Id: job?.vendor2Id || null,
      vendorJob2: job?.vendorJob2 || "",
      vendorCost2: job?.vendorCost2 || "",

      vendor3: job?.vendor3?.name || "",
      vendor3Id: job?.vendor3Id || null,
      vendorJob3: job?.vendorJob3 || "",
      vendorCost3: job?.vendorCost3 || "",

      mailingQuantity: job?.mailingQuantity
        ? Number(job?.mailingQuantity)
        : null,
      mailingFee:
        job?.mailingFee !== undefined || job?.mailingFee !== null
          ? Number(job?.mailingFee).toFixed(2)
          : null,
      postageFee:
        job?.postageFee !== undefined || job?.postageFee !== null
          ? Number(job?.postageFee).toFixed(2)
          : null,
      postageProcessingFee:
        job?.postageProcessingFee !== undefined || job?.postageFee !== null
          ? Number(job?.postageProcessingFee).toFixed(2)
          : null,
    };
  });

  return collection;
};

export const preparingRequestPayload = ({ jobs }: { jobs: IOrderForm[] }) => {
  const jobsList = jobs;

  const newJobs = jobsList.map((job) => {
    if (job.vendorId === "") {
      job.vendorId = null;
    }

    return {
      ...job,
      attributes: job?.attributes.map((item) => ({
        ...item,
        attributePrice: Number(item.attributePrice),
      })),
      editablePrice: Number(job?.editablePrice),
      editableShipping: Number(job?.editableShipping),
      editableTax: Number(job?.editableTax),
      editableTotal: Number(job?.editableTotal),
      quantityPrice: Number(job?.quantityPrice),
      emails: job.emails?.length ? job.emails : null,
    };
  });

  return newJobs;
};

export const dynamicAccess = (obj: any, path: string) => {
  return path
    .replace(/\[(\d+)\]/g, ".$1") // Convert array indices from [0] to .0 for easier access
    .split(".") // Split the path by .
    .reduce((acc, key) => acc && acc[key], obj); // Traverse the object
};
