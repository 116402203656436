import { PrintType } from '@features/subcategories/enums';
import { ISubcategoriesInfo } from '@features/subcategories/types';
import { IShippingZip } from '@features/shippingZip/types';

import { IAddSubcategoryForm, IIsSelectedShippingZip } from './types';

export const defaultValues = {
  title: '',
  description: '',
  specifications: '',
  photo: '',
  additionalPhotos: [],
  categoryId: '',
  printType: '',
  defaultInkInEstimator: '',
  visibleOnSite: false,
  displayAsCardInHeader: false,
  makeCategoryAsProduct: true,
  shippingZipsAll: false,
  isEDDM: false,
  useGrandFormatSQFtTemplate: false,
  isNew: false,
  isSale: false,
  showInSpotlight: false,
  categoryDiscountOffMSRP: null,
  shippingZips: [],
  metaTagTitle: '',
  metaTagDescription: '',
  metaTagKeywords: '',
  productTypes: [],
  newName: '',
  frontArtworkLable: '',
  backArtworkLable: '',
  backArtworkState: false,
  slug: '',
};

export const printTypeNames: { [key in PrintType]: string } = {
  [PrintType.OFFSET_PRODUCTS]: 'Offset Production',
  [PrintType.GRAND_FORMAT_PRODUCTS]: 'Grand Format Products',
  [PrintType.PROMO_PRODUCTS]: 'Promo Products',
};

export const printTypeName = (name: PrintType | null) => {
  return name && printTypeNames[name];
};

export const printTypeValues = Object.entries(printTypeNames).map(([key, optionName]) => ({
  value: key as PrintType,
  optionName,
}));

export const addingIsSelectedFlag = (
  shippingZips: IShippingZip[] | null,
  selectedList: string[],
) => {
  return shippingZips?.map((item) => ({
    ...item,
    isSelected: selectedList.includes(item.id),
  }));
};

export const filterSelectedListIds = (shippingZips?: IIsSelectedShippingZip[]) => (
  shippingZips?.filter((item) => item.isSelected)?.map((item) => item.id));

export const formattedData = (
  data: IAddSubcategoryForm | ISubcategoriesInfo,
  shippingZips: IShippingZip[] | null,
): IAddSubcategoryForm => {
  const selectedShippingList = data.shippingZips.map((item) => item.id);
  const newShippingZips = addingIsSelectedFlag(shippingZips, selectedShippingList);

  if ('productTypes' in data) {
    const newCollected = data?.productTypes?.map((productType) => {
      const selectedList = productType.shippingZips.map((i) => i.id);

      const localNewShippingZips = addingIsSelectedFlag(shippingZips, selectedList);

      return {
        ...productType,
        shippingZips: localNewShippingZips,
        customShippingFacility: productType.customShippingFacility || false,
      };
    }) || [];

    // @ts-ignore
    data.productTypes = newCollected;
  }

  const newData = {
    ...data,
    defaultInkInEstimator: data.defaultInkInEstimator || '',
    shippingZips: newShippingZips,
    shippingZipsAll: data.shippingZips.length === shippingZips?.length,
  } as IAddSubcategoryForm;

  return newData;
};

export const formattingPayload = (data: IAddSubcategoryForm) => {
  const { defaultInkInEstimator, productTypes, makeCategoryAsProduct, photo } = data;
  const formattedShippingZips = filterSelectedListIds(data.shippingZips);

  const payload: any = {
    ...data,
    productTypes: null,
    shippingZips: formattedShippingZips,
    defaultInkInEstimator: defaultInkInEstimator || null,
  };

  const [defaultPhoto, ...additionalPhotos] = photo;

  payload.photo = defaultPhoto;
  payload.additionalPhotos = additionalPhotos ?? [];

  let newProductTypes = [] as Record<string, any>[] | undefined;

  if (!makeCategoryAsProduct) {
    newProductTypes = productTypes?.map((item) => {
      const newShippingZips = item?.shippingZips?.filter((i) => i.isSelected).map((i) => i.id);
      const collectedProductType: Record<string, any> = {
        name: item.name,
        photos: item.photos,
        description: item?.description || null,
        specifications: item?.specifications || null,
        customShippingFacility: item.customShippingFacility,
        slug: item.slug,
      };

      if (item.customShippingFacility && newShippingZips.length) {
        collectedProductType.shippingZips = newShippingZips;
      }

      if (item?.id) {
        // added after requested to save when do click many times on `Disable Types (Make category as product):`
        collectedProductType.id = item?.id;
      }

      return collectedProductType;
    });

    payload.productTypes = newProductTypes;
    payload.description = null;
    payload.specifications = null;
  } else {
    payload.description = data?.description || null;
    payload.specifications = data?.specifications || null;
  }

  return payload;
};
