import { ChangeEvent, Dispatch, SetStateAction } from 'react';

import { Box } from '@mui/material';

import { StyledLabel } from './styles';

interface ToggleSwitchProps {
  switchState: boolean;
  setSwitchState: Dispatch<SetStateAction<boolean>>;
}

const ToggleSwitch = ({
  switchState,
  setSwitchState,
}: ToggleSwitchProps) => {
  function handleOnChange(e: ChangeEvent<HTMLInputElement>) {
    setSwitchState((prev) => !prev);
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
      }}
    >
      <StyledLabel htmlFor="checkbox" checked={switchState}>
        <input
          id="checkbox"
          type="checkbox"
          checked={switchState}
          onChange={handleOnChange}
        />
      </StyledLabel>
      <Box sx={{ width: '50px' }}>
        {!switchState ? 'Disabled' : 'Enabled '}
      </Box>
    </Box>
  );
};

export default ToggleSwitch;
