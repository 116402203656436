import { InputTypes, ValidFieldNames } from "@utils/globalTypes";

export const inputsRows: ValidFieldNames[] = [
  {
    label: "Job Name",
    field: "jobName",
    type: InputTypes.text,
    isRequired: true,
  },
  {
    label: "Status",
    field: "status",
    type: InputTypes.select,
    isRequired: true,
    selectprops: {
      width: "165px",
    },
  },
];

export const customEstimateStatusOptions = [
  {
    value: "received",
    optionName: "Received",
  },
  {
    value: "inReview",
    optionName: "In Review",
  },
  {
    value: "rejected",
    optionName: "Rejected",
  },
  {
    value: "complete",
    optionName: "Active",
  },
];
export const customEstimateReactivatedStatusOptions = [
  {
    value: "received",
    optionName: "Received",
  },
  {
    value: "inReview",
    optionName: "In Review",
  },
  {
    value: "rejected",
    optionName: "Rejected",
  },
  {
    value: "complete",
    optionName: "Active",
  },
  {
    value: "reactivated",
    optionName: "Reactivated",
  },
  {
    value: "expired",
    optionName: "Expired",
  },
];
