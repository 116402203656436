import { memo, useCallback, useEffect, useState } from 'react';

import StyledTable from '@containers/common/Table';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import Loader from '@containers/common/Loader';
import EmptyState from '@containers/common/EmptyState';
import { selectTasks } from '@features/customers/tasks/selectors';
import { getAllTasks } from '@features/customers/tasks/actions';
import PageTitle from '@containers/common/PageTitle';
import PAGE_ROUTES from '@routes/routingEnum';
import queryString from 'query-string';
import { useLocation, useSearchParams } from 'react-router-dom';
import { GlobalQueryString } from '@utils/globalTypes';
import useMount from '@customHooks/useMount';
import { getAllUsers } from '@features/users/actions';
import { resetUsers } from '@features/users/slice';
import { usePrevScroll } from '@utils/prevState';

import { headCells } from './tableData';
import TableRow from './components/TableRow';
import { IFiltersForm } from './components/SearchSection/helpers';
import SearchSection from './components/SearchSection';
import Switcher from './components/Switcher';

const Tasks = () => {
  usePrevScroll();

  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const params = queryString.parse(search) as GlobalQueryString;
  const { tab } = params;
  // if 'switcherStatus' -it is 0 it equal to 'open'
  const [switcherStatus, setSwitcherStatus] = useState((tab && +tab) ? +tab : 0);
  const { searchTerm = '', dateCreated = '', dateCompleted, status = '', userId = '' } = params as IFiltersForm;
  const { data: tasks, isLoading, total } = useAppSelector(selectTasks);
  const isSearchTerm = searchTerm || dateCreated || dateCompleted || status || userId;

  const fetchData = useCallback(() => {
    const query = {
      searchTerm,
      dateCreated,
      dateCompleted,
      status: !switcherStatus ? 'open' : 'closed',
      userId,
    };

    if (!status) {
      searchParams.set("status", "open");
      setSearchParams(searchParams);

      return;
    }

    dispatch(getAllTasks(query));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, dateCreated, dateCompleted, status, userId, dispatch, switcherStatus]);

  useEffect(
    () => fetchData(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchTerm, dateCreated, dateCompleted, status, userId, dispatch, switcherStatus],
  );

  useMount(() => {
    dispatch(getAllUsers());

    return () => dispatch(resetUsers());
  });

  const columns = [...headCells];

  if (switcherStatus === 0) {
    // it is 'Completed Date' column for opens task
    columns.pop();
  }

  return (
    <>
      <PageTitle title={`Tasks (${total})`} btnName="Add Task" path={PAGE_ROUTES.ADD_TASK} />
      { (isSearchTerm || !!tasks?.length) && <SearchSection /> }

      <Switcher
        switcherStatus={switcherStatus}
        setSwitcherStatus={setSwitcherStatus}
      >
        { isLoading ? (
          <Loader />
        ) : tasks?.length ? (
          <StyledTable headCells={columns}>
            {tasks.map((item) => {
              return (
                <TableRow key={item.id} switcherStatus={switcherStatus} {...item} />
              );
            })}
          </StyledTable>
      ) : (
        <EmptyState
          text={isSearchTerm ? 'No search results found' : 'You don’t have any tasks'}
          isAdd={!isSearchTerm}
        />
      )}

      </Switcher>

    </>
  );
};

export default memo(Tasks);
