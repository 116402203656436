import { memo } from 'react';

import { useAppSelector } from '@features/app/hooks';
import { selectAuth } from '@features/auth/selectors';
import { useNavigate } from 'react-router-dom';
import PAGE_ROUTES from '@routes/routingEnum';
import useMount from '@customHooks/useMount';
import { selectProfileStatus } from '@features/customers/tasks/selectors';

const Home = () => {
  const navigate = useNavigate();
  const { isAuth } = useAppSelector(selectAuth);
  const userStatus = useAppSelector(selectProfileStatus);

  useMount(() => {
    navigate(isAuth ? userStatus ? PAGE_ROUTES.MY_TASKS : PAGE_ROUTES.PRODUCTS_PRODUCTS : PAGE_ROUTES.SIGN_IN);
  });

  return <></>;
};

export default memo(Home);
