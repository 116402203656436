import { memo } from "react";

import { format } from "date-fns";
import Typography from "@mui/material/Typography";
import { ProofCustomerLogs } from "@features/orders/order/types";

import { Content, Colored } from "../../styled";

interface ProofCustomerProps {
  proofCustomerLogs: ProofCustomerLogs[];
  fontSize?: string;
  padding?: string;
  maxHeight?: string;
  margin?: string;
}

const ProofCustomer = ({
  proofCustomerLogs,
  fontSize,
  padding,
  maxHeight,
  margin,
}: ProofCustomerProps) => {
  return (
    <Content padding={padding} maxHeight={maxHeight} margin={margin}>
      {[...proofCustomerLogs].reverse().map((log) => {
        let status = null;
        const { proofStatus } = log;

        // eslint-disable-next-line no-unsafe-optional-chaining
        const [_date, ...rest] = log?.text?.split("-");

        const date = format(new Date(log.created), "MM/dd/yyyy hh:mm a");

        if (proofStatus) {
          status = proofStatus === "accepted" ? "Accepted" : "Rejected";
        }

        const text = `${date} - ${rest.join(" - ")}`;

        return (
          <Typography key={log.id} sx={{ fontSize: fontSize || "11px" }}>
            {status ? (
              <Colored status={status.toLocaleLowerCase()}>{text}</Colored>
            ) : (
              text
            )}
          </Typography>
        );
      })}
    </Content>
  );
};

export default memo(ProofCustomer);
