import { useState } from "react";

import { ISet } from "@features/orders/order/types";
import download from "@assets/images/download.svg";
import { useAppDispatch } from "@features/app/hooks";
import { getArtworkZipDownloadLink } from "@features/orders/order/actions";
import { CircularProgress } from "@mui/material";
import Select from "@containers/common/Select";
import { getOrderStatusesOptions } from "@utils/orderStatuses";
import Modal from "@containers/Orders/Order/components/common/Modal";
import Button from "@containers/common/Button";

import { Artwork, Proof, ProofCustomer } from "./components";
import {
  SetsContainer,
  BoxInformation,
  Title,
  StyledBox,
  SetNameWrapper,
  DownloadLink,
  SetAndStatusWrapper,
  StatusWrapper,
  SeeAllSection,
  SeparateJobRow,
} from "./styled";
import Conversation from "../../../../../../Conversation";
import Print from "./components/Print";

interface SetsProps {
  setsList: ISet[];
  jobIndex: number;
  shipmentIndex: number;
  isEddm?: boolean;
}

const Sets = ({ setsList, jobIndex, shipmentIndex, isEddm }: SetsProps) => {
  if (!setsList?.length) {
    return null;
  }

  const [logsModalIndex, setLogsModalIndex] = useState<number | null>(null);
  const statusOptions = getOrderStatusesOptions();

  const dispatch = useAppDispatch();

  return setsList.map((set, setIndex) => {
    const setStatusKey = `jobs[${jobIndex}].shipments[${shipmentIndex}].sets[${setIndex}].status`;

    const {
      id,
      shipmentId,
      backArtWork,
      frontArtWork,
      created,
      proofCustomerLogs,
      backArtworkState,
      frontArtworkSize,
      backArtworkSize,
      frontArtworkName,
      backArtworkName,
      frontArtworkLable,
      backArtworkLable,
      fileID,
      backArtworkDate,
      frontArtworkDate,
      ...setRest
    } = set;

    const [isDownloading, setIsDownloading] = useState<boolean>(false);

    const handleDownloadClick = async (setId: string) => {
      setIsDownloading(true);

      const response = await dispatch(
        getArtworkZipDownloadLink({ setId }),
      ).unwrap();

      setIsDownloading(false);

      const link = document.createElement("a");

      link.href = response;
      link.download = "artwork.zip";
      link.style.display = "none";

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    };

    return (
      <>
        <SetsContainer key={`${shipmentId} ${id}`}>
          <SetNameWrapper>
            <SetAndStatusWrapper>
              <SeparateJobRow>
                <div>
                  <p>{setRest.name}</p>
                  <span>{set.setNumber}</span>
                </div>
                <StatusWrapper>
                  <span>Job status:</span>
                  <Select
                    label=""
                    name={setStatusKey}
                    placeholderText="Search by Priority"
                    options={statusOptions}
                  />
                </StatusWrapper>
              </SeparateJobRow>
              <Conversation
                setId={id}
                disallowMessage={set.disallowMessage}
                existsUnansweredMessagesForAdmin={
                  set.existsUnansweredMessagesForSet
                }
              />
            </SetAndStatusWrapper>
          </SetNameWrapper>
          <BoxInformation>
            <StyledBox>
              <Title>
                ARTWORK
                <DownloadLink onClick={() => handleDownloadClick(id)}>
                  {" "}
                  {isDownloading && <CircularProgress size={15} />}
                  <img src={download} alt={download} />
                  Download available art (ZIP)
                </DownloadLink>
              </Title>
              <Artwork
                frontArtworkName={frontArtworkName}
                backArtworkName={backArtworkName}
                created={created}
                backArtworkDate={backArtworkDate}
                frontArtworkDate={frontArtworkDate}
                jobIndex={jobIndex}
                setIndex={setIndex}
                shipmentIndex={shipmentIndex}
                backArtworkState={backArtworkState}
                frontArtworkSize={frontArtworkSize}
                backArtworkSize={backArtworkSize}
                frontArtworkLable={frontArtworkLable}
                backArtworkLable={backArtworkLable}
              />
            </StyledBox>
            <StyledBox>
              <Proof
                fileID={fileID}
                setId={id}
                jobIndex={jobIndex}
                setIndex={setIndex}
                shipmentIndex={shipmentIndex}
                backArtworkState={backArtworkState}
                backProofStatus={setRest.backProofStatus}
                frontProofStatus={setRest.frontProofStatus}
              />
            </StyledBox>
            {!isEddm ? (
              <StyledBox>
                <Title>
                  PROOF UPLOAD & STATUS LOG
                  <SeeAllSection
                    onClick={() => {
                      setLogsModalIndex(setIndex);
                    }}
                  >
                    See All
                  </SeeAllSection>
                </Title>
                <ProofCustomer proofCustomerLogs={proofCustomerLogs} />
              </StyledBox>
            ) : (
              <StyledBox>
                <Print
                  setId={id}
                  jobIndex={jobIndex}
                  setIndex={setIndex}
                  shipmentIndex={shipmentIndex}
                />
              </StyledBox>
            )}
          </BoxInformation>
          {isEddm && (
            <StyledBox sx={{ height: "186px" }}>
              <Title>
                PROOF UPLOAD & STATUS LOG
                <SeeAllSection
                  onClick={() => {
                    setLogsModalIndex(setIndex);
                  }}
                >
                  See All
                </SeeAllSection>
              </Title>
              <ProofCustomer proofCustomerLogs={proofCustomerLogs} />
            </StyledBox>
          )}
        </SetsContainer>
        {logsModalIndex !== null && (
          <Modal
            sx={{ padding: "16px 24px", maxHeight: "655px" }}
            title="PROOF UPLOAD, APPROVAL & STATUS LOG"
            open={logsModalIndex === setIndex}
            handleClose={() => {
              setLogsModalIndex(null);
            }}
          >
            <ProofCustomer
              proofCustomerLogs={proofCustomerLogs}
              fontSize="14px"
              padding="0px"
              maxHeight="534px"
              margin="18px 0px"
            />
            <Button
              onClick={() => {
                setLogsModalIndex(null);
              }}
            >
              Close
            </Button>
          </Modal>
        )}
      </>
    );
  });
};

export default Sets;
