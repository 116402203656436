import { useFormContext } from "react-hook-form";
import { Job } from "@features/orders/order/types";
import { orderingArrayByArray } from "@utils/attributesArrayOrders";
import Input from "@containers/common/Input";
import { Box } from "@mui/material";

import {
  FirstViewBodyException,
  StyledWrapperDynamically,
  RedLabel,
} from "./styled";

interface EditableAttributesProps {
  job: Job;
  jobIndex: number;
}

const EditableAttributes = ({ job, jobIndex }: EditableAttributesProps) => {
  const CUSTOM_ESTIMATE_KEY = "custom estimate";
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const keyByIndex = `jobs[${jobIndex}]`;

  // const messagesObjJobs = errors?.jobs as Record<string, any>;
  // let messageJob = {} as Record<string, any>;
  // if (messagesObjJobs && messagesObjJobs[jobIndex]) {
  //   messageJob = messagesObjJobs[jobIndex];
  // }

  let attributes = { ...job?.item } as Record<string, any>;

  delete attributes.item;

  if (job?.customEstimateId) {
    attributes = {
      ...attributes,
      [CUSTOM_ESTIMATE_KEY]: job.customEstimateId,
    };
  }

  if (job?.widthGP && job?.heightGP && job?.quantityGP) {
    const { quantity, ...rest } = attributes;

    // attributes need check this variable un usable
    attributes = {
      ...rest,
      width: job.widthGP,
      height: job.heightGP,
      quantity: job.quantityGP,
    };
  }

  const { jobAttributes } = job;
  const attributesList = jobAttributes;

  return (
    <FirstViewBodyException>
      {attributesList.map((attribute, index) => {
        const priceMessage =
          // @ts-ignore
          errors?.jobs?.[jobIndex]?.attributes?.[index]?.attributePrice
            ?.message;

        return (
          <StyledWrapperDynamically key={attribute.id}>
            {attribute.red ? (
              <RedLabel>
                {attribute.attributeCategoryName}
                :
              </RedLabel>
            ) : (
              <label>
                {attribute.attributeCategoryName}
                :
              </label>
            )}
            <Input
              orderDesignStyle
              {...register(`${keyByIndex}.attributes[${index}].attributeName`)}
              label=""
              placeholder="Enter"
            />
            <Box sx={{ gap: 1 }}>
              <p>$</p>
              <Input
                orderDesignStyle
                {...register(
                  `${keyByIndex}.attributes[${index}].attributePrice`,
                )}
                label=""
                placeholder="Enter"
                onlyBorder
                errorMessage={priceMessage}
              />
            </Box>
          </StyledWrapperDynamically>
        );
      })}
    </FirstViewBodyException>
  );
};

export default EditableAttributes;
