import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const ChartsContainer = styled(Box)(() => ({
  marginBottom: '24px',
}));

export const FiltersWrapper = styled(Box)(() => ({
  gap: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginBottom: '41px',

  div: {
    marginBottom: 0,
    width: 'max-content',

    h2: {
      marginRight: '12px',
    },
  },

}));

export const Item = styled(Box)<{active: boolean}>(({ active }) => ({
  padding: '7px 16px',
  cursor: 'pointer',
  maxHeight: '38px',
  border: active ? '2px solid #1D73E3' : '1px solid #CFD9E0',
}));
