import { memo, useState } from "react";

import { useFormContext } from "react-hook-form";
import RowComponent from "@containers/common/Table/components/RowComponent";
import Select from "@containers/common/Select";
import { selectAttributeCategories } from "@features/attributeCategories/selectors";
import { useAppSelector } from "@features/app/hooks";
import { getOptionsArray } from "@utils/helpers";
import { IAttribute } from "@features/customEstimates/types";
import Input from "@containers/common/Input";

import { InternalNotesSection } from "./styled";

const Attributes = () => {
  const { subAttributeCategories } = useAppSelector(selectAttributeCategories);
  const {
    watch,
    formState: { errors },
    register,
  } = useFormContext();

  const { attributes } = watch();

  return attributes.map(
    (
      { id: attributeId, attributeCategory: { id, name } }: IAttribute,
      idx: number,
    ) => {
      const attributeCategoriesList = subAttributeCategories?.find(
        (item) => item.id === id,
      );

      return (
        attributeCategoriesList && (
          <RowComponent key={attributeId} label={name}>
            <InternalNotesSection>
              <Input
                placeholder="Add internal notes"
                {...register(`attributes[${idx}].comment`)}
              />
            </InternalNotesSection>
          </RowComponent>
        )
      );
    },
  );
};

export default memo(Attributes);
