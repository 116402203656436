import { memo } from 'react';

// import { Link } from 'react-router-dom';
// import informationCircle from '@/assets/icons/informationCircle.svg';

import NotUploadedContent from './components/NotUploadedContent';
import UploadedContent from './components/UploadedContent';
import {
  DragAndDropContainer,
  StyledDescription,
  // StyledLi,
  // StyledOl,
  SubTitle,
  TitleWrapper,
  UploadContainer,
} from './styled';

export interface ProcessState {
  message: string;
  isLoading: boolean;
  type: CertificateEnumType;
}

export enum CertificateEnumType {
  frontPrint = 'frontPrint',
  backPrint = 'backPrint',
}

interface UploadBoxProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  uploaded: any;
  desc: string;
  title: string;
  mobileButton?: string;
  process: ProcessState;
  keyType: CertificateEnumType;
  onDrop: (...rest: any) => {}; // onDropType;
  onChange: (...rest: any) => {}; // onChangeType;
}

const UploadBox = ({
  title,
  desc,
  keyType,
  process,
  uploaded,
  mobileButton = '',
  onDrop,
  onChange,
}: UploadBoxProps) => {
  return (
    <UploadContainer>
      <TitleWrapper>
        <SubTitle>{title}</SubTitle>
      </TitleWrapper>
      <StyledDescription>{desc}</StyledDescription>
      <DragAndDropContainer>
        {uploaded ? (
          <UploadedContent
            file={uploaded}
            process={process}
            keyType={keyType}
            onDrop={onDrop}
            onChange={onChange}
          />
        ) : (
          <NotUploadedContent
            process={process}
            keyType={keyType}
            onDrop={onDrop}
            onChange={onChange}
            mobileButton={mobileButton}
          />
        )}
      </DragAndDropContainer>
      {keyType === process.type && process.message ? (
        <div>{process?.message || ''}</div>
      ) : null}
    </UploadContainer>
  );
};

export default memo(UploadBox);
