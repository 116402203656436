export const switcherCollection = [
  {
    title: 'Open',
    key: "open",
  },
  {
    title: 'Closed',
    key: "closed",
  },
];
