import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import { UseFormSetValue } from 'react-hook-form';

import { TextareaEditorSection } from './styled';
import ErrorMessage from '../ErrorMessage';
import { myColors } from './data';

interface TextareaEditorProps {
  name: string;
  value: string;
  placeholder?: string;
  errorMessage?: string;
  onChange?: () => void;
  setValue: UseFormSetValue<any>;
}

const TextareaEditor = ({
  value,
  errorMessage,
  placeholder,
  name,
  ...restProps
}: TextareaEditorProps) => {
  const newValue = value;

  const modules = {
    toolbar: [
      [{ header: [1, 3] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      // [{ align: ['right', 'center', 'justify'] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
      [{ color: myColors }],
      [{ background: myColors }],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'color',
    'image',
    'background',
    'align',
  ];

  return (
    <div>
      <TextareaEditorSection>
        <ReactQuill
          modules={modules}
          value={newValue}
          placeholder={placeholder}
          formats={formats}
          onChange={(event) => {
          const isEmpty = event.replace(/(<([^>]+)>)/gi, '');

          restProps.setValue(name, !isEmpty.length ? "" : event);
        }}
        />
      </TextareaEditorSection>
      <ErrorMessage message={errorMessage} />
    </div>
  );
};

export default TextareaEditor;
