import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import SubmitBtn from "@containers/common/Table/components/SubmitBtn";
import { Box } from "@mui/material";

export const StyledContent = styled(Stack)(({ theme }) => ({
  marginLeft: "-230px",
  width: "calc(100vw - 284px)",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    marginLeft: 0,
  },

  th: {
    "&.MuiTableCell-head": {
      padding: "10px",
    },
    ".MuiTypography-root": {
      fontSize: "12px",
    },
  },

  ".MuiFormControlLabel-root": {
    marginRight: "8px",
  },
}));

export const StyledSubmitBtn = styled(SubmitBtn)(({ theme }) => ({
  width: "280px",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}));

export const SubTitleSection = styled(Box)({
  width: "100%",

  span: {
    width: "100%",
  },
});
