import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

export const GrayBackground = styled(Box)(() => ({
  padding: "8px 8px",
  marginTop: "5px",
  background: "#FAFAFA",
  height: "100%",
}));

export const Wrapper = styled(Box)(() => ({
  display: "grid",
  gap: "20px",
  alignItems: "center",
  justifyContent: "flex-end",
  gridTemplateColumns: "max-content 100px",
}));

export const EditableSection = styled(Box)(() => ({
  display: "flex",
  gap: "3px",
  alignItems: "anchor-center",
}));

export const Text = styled(Typography)(() => ({
  color: "#06152B",
  fontFamily: "DM Sans",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "120%",
}));
