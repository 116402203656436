import { createRequiredTextWidthValidation, createTextWidthValidation } from '@utils/schemas';
import { InputTypes, ValidFieldNames } from '@utils/globalTypes';
import * as yup from 'yup';
import { URL_REGEXP } from '@utils/regexp';

export interface IAddDataForm {
  title: string;
  buttonLink: string;
  buttonText: string;
  textTemplate: string;
  enableButton: boolean;
}

export const defaultValues = {
  title: '',
  textTemplate: '',
  enableButton: false,
  buttonLink: '',
  buttonText: '',
};

export const AddDataSchema = yup.object().shape({
  title: createRequiredTextWidthValidation('Name', 255),
  textTemplate: createTextWidthValidation(10000),
  enableButton: yup.boolean().required(),
  buttonLink: yup.string()
    .when('enableButton', (enableButton: any, schema: yup.StringSchema) => (
      enableButton[0]
        ? schema.matches(URL_REGEXP, 'Enter correct url!').required('Button Text is Required.')
        : schema.optional()))
    .nullable(),
  buttonText: yup.string()
    .when('enableButton', (enableButton: any, schema: yup.StringSchema) => (
      enableButton[0]
        ? createRequiredTextWidthValidation('Button Text', 255)
        : schema.optional()))
    .nullable(),
});

export const inputsRows: ValidFieldNames[] = [
  {
    label: 'Title / Email Subject',
    field: 'title',
    type: InputTypes.text,
    isRequired: true,
  },
  {
    label: 'Text Template',
    field: 'textTemplate',
    type: InputTypes.editor,
    textareaprops: {
      vtResize: true,
      height: 'auto',
      placeholder: 'Text Template',
    },
  },
  {
    label: 'Enable button',
    field: 'enableButton',
    type: InputTypes.checkbox,
  },
  {
    label: 'Button link',
    field: 'buttonLink',
    type: InputTypes.text,
    textareaprops: {
      placeholder: 'Text Template',
    },
  },
  {
    label: 'Button text',
    field: 'buttonText',
    type: InputTypes.text,
    textareaprops: {
      placeholder: 'Text Template',
    },
  },
];
