import { useEffect, useState } from 'react';

import Button from '@containers/common/Button';
import { Box, Modal, Typography } from '@mui/material';
import { getOrderEmailsHistory } from '@features/emailTemplate/actions';
import { useAppDispatch } from '@features/app/hooks';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { DATE_FORMAT } from '@utils/helpers';
import { IEmailTemplate } from '@features/emailTemplate/types';

import {
  EmailsContainer,
  HeaderSection,
  ListWrapper,
  Item,
  StyledTypography,
  Description,
  PageTitle,
} from './styled';
import SendEmailDialog from './SendEmailDialog';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 520,
  background: 'white',
  p: 4,
};

const Emails = () => {
  const { orderId } = useParams();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [list, setList] = useState<IEmailTemplate[] | []>([]);

  const getEmailHistory = async (id: string) => {
    try {
      const response = await dispatch(getOrderEmailsHistory({ id }))
        .unwrap();

      setList(response);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!orderId) {
      return;
    }

    getEmailHistory(orderId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId, open]);

  return (
    <EmailsContainer>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <SendEmailDialog handleClose={handleClose} />
        </Box>
      </Modal>

      <HeaderSection>
        <PageTitle variant="h3">
          Email history
        </PageTitle>
        <Button
          onClick={handleOpen}
        >
          Send new email
        </Button>
      </HeaderSection>
      <ListWrapper>
        {list?.map((item) => {
          return (
            <Item key={item.id}>
              <StyledTypography>
                Sent
                {' '}

                {moment(item?.created).format(`${DATE_FORMAT}, hh:mm A`)}
              </StyledTypography>
              <Description dangerouslySetInnerHTML={{ __html: item?.textTemplate }} />
            </Item>
          );
        })}
      </ListWrapper>
    </EmailsContainer>
  );
};

export default Emails;
