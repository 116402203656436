import { memo, useCallback, useEffect } from 'react';

import moment from 'moment';
import StyledTable from '@containers/common/Table';
import { StyledTableRow } from '@containers/common/Table/styled';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import Loader from '@containers/common/Loader';
import PageTitle from '@containers/common/PageTitle';
import EmptyState from '@containers/common/EmptyState';
import TCell from '@containers/common/Table/components/TCell';
import { getAllAdminLogs } from '@features/adminLogIn/actions';
import { selectAdminLogs } from '@features/adminLogIn/selectors';
import Pagination from '@containers/common/Table/components/Pagination';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { paginationLimit } from '@utils/constants';
import { DATE_FORMAT } from '@utils/helpers';

import { IFiltersForm, headCells } from './helpers';

const AdminLogIn = () => {
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const params = queryString.parse(search);
  const { limit = paginationLimit, offset = 0 } = params as IFiltersForm;
  const { data, isLoading, total } = useAppSelector(selectAdminLogs);

  const fetchData = useCallback(() => {
    const query = { limit, offset };

    dispatch(getAllAdminLogs(query));
  }, [limit, offset, dispatch]);

  useEffect(
    () => {
      fetchData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [limit, offset],
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <PageTitle title={`Login Log (${total})`} isShowBtn={false} />
      {data?.length ? (
        <>
          <StyledTable headCells={headCells}>
            {data.map(({ id, created, user }) => (
              <StyledTableRow key={id}>
                <TCell text={moment(created).format(`${DATE_FORMAT}, hh:mm A`)} />
                <TCell text={`${user.firstName} ${user.lastName} logged in.`} />
              </StyledTableRow>
            ))}
          </StyledTable>
          { total > paginationLimit && <Pagination total={total} path="ADMIN_LOG_IN" />}
        </>
      ) : (
        <EmptyState text="You don’t have any logs" />
      )}
    </>
  );
};

export default memo(AdminLogIn);
