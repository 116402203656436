import { memo } from "react";

import imagePlaceholder from "@assets/images/image-placeholder.png";
import { allowedUploadableFormatForArtwork, imgExtensions } from "@utils/imageService";
import { getCDNImagePath } from "@utils/helpers";
import { Box } from "@mui/material";
import ImagePreview from "@containers/Orders/Sets/components/ImagePreview";

import {
  FileInputLabel,
  StyledImageWrapper,
  StyledLine,
  StyledWrongFile,
  SuccessfullyUploaded,
  UploadedContentStyled,
  IconContainer,
  IconText,
} from "./styled";
import { CertificateEnumType, ProcessState } from "../..";

interface UploadedContentProps {
  file: string;
  keyType: CertificateEnumType;
  process: ProcessState;
  onDrop: (...rest: any) => {}; // onDropType;
  onChange: (...rest: any) => {}; // onChangeType;
}

const UploadedContent = ({
  file,
  keyType,
  process,
  onDrop,
  onChange,
}: UploadedContentProps) => {
  const acceptedExtension = imgExtensions;

  const loadingProcess = process.isLoading && process.type === keyType;
  const fileExtension = String(file.split(".").pop());
  const isImage = allowedUploadableFormatForArtwork.test(file.toLowerCase());

  const getImageUrl = () => {
    if (isImage) {
      return getCDNImagePath(file);
    }

    return imagePlaceholder;
  };

  const fileIconName = () => {
    if (isImage) {
      return null;
    }

    return `.${fileExtension.toLocaleLowerCase()}`;
  };

  return (
    <UploadedContentStyled>
      <StyledImageWrapper>
        {loadingProcess ? (
          <span>Loading...</span>
        ) : (
          <Box
            sx={{ height: "100%", position: "relative" }}
            onDrop={(d) => onDrop(d, keyType)}
            onDragOver={(e) => e.preventDefault()}
          >
            <input
              type="file"
              accept={acceptedExtension.join(", ")}
              style={{ display: "none" }}
              disabled={!!loadingProcess}
            />
            <ImagePreview width={155} height={155} url={getImageUrl()} />
            {fileIconName() ? (
              <IconContainer>
                <IconText>{fileIconName()}</IconText>
              </IconContainer>
            ) : null}
          </Box>
        )}
      </StyledImageWrapper>
      <div>
        <SuccessfullyUploaded>
          {loadingProcess ? "..." : "Your file has been successfully uploaded!"}
        </SuccessfullyUploaded>
        <StyledLine />
        <input
          type="file"
          id={keyType}
          accept={acceptedExtension.join(", ")}
          style={{ display: "none" }}
          onChange={(e) => onChange(e, keyType)}
        />
        <StyledWrongFile>
          <p>Wrong file? </p>
          <FileInputLabel htmlFor={keyType}> Upload new file</FileInputLabel>
        </StyledWrongFile>
      </div>
    </UploadedContentStyled>
  );
};

export default memo(UploadedContent);
