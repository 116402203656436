import { memo, useEffect, useState } from "react";

import Select from "@containers/common/Select";
import { useFormContext } from "react-hook-form";
import { Job } from "@features/orders/order/types";
import Textarea from "@containers/common/Textarea";
import { Box } from "@mui/material";
import Input from "@containers/common/Input";
import { UnderlinedLink } from "@containers/common/Underlined/styles";
import { getCDNImagePath } from "@utils/helpers";
import FileUploader from "@containers/common/FileUploader";
import Button from "@containers/common/Button";
import { useAppDispatch } from "@features/app/hooks";
import {
  cencelOrderFrom4over,
  createOrderFor4over,
  exportEddmRoutes,
  update4overPrintFIles,
} from "@features/orders/order/actions";
import GlobalUpdatesMessage, {
  GlobalUpdatesMessageProps,
} from "@containers/common/GlobalUpdatesMessage";
import { ShipmentStatusesEnum } from "@features/orders/order/enums";
import Modal from "@containers/Orders/Order/components/common/Modal";
import StyledTypography from "@containers/common/StyledTypography";

import { IVendorOptions } from "../../../utils/types";
import {
  JobStatusAndVendorWrapper,
  Container,
  JobStatusWrapper,
  VendorLineWrapper,
  StyledText,
  NotWrapper,
  SpecificCase,
  MailingSection,
  EddActionsSection,
  MessageSection,
  ModalTitle,
  ModalButtonSection,
} from "./styled";
import { ProofStatusEnum } from "../../../utils/enums";

interface JobRightSectionProps {
  job: Job;
  keyByIndex: string;
  vendorsList: IVendorOptions[];
}

const JobRightSection = ({
  job,
  keyByIndex,
  vendorsList,
}: JobRightSectionProps) => {
  const { isMailing, isEDDM } = job;
  const { watch, register, setValue } = useFormContext();
  const [disableButtons, setDisableButtons] = useState(false);
  const [disable4Over, setDisable4Over] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isSuccessMessage, setIsSuccessMessage] =
    useState<GlobalUpdatesMessageProps>({ msg: "", status: null });

  const dispatch = useAppDispatch();

  const yesNoOptions = [
    {
      optionName: "Yes",
      value: true,
    },
    {
      optionName: "No",
      value: false,
    },
  ];

  const handleOrderTo4over = () => {
    setDisableButtons(true);
    dispatch(createOrderFor4over({ jobId: job.id }))
      .unwrap()
      .then((result) => {
        console.log(result);
        // setIsSuccessMessage({ msg: "Order created", status: 200 });
        window.location.reload();
      })
      .catch((e) => {
        console.log(e?.message);
        setIsSuccessMessage({ msg: e?.message, status: 500 });
        setDisableButtons(false);
      });
  };

  const handleUpdatePrintFiles = () => {
    setDisableButtons(true);
    dispatch(update4overPrintFIles({ jobId: job.id }))
      .unwrap()
      .then((result) => {
        console.log(result);
        setIsSuccessMessage({
          msg: "Print files has been updated",
          status: 200,
        });
        // window.location.reload();
      })
      .catch((e) => {
        console.log(e?.message);
        setIsSuccessMessage({ msg: e?.message, status: 500 });
        setDisableButtons(false);
      });
  };

  const handleCancelOrderFrom4over = () => {
    setDisableButtons(true);
    dispatch(cencelOrderFrom4over({ jobId: vendor1Value }))
      .unwrap()
      .then((result) => {
        console.log(result);
        // setIsSuccessMessage({ msg: "Order has been canceled", status: 200 });
        window.location.reload();
      })
      .catch((e) => {
        console.log(e?.message);
        setIsSuccessMessage({ msg: e?.message, status: 500 });
        setDisableButtons(false);
      });
  };

  const handleCancel = () => {
    setDeleteModal(true);
  };

  const handleExportRoutes = () => {
    dispatch(exportEddmRoutes({ jobId: job.id }))
      .unwrap()
      .then((data) => {
        const blob = new Blob([data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");

        a.href = url;
        a.download = "routes.csv";
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch((e) => {
        console.log(e?.message);
      });
  };

  const mailingFile = watch(`${keyByIndex}.mailingFile`);
  const postageFromVendor = watch(`${keyByIndex}.postageFromVendor`);
  const customerPostage = watch(`${keyByIndex}.customerPostage`);
  const postageToVendor = watch(`${keyByIndex}.postageToVendor`);
  const frontPrintStatus = watch(`${keyByIndex}.frontPrint`);
  const backArtworkState = job?.shipments?.[0].sets?.[0]?.backArtworkState;
  const printsWatcher = watch(`${keyByIndex}.shipments[0].sets[0]`);
  const frPrintState = watch(`${keyByIndex}.shipments[0].sets[0].frontPrint`);
  const bkPrintState = watch(`${keyByIndex}.shipments[0].sets[0].backPrint`);
  const vendor1Value =
    watch(`${keyByIndex}.vendorJob`) === "isEditable"
      ? ""
      : watch(`${keyByIndex}.vendorJob`);

  useEffect(() => {
    const defSet = job?.shipments?.[0].sets?.[0];

    const issetPrints = defSet
      ? backArtworkState
        ? printsWatcher.frontPrint &&
          printsWatcher.frontPrint.length &&
          printsWatcher.backPrint &&
          printsWatcher.backPrint.length &&
          defSet.frontProof &&
          defSet.frontProof.length &&
          defSet.frontProofStatus === ProofStatusEnum.ACCEPTED &&
          defSet.backProof &&
          defSet.backProof.length &&
          defSet.backProofStatus === ProofStatusEnum.ACCEPTED
        : !backArtworkState &&
          printsWatcher.frontPrint &&
          printsWatcher.frontPrint.length &&
          defSet.frontProof &&
          defSet.frontProof.length &&
          defSet.frontProofStatus === ProofStatusEnum.ACCEPTED
      : false;

    const disableSendTo4over = vendor1Value || disableButtons || !issetPrints;

    setDisable4Over(disableSendTo4over);
    // eslint-disable-next-line react-hooks/exhaustive-deps, react-hooks/exhaustive-deps
  }, [
    job,
    vendor1Value,
    disableButtons,
    frontPrintStatus,
    backArtworkState,
    printsWatcher,
    frPrintState,
    bkPrintState,
  ]);

  return (
    <Container>
      <JobStatusAndVendorWrapper>
        <JobStatusWrapper width={isMailing ? "140px" : "100px"}>
          <VendorLineWrapper className="vendors">
            <Box>
              <StyledText>Vendor 1:</StyledText>
            </Box>
            <Box>
              <Select
                className="vendor-selector"
                name={`${keyByIndex}.vendorId`}
                options={vendorsList}
                orderDesignStyle
                value={watch(`${keyByIndex}.vendorId`)}
                onChange={(e) => {
                  setValue(`${keyByIndex}.vendorId`, e.target.value);

                  const used = vendorsList.find(
                    (item) => item.value === e.target.value,
                  );

                  if (used) {
                    if (
                      used.carrierUsed.toUpperCase()
                      === ShipmentStatusesEnum.UPS
                    ) {
                      job.shipments.forEach((shipment, index) => {
                        console.log(
                          `${keyByIndex}.shipments[${index}].trackingCarrier`,
                        );
                        setValue(
                          `${keyByIndex}.shipments[${index}].trackingCarrier`,
                          ShipmentStatusesEnum.UPS,
                        );
                      });
                    } else if (
                      used.carrierUsed.toUpperCase()
                      === ShipmentStatusesEnum.FEDEX
                    ) {
                      job.shipments.forEach((shipment, index) => {
                        console.log(
                          `${keyByIndex}.shipments[${index}].trackingCarrier`,
                        );
                        setValue(
                          `${keyByIndex}.shipments[${index}].trackingCarrier`,
                          ShipmentStatusesEnum.FEDEX,
                        );
                      });
                    }
                  }

                  return true;
                }}
              />
            </Box>
            <SpecificCase>
              <StyledText>Vendor PO:</StyledText>
              <Input
                orderDesignStyle
                placeholder="Enter"
                value={vendor1Value}
                {...register(`${keyByIndex}.vendorJob`)}
              />
            </SpecificCase>
            <SpecificCase>
              <StyledText>Vendor Cost:</StyledText>
              <Input
                orderDesignStyle
                placeholder="Enter"
                value={watch(`${keyByIndex}.vendorCost`)}
                {...register(`${keyByIndex}.vendorCost`)}
              />
            </SpecificCase>
          </VendorLineWrapper>
          <VendorLineWrapper className="vendors">
            <Box>
              <StyledText>Vendor 2:</StyledText>
            </Box>
            <Box>
              <Select
                className="vendor-selector"
                name={`${keyByIndex}.vendor2Id`}
                options={vendorsList}
                orderDesignStyle
                value={watch(`${keyByIndex}.vendor2Id`)}
              />
            </Box>
            <SpecificCase>
              <StyledText>Vendor PO:</StyledText>
              <Input
                orderDesignStyle
                placeholder="Enter"
                value={watch(`${keyByIndex}.vendorJob2`)}
                {...register(`${keyByIndex}.vendorJob2`)}
              />
            </SpecificCase>
            <SpecificCase>
              <StyledText>Vendor Cost:</StyledText>
              <Input
                orderDesignStyle
                placeholder="Enter"
                value={watch(`${keyByIndex}.vendorCost2`)}
                {...register(`${keyByIndex}.vendorCost2`)}
              />
            </SpecificCase>
          </VendorLineWrapper>
          <VendorLineWrapper className="vendors">
            <Box>
              <StyledText>Vendor 3:</StyledText>
            </Box>
            <Box>
              <Select
                className="vendor-selector"
                name={`${keyByIndex}.vendor3Id`}
                options={vendorsList}
                orderDesignStyle
                value={watch(`${keyByIndex}.vendor3Id`)}
              />
            </Box>
            <SpecificCase>
              <StyledText>Vendor PO:</StyledText>
              <Input
                orderDesignStyle
                placeholder="Enter"
                value={watch(`${keyByIndex}.vendorJob3`)}
                {...register(`${keyByIndex}.vendorJob3`)}
              />
            </SpecificCase>
            <SpecificCase>
              <StyledText>Vendor Cost:</StyledText>
              <Input
                orderDesignStyle
                placeholder="Enter"
                value={watch(`${keyByIndex}.vendorCost3`)}
                {...register(`${keyByIndex}.vendorCost3`)}
              />
            </SpecificCase>
          </VendorLineWrapper>
          {isEDDM && (
            <>
              <EddActionsSection>
                <StyledText>4over Actions:</StyledText>
                <div>
                  {/* {vendor1Value ? (
                    <Button
                      sx={{ maxWidth: "180px" }}
                      onClick={() => handleUpdatePrintFiles()}
                    >
                      Update Print files
                    </Button>
                  ) : (
                    <Button
                      disabled={disable4Over}
                      sx={{ maxWidth: "180px" }}
                      onClick={() => handleOrderTo4over()}
                    >
                      Send Order to 4over
                    </Button>
                  )} */}
                  <Button
                    disabled={disable4Over}
                    sx={{ maxWidth: "180px" }}
                    onClick={() => handleOrderTo4over()}
                  >
                    Send Order to 4over
                  </Button>
                  <Button
                    disabled={!job.eddmRoutes}
                    onClick={() => handleExportRoutes()}
                    sx={{
                      maxWidth: "120px",
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                  >
                    Export Routes
                  </Button>
                  <Button
                    disabled={!vendor1Value || disableButtons}
                    onClick={() => handleCancel()}
                    sx={{
                      maxWidth: "104px",
                      background: "transparent",
                      color: "#FB3838",
                      borderColor: "#FB3838",
                      marginLeft: "10px",
                    }}
                  >
                    Cancel Order
                  </Button>
                </div>
              </EddActionsSection>
              {isSuccessMessage?.status && (
                <EddActionsSection>
                  <StyledText />
                  <MessageSection>
                    <GlobalUpdatesMessage {...isSuccessMessage} />
                  </MessageSection>
                </EddActionsSection>
              )}
            </>
          )}
          {isMailing && (
            <MailingSection>
              <Box>
                <StyledText>Received postage amount from vendor:</StyledText>
                <Select
                  withoutSelect
                  orderDesignStyle
                  defaultValue={{
                    optionName: postageFromVendor ? "Yes" : "No",
                    value: postageFromVendor,
                  }}
                  name={`${keyByIndex}.postageFromVendor`}
                  options={yesNoOptions}
                />
              </Box>
              <Box>
                <StyledText>Customer paid postage:</StyledText>
                <Select
                  withoutSelect
                  orderDesignStyle
                  defaultValue={{
                    optionName: customerPostage ? "Yes" : "No",
                    value: customerPostage,
                  }}
                  name={`${keyByIndex}.customerPostage`}
                  options={yesNoOptions}
                />
              </Box>
              <Box>
                <StyledText>Postage paid to vendor:</StyledText>
                <Select
                  withoutSelect
                  orderDesignStyle
                  defaultValue={{
                    optionName: postageToVendor ? "Yes" : "No",
                    value: postageToVendor,
                  }}
                  name={`${keyByIndex}.postageToVendor`}
                  options={yesNoOptions}
                />
              </Box>
              <Box>
                <StyledText>Mail PO:</StyledText>
                <Input
                  orderDesignStyle
                  {...register(`${keyByIndex}.mailingPO`)}
                  placeholder="Enter"
                />
              </Box>
              <Box>
                <StyledText>Mailing List:</StyledText>
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    button: {
                      width: "100px",
                    },
                  }}
                >
                  {mailingFile && (
                    <UnderlinedLink
                      to={getCDNImagePath(mailingFile)}
                      width="120px"
                      target="_blank"
                    >
                      {mailingFile}
                    </UnderlinedLink>
                  )}
                  <FileUploader
                    outOfFormSystem="mailingFile"
                    name={`${keyByIndex}.mailingFile`}
                    errorMessage=""
                    fileExtensions={[".csv", ".xls", ".xlsx"]}
                    btnProps={{
                      maxWidth: "80px",
                      btnText: "Choose File",
                      isOutlined: true,
                    }}
                  />
                </Box>
              </Box>
            </MailingSection>
          )}

          <NotWrapper width={isMailing ? "140px" : "100px"}>
            <StyledText>Internal Job Notes:</StyledText>
            <Textarea
              vtResize
              {...register(`${keyByIndex}.notes`)}
              placeholder="Enter"
            />
          </NotWrapper>
        </JobStatusWrapper>
      </JobStatusAndVendorWrapper>
      <Modal
        open={deleteModal}
        sx={{ padding: "16px 24px", maxHeight: "655px" }}
        title=""
        handleClose={() => {
          setDeleteModal(false);
        }}
      >
        <ModalTitle>Are you sure you want to cancel order?</ModalTitle>
        <ModalButtonSection>
          <Button
            isOutlined
            onClick={() => {
              setDeleteModal(false);
            }}
          >
            No
          </Button>
          <Button
            onClick={() => {
              handleCancelOrderFrom4over();
            }}
          >
            Yes
          </Button>
        </ModalButtonSection>
      </Modal>
    </Container>
  );
};

export default memo(JobRightSection);
