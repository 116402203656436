export const headCells = [
  {
    label: 'Task title',
  },
  {
    label: 'Status',
  },
  {
    label: 'Priority',
  },
  {
    label: 'Date Created',
  },
  {
    label: 'Delivery Date',
  },
  {
    label: 'Date Completed',
  },
  {
    label: 'Created by',
  },
  {
    label: 'ACTIONS',
    colSpan: 1,
  },
];

export const switcherCollection = [
  {
    title: 'Open',
  },
  {
    title: 'Closed',
  },
];
