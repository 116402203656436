import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import { FontFamilyNames } from "@customTypes/global/theme/fonts";
import { Diversity1 } from "@mui/icons-material";

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  boxShadow: "none",
  border: `1px solid ${theme.palette.grey[500]}`,
  borderRadius: 0,
  overflowX: "auto",
  width: "100%",
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  th: {
    background: theme.palette.grey[700],
    fontWeight: 500,
    fontFamily: FontFamilyNames.DmSansMedium,
    padding: "5px 8px",
    lineHeight: "normal",

    ".MuiTypography-body1": {
      textTransform: "uppercase",
    },

    ".MuiTypography-root": {
      fontWeight: 500,
      fontFamily: FontFamilyNames.DmSansMedium,
    },
  },
  tr: {
    display: 'block',
  },
  td: {
    padding: "5px 8px",
  },
  "td, th": {
    borderRight: `1px solid ${theme.palette.grey[500]}`,
    fontSize: "12px",
  },
  "th:last-child, td:last-child": {
    borderRight: 0,
  },

  "&:last-child td": {
    borderBottom: 0,
  },
}));

export const StyledMuiTable = styled(Table)(() => ({
  boxShadow: "none",
  minWidth: "432px",
}));

export const TableCellStyle = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));
