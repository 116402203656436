import { useEffect, useState } from 'react';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import useResponsive from '@customHooks/useResponsive';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { selectSidebarCounts } from '@features/sidebarCounts/selectors';
import Loader from '@containers/common/Loader';
import { getEstimatesCount, getRequestsCount } from '@features/sidebarCounts/actions';
import PAGE_ROUTES from '@routes/routingEnum';
import { getAllMyTasks } from '@features/customers/tasks/actions';

import Sidebar from './Sidebar';
import { StyledMain, StyledRoot } from './styled';
import HeaderSection from './Header';

const Layout = () => {
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const isMobile = useResponsive('down', 'lg');
  const { isLoading } = useAppSelector(selectSidebarCounts);
  const navigate = useNavigate();

  const handleRequest = async () => {
    const checking = sessionStorage.getItem('oneMoveMyTasks');

    if (!checking) {
      const { showMyTasks } = await dispatch(getAllMyTasks({})).unwrap();

      if (showMyTasks) {
        sessionStorage.setItem('oneMoveMyTasks', "done");
        if (pathname.includes('/products/products') || pathname.includes('/dashboard')) {
          navigate(PAGE_ROUTES.MY_TASKS);
        }
      } else {
        sessionStorage.setItem('oneMoveMyTasks', "done");
        navigate(PAGE_ROUTES.DASHBOARD);
      }
    }
  };

  useEffect(() => {
    handleRequest();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

 useEffect(() => {
    // every refresh
    // show live counts data in sidebar
    dispatch(getAllMyTasks({})).unwrap();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // show live counts data in sidebar
    dispatch(getRequestsCount());
    dispatch(getEstimatesCount());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <StyledRoot>
      {isMobile && <HeaderSection onOpenNav={() => setOpen(true)} />}
      <Sidebar open={!isMobile || open} onCloseNav={() => setOpen(false)} />
      <StyledMain>
        <Outlet />
      </StyledMain>
    </StyledRoot>
  );
};

export default Layout;
