import { createSlice } from "@reduxjs/toolkit";
import PAGE_ROUTES from "@routes/routingEnum";

import { IState } from "./types";
import { getEstimatesCount, getRequestsCount } from "./actions";

const initialState: IState = {
  isLoading: true,
  [PAGE_ROUTES.REQUESTS]: null,
  [PAGE_ROUTES.OPEN_ESTIMATES]: null,
  [PAGE_ROUTES.COMPLETE_ESTIMATES]: null,
  [PAGE_ROUTES.INACTIVE_ESTIMATES]: null,
};

const sidebarCountsSlice = createSlice({
  name: "sidebarCounts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRequestsCount.pending, (state) => {});
    builder.addCase(getRequestsCount.fulfilled, (state, { payload }) => {
      const { nonProfit, reseller } = payload;

      state[PAGE_ROUTES.REQUESTS] = nonProfit + reseller;
      state.isLoading = false;
    });
    builder.addCase(getRequestsCount.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getEstimatesCount.pending, (state) => {});
    builder.addCase(getEstimatesCount.fulfilled, (state, { payload }) => {
      const {
        openEstimates,
        completeEstimates,
        inactiveEstimates,
        openOrders,
        cancelledOrders,
        complatedOrders,
        openOrderInvoices,
        complatedOrderInvoices,
        developmentTasks,
        tasks,
      } = payload;

      state[PAGE_ROUTES.OPEN_ESTIMATES] = openEstimates;
      state[PAGE_ROUTES.COMPLETE_ESTIMATES] = completeEstimates;
      state[PAGE_ROUTES.INACTIVE_ESTIMATES] = inactiveEstimates;
      state[PAGE_ROUTES.OPEN_ORDERS] = openOrders;
      state[PAGE_ROUTES.CANCELLED_ORDERS] = cancelledOrders;
      state[PAGE_ROUTES.COMPLETED_ORDERS] = complatedOrders;
      state[PAGE_ROUTES.OPEN_ORDERS_INVOICES] = openOrderInvoices;
      state[PAGE_ROUTES.COMPLETED_ORDER_INVOICES] = complatedOrderInvoices;
      state[PAGE_ROUTES.TASKS] = tasks;
      state[PAGE_ROUTES.DEVELOPERS_TASKS] = developmentTasks;
      state.isLoading = false;
    });
    builder.addCase(getEstimatesCount.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default sidebarCountsSlice.reducer;
