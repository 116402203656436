export const headCells = [
  {
    label: "",
    checkbox: "actual",
  },
  {
    label: "ID",
    width: '70px',
  },
  {
    label: "Job Name",
  },
  {
    label: "DATE",
  },
  {
    label: "BILL TO",
    width: '120px',
  },
  {
    label: "STATUS",
  },
  {
    label: "VENDOR JOB #",
  },
  {
    label: "VENDOR",
    width: '150px',
  },
  {
    label: "VENDOR COST",
  },
  {
    label: "TRACKING #",
  },
  {
    label: "MAIL PO",
    width: '110px',
  },
];
