import { http } from "@services/RequestService";
import { AWS_S3_PREFIX } from "@utils/constants";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import { getMimeType } from "./helpers";

export interface IUploadFileProps {
  file: FileStateType;
  url: string;
}

export interface ICreateAWSLinkResponse {
  putUrl: string;
  path: string;
}
interface IFileListPayload {
  extension: string;
  name: string;
}

export type FileStateType = FileList | null;

export const uploadFile = async (
  file: FileList,
  fileList: ICreateAWSLinkResponse[],
) => {
  const uploadPromises = fileList.map(async (item, i) => {
    const mimeType = getMimeType(file[i].name);
    const options: AxiosRequestConfig = {
      headers: { "Content-Type": mimeType },
      withCredentials: false,
    };

    await axios.put<IUploadFileProps["file"], boolean>(
      item.putUrl,
      file[i],
      options,
    );
  });

  await Promise.all(uploadPromises);
};

const getRandomNumber = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min) + min);
};

export const getUploadUrl = async (fileList: FileList, type?: string, fileID?: number) => {
  const fileDetails = Array.from(fileList).map(({ name }) => {
    const idx = name.lastIndexOf(".");
    let fileName = name.substring(0, idx).replace(/[ #+?%\\]/g, "");
    const namePrefix = fileID || getRandomNumber(99, 9999999);

    if (type && type === "backProof") {
      fileName = `${namePrefix}_proof_back`;
    }

    if (type && type === "frontProof") {
      fileName = `${namePrefix}_proof_front`;
    }

    const extension = name.substring(idx + 1);

    return {
      name: fileName,
      extension,
      withoutRename: !!type,
    };
  });

  const {
    data: { data },
  } = await http.post<
    IFileListPayload[],
    AxiosResponse<AxiosResponse<ICreateAWSLinkResponse[]>>
  >(`${AWS_S3_PREFIX}`, fileDetails);

  return { fileList: data };
};
