/* eslint-disable max-len */
import { AxiosResponse } from "axios";
import { http } from "@services/RequestService";
import { AxiosData } from "@utils/globalTypes";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { customErrorHandling } from "@utils/errorHandler";
import { constructQueryString } from "@utils/helpers";

import { IConversationModel, IConversationPayload, IMessage } from "./types";

const prefix = "/conversations";

export const getConversationLastMessage = createAsyncThunk<
  IMessage[],
  { id: string },
  { rejectValue: AxiosData }
>("conversations/last-message", async ({ id }, thunkAPI) => {
  try {
    const {
      data: { data },
    } = await http.get<AxiosResponse<IMessage[]>>(`${prefix}?setId=${id}&limit=1&offset=0`);

    return data;
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});

export const getOnlyConversationSets = createAsyncThunk<
  IConversationModel,
  { id: string; limit: number; offset: number },
  { rejectValue: AxiosData }
>("conversations/sets", async ({ id, limit, offset }, thunkAPI) => {
  try {
    const queryParams = constructQueryString({ limit, offset });

    const {
      data: { data },
    } = await http.get<AxiosResponse<IConversationModel>>(`${prefix}?setId=${id}${queryParams ? `&${queryParams}` : ''}`);

    return data;
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});

export const getConversations = createAsyncThunk<
  IConversationModel,
  { id: string; limit: number; offset: number },
  { rejectValue: AxiosData }
>("conversations/list", async ({ id, limit, offset }, thunkAPI) => {
  try {
    const queryParams = constructQueryString({ limit, offset });

    const {
      data: { data },
    } = await http.get<AxiosResponse<IConversationModel>>(`${prefix}?setId=${id}${queryParams ? `&${queryParams}` : ''}`);

    return data;
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});

export const sendMessage = createAsyncThunk<
  IMessage,
  IConversationPayload,
  { rejectValue: AxiosData}
>('message/send', async (body, thunkAPI) => {
    try {
      const { data: { data } } = await http.post<
      IConversationPayload,
      AxiosResponse<AxiosResponse<IMessage>>>(prefix, body);

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  });
