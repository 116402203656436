import { createSlice } from '@reduxjs/toolkit';

import { IState } from './types';
import {
  addSubcategory,
  addSubcategoryAttributes,
  editSubcategory,
  getAllAttributesBySubcategoryId,
  getAllSubcategories,
  getAllSubcategoriesProducts,
  getDefaultAttributesBySubcategoryId,
  getSubcategoryById,
  reorderSubcategories,
  searchSubcategories,
} from './actions';

const initialState: IState = {
  isLoading: true,
  actionLoading: false,
  errorMessage: null,
  data: null,
  allData: null,
  dataHavingProducts: null,
  attributeIds: null,
  defaultAttributeIds: null,
  attributesLoading: true,
};

const subcategoriesSlice = createSlice({
  name: 'subcategories',
  initialState,
  reducers: {
    setSubcategories: (state, { payload }) => {
      state.data = payload;
    },
    resetSubcategories: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getAllSubcategories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllSubcategories.fulfilled, (state, { payload }) => {
      state.allData = payload;
      state.isLoading = false;
      state.errorMessage = null;
    });
    builder.addCase(getAllSubcategories.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getSubcategoryById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSubcategoryById.fulfilled, (state) => {
      state.isLoading = false;
      state.errorMessage = null;
    });
    builder.addCase(getSubcategoryById.rejected, (state) => {
      state.isLoading = false;
    });

    // in product category page during Edit it makes problem
    // builder.addCase(editSubcategory.pending, (state) => {
    //   state.actionLoading = true;
    // });
    // builder.addCase(editSubcategory.fulfilled, (state) => {
    //   state.actionLoading = false;
    //   state.errorMessage = null;
    // });
    // builder.addCase(editSubcategory.rejected, (state) => {
    //   state.actionLoading = false;
    // });

    builder.addCase(addSubcategory.pending, (state) => {
      state.actionLoading = true;
    });
    builder.addCase(addSubcategory.fulfilled, (state) => {
      state.actionLoading = false;
      state.errorMessage = null;
    });
    builder.addCase(addSubcategory.rejected, (state, { payload }) => {
      state.actionLoading = false;
      state.errorMessage = payload.message;
    });

    builder.addCase(reorderSubcategories.pending, (state) => {
      state.actionLoading = true;
    });
    builder.addCase(reorderSubcategories.fulfilled, (state) => {
      state.actionLoading = false;
      state.errorMessage = null;
    });
    builder.addCase(reorderSubcategories.rejected, (state, { payload }) => {
      state.actionLoading = false;
      state.errorMessage = payload.message;
    });

    builder.addCase(searchSubcategories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(searchSubcategories.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.isLoading = false;
      state.errorMessage = null;
    });
    builder.addCase(searchSubcategories.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errorMessage = payload.message;
    });

    builder.addCase(addSubcategoryAttributes.pending, (state) => {
      state.actionLoading = true;
    });
    builder.addCase(addSubcategoryAttributes.fulfilled, (state) => {
      state.actionLoading = false;
      state.errorMessage = null;
    });
    builder.addCase(addSubcategoryAttributes.rejected, (state) => {
      state.actionLoading = false;
    });

    builder.addCase(getAllSubcategoriesProducts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllSubcategoriesProducts.fulfilled, (state, { payload }) => {
      state.dataHavingProducts = payload;
      state.isLoading = false;
      state.errorMessage = null;
    });
    builder.addCase(getAllSubcategoriesProducts.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getAllAttributesBySubcategoryId.pending, (state) => {
      state.attributesLoading = true;
    });
    builder.addCase(getAllAttributesBySubcategoryId.fulfilled, (state, { payload }) => {
      const { defaultAttributeIds, attributeIds } = payload;

      state.defaultAttributeIds = defaultAttributeIds;
      state.attributeIds = attributeIds;
      state.attributesLoading = false;
      state.errorMessage = null;
    });
    builder.addCase(getAllAttributesBySubcategoryId.rejected, (state) => {
      state.attributesLoading = false;
    });
    builder.addCase(getDefaultAttributesBySubcategoryId.pending, (state) => {
    });
    builder.addCase(getDefaultAttributesBySubcategoryId.fulfilled, (state, { payload }) => {
      const { defaultAttributeIds, attributeIds } = payload;

      state.defaultAttributeIds = defaultAttributeIds;
      state.attributeIds = attributeIds;
      state.attributesLoading = false;
      state.errorMessage = null;
    });
    builder.addCase(getDefaultAttributesBySubcategoryId.rejected, (state) => {
    });
  },
});

export const { setSubcategories, resetSubcategories } = subcategoriesSlice.actions;
export default subcategoriesSlice.reducer;
